import * as ibantools from 'ibantools';
import * as yup from 'yup';
import i18next from 'i18next';

import isPhoneNumberValid from '@/shared/utils/phoneUtils/phoneUtils';
import { removeWhitespaces } from '@/shared/utils/helpers';

const methodKeys = {
  ACCOUNT_NUMBER: 'accountNumber',
  ALPH_NUM: 'alphaNumeric',
  BIC: 'bic',
  TEL: 'phoneNumber',
  TRANS_NUM: 'transformNaN',
};

const regExp = {
  ALPH_NUM: /^[a-zA-Z0-9]+$/,
  ALPH_NUM_U: /^\w+$/,
};

function alphaNumeric(
  this: yup.StringSchema,
  message?: string,
  options: yup.AlphaNumericOptions = { allowUnderscore: true },
) {
  const pattern = options.allowUnderscore ? regExp.ALPH_NUM_U : regExp.ALPH_NUM;

  return this.matches(pattern, message || i18next.t('errors:shouldBeAlphaNumeric'));
}

function phoneNumber(this: yup.AnyObjectSchema, isMobile: boolean, allowBoth = false) {
  return this.test((value, context) => {
    if (!value?.number) return true;

    const { isValid, errorMessage: message } = isPhoneNumberValid(
      i18next.t,
      value.number,
      value.countryCode,
      isMobile,
      allowBoth,
    );

    if (isValid) return true;

    return context.createError({
      message,
    });
  });
}

function transformNaN(this: yup.NumberSchema) {
  return this.transform((value) => (Number.isNaN(value) ? 0 : value));
}

function accountNumber(
  this: yup.StringSchema,
  message?: string,
) {
  return this.test((value, context) => {
    if (!value) {
      return true;
    }
    const input = removeWhitespaces(value);
    const isValid = ibantools.validateIBAN(input).valid;

    if (isValid) return true;

    return context.createError({
      message: message || i18next.t('errors:WrongAccountBankBranchChecksum'),
    });
  });
}

function bic(
  this: yup.StringSchema,
  message?: string,
) {
  return this.test((value, context) => {
    if (!value) {
      return true;
    }
    const input = removeWhitespaces(value);
    const isValid = ibantools.validateBIC(input).valid;

    if (isValid) return true;

    return context.createError({
      message: message || i18next.t('errors:WrongBICChecksum'),
    });
  });
}

yup.addMethod(yup.string, methodKeys.ALPH_NUM, alphaNumeric);
yup.addMethod(yup.object, methodKeys.TEL, phoneNumber);
yup.addMethod(yup.number, methodKeys.TRANS_NUM, transformNaN);
yup.addMethod(yup.string, methodKeys.ACCOUNT_NUMBER, accountNumber);
yup.addMethod(yup.string, methodKeys.BIC, bic);
