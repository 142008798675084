import { AutocompleteOwnerState, AutocompleteRenderGetTagProps } from '@mui/joy';

import { BuildingEmailContact as EmailContact } from '@/types/trpc/building';
import { EmailOption } from '@/types/trpc/mailbox';
import { RelationType } from '~/common/enums';
import { SenderOrReceiver } from '@/types/email';

export enum DisplayType {
  Action = 'Action',
  Custom = 'Custom',
  Company = RelationType.Company,
  Contact = RelationType.Contact,
  Suggestion = 'Suggestion',
  Tip = 'Tip',
}

export type DefaultOption = EmailOption & {
  displayType: DisplayType.Company | DisplayType.Contact;
};

export type ActionOption = {
  action: () => void;
  displayType: DisplayType.Action;
  label: string;
  startDecorator: React.ReactNode;
};

export type CustomOption = {
  displayType: DisplayType.Custom;
  label: string;
  value: string;
};

export type SuggestionOption = {
  data: SenderOrReceiver;
  displayType: DisplayType.Suggestion;
  label: string;
  value: string;
};

export type TipOption = {
  displayType: DisplayType.Tip;
  label: string;
  startDecorator: React.ReactNode;
};

export type SelectableOption = DefaultOption | CustomOption | SuggestionOption;

export type Option = SelectableOption | ActionOption | TipOption;

type BaseProps = {
  className?: string;
  endDecorator?: React.ReactNode;
  error?: string;
  isLoading?: boolean;
  label?: string;
  onBlur?: React.FocusEventHandler;
  renderTags?: (
    value: Option[],
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: AutocompleteOwnerState<Option, boolean, boolean, undefined>,
  ) => React.ReactNode;
  useEmailAsLabel?: boolean;
};

type MultipleProps = {
  initialSuggestions?: SenderOrReceiver[];
  multiple: true;
  onChange: React.Dispatch<SelectableOption[]>;
  value: SelectableOption[];
};

type SingleProps = {
  initialSuggestions?: SenderOrReceiver;
  multiple?: false;
  onChange: React.Dispatch<SelectableOption>;
  value: SelectableOption | null;
};

export type EmailSelectorProps = BaseProps & (SingleProps | MultipleProps);

export type EmailDndItem = {
  contact: EmailContact;
  source: string;
};
