import { Alert, Divider, Stack } from '@mui/joy';
import React, { useContext } from 'react';
import { lowerFirst } from 'lodash';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import ConfirmModal from '@/shared/components/2.0/ConfirmModal';
import EmailAddressInput from '@/containers/Email/EmailAddressInput';
import FormikAddressForm from '@/shared/formik/FormikAddressForm';
import FormikAutocomplete from '@/shared/components/2.0/formik/FormikAutocomplete';
import FormikIbansInput from '@/shared/components/2.0/input/FormikIbansInput';
import FormikInternationalPhoneNumberInput from '@/shared/components/2.0/formik/FormikInternationalPhoneNumberInput';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';
import { InlineSpinner } from '@/shared/icons/Icons';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';
import { PhoneNumberType } from '@/shared/components/phoneNumber/InternationalPhoneNumber';

import { CaseRole, Gender, Language } from '~/common/enums';
import { AppContext } from '@/shared/context/context';
import ContactFormikValues from '~/common/types/contact/ContactFormikValues';
import { TAutocompleteOption } from '~/common/types';
import { trpc } from '@/config/trpc';
import useDuplicateCheck from '../useDuplicateCheck';
import useToggle from '@/shared/hooks/UseToggle';

type Props = {
  disabled?: boolean;
  isSubmitting: boolean;
  minified?: boolean;
  onCancel: () => void;
};

const AddEditContactFormBody: React.FC<Props> = ({
  disabled = false,
  isSubmitting,
  minified = false,
  onCancel,
}) => {
  const { t } = useTranslation(['common', 'errors']);

  const confirmToggle = useToggle();

  const genderOptions = Object.values(Gender).map((gender) => ({
    label: t(lowerFirst(gender)),
    value: gender,
  })) as TAutocompleteOption<Gender>[];

  const languageOptions = Object.values(Language).map((language) => ({
    label: t(lowerFirst(language)),
    value: language,
  })) as TAutocompleteOption<Language>[];

  const caseRoleOptions = Object.values(CaseRole).map((caseRole) => ({
    label: t(lowerFirst(caseRole)),
    value: caseRole,
  })) as TAutocompleteOption<CaseRole>[];

  const { isValid, values, submitForm } = useFormikContext<ContactFormikValues>();
  const { alertInfo, checkForDuplicates, duplicateReason, isCheckingForDuplicate } =
    useDuplicateCheck({ values });

  const { currentTeam } = useContext(AppContext);
  const allowCaseManagement = !!currentTeam?.allowCaseManagement;

  const { data: tags } = trpc.contact.tags.useQuery();

  return (
    <>
      {!!alertInfo && (
        <Alert
          startDecorator={isCheckingForDuplicate ? <InlineSpinner /> : alertInfo.startDecorator}
          color={alertInfo.color}
        >
          <p>
            {alertInfo.label} {t('clickHereToViewContact.click')}{' '}
            <a href={`/contact/${alertInfo.contactId}`} rel="noreferrer" target="_blank">
              {t('clickHereToViewContact.here')}
            </a>{' '}
            {t('clickHereToViewContact.toViewContact')}
          </p>
        </Alert>
      )}
      <Stack rowGap={0.5}>
        <div className={allowCaseManagement ? 'flex gap-4' : ''}>
          <div className={allowCaseManagement ? 'w-1/2' : ''}>
            <FormikAutocomplete
              name="contactTags"
              id="contactTags"
              label={t('tags')}
              multiple
              options={tags || []}
            />
          </div>
          {allowCaseManagement && (
            <div className="w-1/2">
              <FormikAutocomplete
                name="defaultCaseRole"
                id="defaultCaseRole"
                label={t('defaultRole')}
                options={caseRoleOptions || []}
              />
            </div>
          )}
        </div>
        <Stack direction="row" spacing={1}>
          <FormikTextField
            id="firstName"
            name="firstName"
            label={t('common:firstName')}
            fullWidth
            type="text"
            disabled={disabled}
            onBlur={() => checkForDuplicates()}
          />
          <FormikTextField
            label={t('common:lastName')}
            fullWidth
            id="lastName"
            name="lastName"
            type="text"
            required
            disabled={disabled}
            onBlur={() => checkForDuplicates()}
          />
        </Stack>
        {!minified && (
          <>
            <Stack direction="row" spacing={1}>
              <FormikAutocomplete
                name="gender"
                label={t('common:gender')}
                options={genderOptions}
              />
              <FormikAutocomplete
                name="language"
                id="language"
                label={t('common:language')}
                options={languageOptions}
              />
            </Stack>
            <Divider className="my-2" />
            <FormikIbansInput />
            <Divider className="my-2" />
            <EmailAddressInput inputOnBlur={checkForDuplicates} onRemove={checkForDuplicates} />
            <Divider className="mb-2 mt-5" />
            <Stack direction="row" spacing={1}>
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Landline}
                className="w-full"
                name="phoneNumber"
                label={t('phoneNumber')}
                disabled={disabled}
                onBlur={checkForDuplicates}
              />
              <FormikInternationalPhoneNumberInput
                type={PhoneNumberType.Mobile}
                className="w-full"
                name="mobilePhoneNumber"
                label={t('mobilePhoneNumber')}
                disabled={disabled}
                onBlur={checkForDuplicates}
              />
            </Stack>
            <FormikAddressForm disabled={disabled} />
          </>
        )}
      </Stack>

      <ModalFooter
        onSubmit={() => {
          if (!duplicateReason) {
            submitForm();
            return;
          }

          confirmToggle.show();
        }}
        submitButtonType="button"
        disableSubmit={!isValid || isCheckingForDuplicate}
        isSubmitting={isSubmitting}
        onCancel={onCancel}
      />

      {confirmToggle.value && (
        <ConfirmModal
          bodyText={`${alertInfo?.label} ${t('modals.confirm.description')}`}
          onConfirm={submitForm}
          toggle={confirmToggle}
        />
      )}
    </>
  );
};

export default AddEditContactFormBody;
