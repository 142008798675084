import { useMailboxContext } from '@/containers/Mailbox/store';

const PREFIXES = {
  FORWARD: 'FWD',
  REPLY: 'RE',
};

const useGetSubject = (initialSubject?: string | null) => {
  const { state: mailbox } = useMailboxContext();

  if (initialSubject) return initialSubject;
  if (!mailbox.replyEmail) return null;

  const replySubject = mailbox.replyEmail?.subject ?? null;

  if (mailbox.isRetry) return replySubject;

  const prefix = `${mailbox.isForward ? PREFIXES.FORWARD : PREFIXES.REPLY}:`;

  if (!replySubject) return prefix;

  return replySubject?.toUpperCase().startsWith(prefix)
    ? replySubject
    : `${prefix} ${replySubject}`;
};

export default useGetSubject;
