import { FormControl, FormHelperText, FormLabel } from '@mui/joy';
import { FormikValues, useFormikContext } from 'formik';

import InternationalPhoneNumber, {
  PhoneNumberType,
} from '@/shared/components/phoneNumber/InternationalPhoneNumber';

type Props = {
  className?: string;
  label: string;
  name: string;
  type: PhoneNumberType;
  value?: string;
  disabled?: boolean;
  onBlur?: () => void;
};

const FormikInternationalPhoneNumberInput: React.FC<Props> = ({
  className,
  disabled = false,
  label,
  name,
  value,
  type,
  onBlur,
}) => {
  const { errors, touched, values, setFieldValue, setFieldTouched } =
    useFormikContext<FormikValues>();

  const hasError = touched && !!errors[name];

  return (
    <FormControl className={className} error={hasError}>
      <FormLabel>{label}</FormLabel>
      <InternationalPhoneNumber
        callBack={async (phoneNbr, code) => {
          await setFieldValue(name, {
            countryCode: phoneNbr === '' ? '' : code,
            number: phoneNbr,
          });
          await setFieldTouched(name, true);
        }}
        type={type}
        inputClass="h-8 w-100 rounded-lg"
        value={value ?? `${values[name]?.countryCode ?? ''}${values[name]?.number ?? ''}`}
        disabled={disabled}
        disableDropdown
        onBlur={() => onBlur?.()}
      />
      {hasError && (
        <FormHelperText className="pl-1 text-xs">
          {errors[name] as string | undefined}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikInternationalPhoneNumberInput;
