import { Button, Checkbox, FormControl, FormLabel, Input, Textarea } from '@mui/joy';
import { FC, useContext } from 'react';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '@/containers/Cases/CaseContext';
import { trpc } from '@/config/trpc';
import useMutateWithSnackbar from '@/shared/hooks/useMutateWithSnackbar';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { CancelIcon, InlineSpinner, SaveIcon } from '@/shared/icons/Icons';
import CurrencyInput from '@/shared/components/inputs/CurrencyInput';
import DatePicker from '@/shared/components/2.0/DatePicker';

type FormValues = {
  allowExecutionOnTimeAndExpenseBasis: boolean;
  assignmentAdditionalInfo: string;
  assignmentDescription: string;
  timeAndExpenseBaseMaxAmount: number | null;
  timeAndExpenseBaseMaxDate: Date | null;
};

type Props = {
  toggle: UseToggle;
};

const EditAssignmentInformationForm: FC<Props> = ({ toggle }) => {
  const { t } = useTranslation();
  const { currentCase, refetchCase } = useContext(CaseContext);

  const { mutateAsync, isLoading } =
    trpc.case.repair.contractor.updateAssignmentInformation.useMutation();

  const updateAssignmentInformation = useMutateWithSnackbar({
    actionKey: 'update',
    entityKey: 'case',
    mutateAsync,
    onSuccess: () => {
      refetchCase();
      toggle.hide();
    },
  });

  return (
    <Formik<FormValues>
      initialValues={{
        allowExecutionOnTimeAndExpenseBasis:
          currentCase.allowExecutionOnTimeAndExpenseBasis ?? false,
        assignmentAdditionalInfo: currentCase.assignmentAdditionalInfo ?? '',
        assignmentDescription: currentCase.assignmentDescription ?? '',
        timeAndExpenseBaseMaxAmount: currentCase.timeAndExpenseBaseMaxAmount,
        timeAndExpenseBaseMaxDate: currentCase.timeAndExpenseBaseMaxDate,
      }}
      onSubmit={(values) => {
        const { allowExecutionOnTimeAndExpenseBasis, ...rest } = values;

        const request = {
          ...rest,
          allowExecutionOnTimeAndExpenseBasis,
          caseId: currentCase.id,
          timeAndExpenseBaseMaxAmount: allowExecutionOnTimeAndExpenseBasis
            ? values.timeAndExpenseBaseMaxAmount
            : null,
          timeAndExpenseBaseMaxDate: allowExecutionOnTimeAndExpenseBasis
            ? values.timeAndExpenseBaseMaxDate
            : null,
        };

        updateAssignmentInformation(request);
      }}
    >
      {({ values, setFieldValue }) => (
        <Form className="flex flex-col space-y-2">
          <FormControl>
            <FormLabel>{t('description')}</FormLabel>
            <Field as={Input} name="assignmentDescription" />
          </FormControl>

          <FormControl>
            <FormLabel>{t('additionalInformation')}</FormLabel>
            <Field as={Textarea} name="assignmentAdditionalInfo" />
          </FormControl>

          <FormControl>
            <Checkbox
              label={t('onTimeAndExpenseBasis')}
              checked={values.allowExecutionOnTimeAndExpenseBasis}
              onChange={(e) =>
                setFieldValue('allowExecutionOnTimeAndExpenseBasis', e.target.checked)
              }
            />
          </FormControl>

          {values.allowExecutionOnTimeAndExpenseBasis && (
            <div className="grid grid-cols-2 gap-2">
              <CurrencyInput
                currencyValue={values.timeAndExpenseBaseMaxAmount}
                label={t('timeAndExpenseBaseMaxAmount')}
                className="col-span-1"
                callback={(value) => setFieldValue('timeAndExpenseBaseMaxAmount', value)}
              />
              <FormControl>
                <FormLabel>{t('timeAndExpenseBaseMaxDate')}</FormLabel>
                <DatePicker
                  className="col-span-1"
                  clearable
                  value={values.timeAndExpenseBaseMaxDate}
                  onChange={(date) => setFieldValue('timeAndExpenseBaseMaxDate', date)}
                />
              </FormControl>
            </div>
          )}

          <div className="my-4 flex w-full justify-end space-x-4">
            <Button
              variant="outlined"
              color="neutral"
              startDecorator={<CancelIcon />}
              onClick={() => toggle.hide()}
            >
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              color="primary"
              startDecorator={isLoading ? <InlineSpinner /> : <SaveIcon />}
            >
              {t('save')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default EditAssignmentInformationForm;
