import { useLayoutEffect, useRef, useState } from 'react';
import { clamp } from 'lodash';

type UseAvailableHeightInput = {
  bottomOffset?: number; // afaik it's not possible to determine which parts of the remaining viewport are truly "free" or actually margin, padding...
  maxHeight?: number;
  minHeight?: number;
};

const useAvailableHeight = ({
  bottomOffset = 0,
  maxHeight,
  minHeight = 0,
}: UseAvailableHeightInput) => {
  const bottomRef = useRef<HTMLDivElement>(null);
  const topRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(minHeight ?? maxHeight ?? 0);

  useLayoutEffect(() => {
    const measureAvailableHeight = () => {
      const elOffset = (() => {
        const bottom = bottomRef.current?.offsetHeight ?? 0;
        const top = topRef.current ? topRef.current.getBoundingClientRect().bottom : 0;

        return bottom + top;
      })();

      const availableHeight = Math.floor(window.innerHeight - elOffset - bottomOffset);
      const toUseHeight = clamp(availableHeight, minHeight, maxHeight || availableHeight);

      setHeight(toUseHeight);
    };

    const observer = new ResizeObserver(measureAvailableHeight);

    if (bottomRef.current) observer.observe(bottomRef.current);
    if (topRef.current) observer.observe(topRef.current);

    measureAvailableHeight();

    return () => observer.disconnect();
  }, [bottomOffset, bottomRef, maxHeight, minHeight, topRef]);

  return {
    bottomRef,
    topRef,
    value: height,
  };
};

export default useAvailableHeight;
