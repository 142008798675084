import { isEqual, uniqBy } from 'lodash';
import { useCallback, useEffect, useRef } from 'react';

import { DisplayType, SelectableOption } from '../../../select/SelectEmailContact/types';
import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';
import { BuildingEmailContact as EmailContact } from '@/types/trpc/building';
import { EmailFormValues } from '@/containers/Mailbox/Components/ComposeEmail/types';
import { SplitRoles } from '../../../autocomplete/buildingRoleSelector';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { hasRole } from '../../../select/SelectEmailContact/utils';
import { trpc } from '@/config/trpc';
import useFormikValues from '@/shared/hooks/useFormikValues';

export type RolesData = {
  selected: BuildingRoleSelectorOption[];
  split: SplitRoles;
};

type PrevEmailContacts = {
  building: EmailContact[] | null;
  hoa: EmailContact[] | null;
};

type UseEmailContactsForRolesArgs = {
  bccToggle: UseToggle;
  buildingId?: string | null;
  hoaBuildingIds?: string[];
  hoaId?: string | null;
  roles: RolesData;
};

const useEmailContactsForRoles = ({
  bccToggle,
  buildingId,
  hoaBuildingIds,
  hoaId,
  roles,
}: UseEmailContactsForRolesArgs) => {
  const prevEmailContactsRef = useRef<PrevEmailContacts>({
    building: null,
    hoa: null,
  });

  const [formikProps, formikUtils] = useFormikValues<EmailFormValues>();

  const queriesEnabled = {
    buildingEmailContacts: !!buildingId && !!roles.selected.length,
    hoaEmailContacts: !!hoaId && !!hoaBuildingIds?.length && !!roles.selected.length,
  };

  const { data: buildingEmailContacts, isFetching: isFetchingBuildingEmailContacts } =
    trpc.building.emailContacts.useQuery(
      {
        buildingId: buildingId ?? '',
        ...roles.split,
      },
      {
        enabled: queriesEnabled.buildingEmailContacts,
      },
    );
  const { data: hoaEmailContacts, isFetching: isFetchingHoaEmailContacts } =
    trpc.hoa.communication.receiver.emailContacts.useQuery(
      { buildings: hoaBuildingIds ?? [], homeownerAssociationId: hoaId ?? '', ...roles.split },
      { enabled: queriesEnabled.hoaEmailContacts },
    );

  const handleChange = useCallback(
    (emailContacts: EmailContact[], rolesData: RolesData) => {
      const emailContactsAsOptions = (() => {
        const options = emailContacts.map((contact) => ({
          data: contact,
          displayType: DisplayType.Suggestion as const,
          label: contact.name,
          value: contact.email,
        }));

        return uniqBy(options, 'value');
      })();

      const hasBuildingOptionWithValue = (value: string) =>
        emailContactsAsOptions.some((contactOption) => contactOption.value === value);

      const filterByRoles = (selectedOptions: SelectableOption[]) =>
        selectedOptions.filter(
          (option) =>
            hasRole(option, [
              ...rolesData.split.boardMemberRoles,
              ...rolesData.split.buildingUnitRoles,
            ]) && !hasBuildingOptionWithValue(option.value),
        );

      const newTo = filterByRoles(formikProps.selectedTo.value);

      let newBcc = filterByRoles(formikProps.selectedBcc.value);
      let newCc = filterByRoles(formikProps.selectedCc.value);

      if (rolesData.selected.includes(BuildingRoleSelectorOption.BoardOfCoOwnership)) {
        newCc = [...newCc, ...emailContactsAsOptions];
      } else {
        newBcc = [...newBcc, ...emailContactsAsOptions];
      }

      if (newBcc.length) bccToggle.show();

      formikUtils.selectedBcc.set(newBcc);
      formikUtils.selectedCc.set(newCc);
      formikUtils.selectedTo.set(newTo);
    },
    [
      bccToggle,
      formikProps.selectedBcc.value,
      formikProps.selectedCc.value,
      formikProps.selectedTo.value,
      formikUtils.selectedBcc,
      formikUtils.selectedCc,
      formikUtils.selectedTo,
    ],
  );

  const reset = useCallback(() => {
    handleChange([], {
      selected: [],
      split: {
        boardMemberRoles: [],
        buildingUnitRoles: [],
      },
    });

    prevEmailContactsRef.current = {
      building: null,
      hoa: null,
    };
  }, [handleChange]);

  useEffect(() => {
    if (
      !buildingEmailContacts ||
      isEqual(buildingEmailContacts, prevEmailContactsRef.current.building)
    )
      return;

    handleChange(buildingEmailContacts, roles);

    prevEmailContactsRef.current.building = buildingEmailContacts;
  }, [buildingEmailContacts, handleChange, roles]);

  useEffect(() => {
    if (!hoaEmailContacts || isEqual(hoaEmailContacts, prevEmailContactsRef.current.hoa)) return;

    handleChange(hoaEmailContacts, roles);

    prevEmailContactsRef.current.hoa = hoaEmailContacts;
  }, [handleChange, hoaEmailContacts, roles]);

  return {
    isFetching: isFetchingBuildingEmailContacts || isFetchingHoaEmailContacts,
    reset,
  };
};

export default useEmailContactsForRoles;
