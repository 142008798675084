import { FC, useContext, useMemo } from 'react';
import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '@/containers/Cases/CaseContext';
import useToggle from '@/shared/hooks/UseToggle';

import { AssignIcon, EditIcon, InvalidIcon, ValidIcon } from '../../../../../../shared/icons/Icons';
import AddContractorForm from '../../../contractor/AddContractorForm';
import EditAssignmentInformationForm from './EditAssignmentInformationForm';
import { formatAsCurrency } from '@/shared/utils/helpers';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import useDayjs from '@/shared/hooks/useDayjs';

const Icon = (valid: boolean) =>
  valid ? (
    <ValidIcon color="green" className="px-2 pt-1" />
  ) : (
    <InvalidIcon color="red" className="px-2 pt-1" />
  );

type Props = {
  nextStep: () => void;
};
const AssignContractor: FC<Props> = ({ nextStep }) => {
  const { currentCase, ownRelation } = useContext(CaseContext);
  const { t } = useTranslation();

  const { formatDate } = useDayjs();
  const toggles = {
    assignContractor: useToggle(),
    editAssignmentInfo: useToggle(),
  };

  const isCaseOwner = ownRelation.isOwner;
  const isContractor = ownRelation.isCurrentContractor;

  const contractor = useMemo(
    () => (currentCase ? currentCase.relations.find((relation) => relation.isContractor) : null),
    [currentCase],
  );

  const handleSubmit = () => {
    toggles.assignContractor.hide();
    nextStep();
  };

  return (
    <div>
      <Timeline position="right">
        {!!currentCase && !!contractor && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: '4px' }} />
              <TimelineDot sx={{ height: '45px', width: '45px' }} />
              <TimelineConnector sx={{ height: '4px' }} />
            </TimelineSeparator>
            <div className="my-auto ms-3 flex w-full flex-col space-y-2 leading-4">
              <p className="bold">{contractor?.displayName}</p>
              <p>
                {t('description')}: {currentCase.assignmentDescription || '-'}
              </p>
              <p>
                {t('additionalInformation')}: {currentCase.assignmentAdditionalInfo || '-'}
              </p>

              {(isCaseOwner || isContractor) && (
                <>
                  <p>
                    {t('executionOnTimeAndExpenseBaseAllowed')}:{' '}
                    {Icon(currentCase.allowExecutionOnTimeAndExpenseBasis)}
                  </p>
                  {currentCase.allowExecutionOnTimeAndExpenseBasis && (
                    <>
                      <p>
                        {t('timeAndExpenseBaseMaxAmount')}:{' '}
                        {currentCase.timeAndExpenseBaseMaxAmount
                          ? formatAsCurrency(currentCase.timeAndExpenseBaseMaxAmount, true)
                          : '-'}
                      </p>
                      <p>
                        {t('timeAndExpenseBaseMaxDate')}:{' '}
                        {currentCase.timeAndExpenseBaseMaxDate
                          ? formatDate(currentCase.timeAndExpenseBaseMaxDate)
                          : '-'}
                      </p>
                    </>
                  )}
                </>
              )}

              {isCaseOwner && (
                <Button
                  startDecorator={<EditIcon />}
                  className="w-36"
                  onClick={() => toggles.editAssignmentInfo.show()}
                >
                  {t('edit')}
                </Button>
              )}
            </div>
          </TimelineItem>
        )}
        {!isCaseOwner && contractor == null && (
          <span className="color-keypoint">{t('noDataToDisplay')}</span>
        )}
        {isCaseOwner && (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineConnector sx={{ height: '15px' }} />
              <TimelineDot sx={{ height: '45px', width: '45px' }} />
              <TimelineConnector sx={{ height: '15px' }} />
            </TimelineSeparator>
            <div className="m-auto ms-3 w-full">
              <Button startDecorator={<AssignIcon />} onClick={toggles.assignContractor.show}>
                {contractor ? t('assignAnotherContractor') : t('assignContractor')}
              </Button>
            </div>
          </TimelineItem>
        )}
      </Timeline>
      <KpcModal title={t('assignContractor')} toggle={toggles.assignContractor}>
        <AddContractorForm toggle={toggles.assignContractor} onSuccess={handleSubmit} />
      </KpcModal>

      <KpcModal title={contractor?.displayName} toggle={toggles.editAssignmentInfo}>
        <EditAssignmentInformationForm toggle={toggles.editAssignmentInfo} />
      </KpcModal>
    </div>
  );
};

export default AssignContractor;
