import { useCallback, useRef } from 'react';
import classNames from 'classnames';
import { OpUnitType } from 'dayjs';
import { useTranslation } from 'react-i18next';

import { AddEditAppointmentFormikValues } from '../FormikValues';
import { AutocompleteOption } from '~/common/types';
import { CalendarEvent } from '~/common/types/calendars/events';
import { EditorHandle } from '@/shared/components/richTextEditor/types';

import { caseApi } from '~/frontend/shared/api';
import { queryIds } from '~/frontend/shared/utils/constants';
import { TOOLBAR_SETTINGS } from '@/shared/components/richTextEditor/constants';
import useDayjs from '@/shared/hooks/useDayjs';
import useFormikValues from '@/shared/hooks/useFormikValues';
import { useGetCase } from '@/queries';

import AsyncFormikSelector from '@/shared/components/2.0/formik/AsyncFormikSelector';
import DatePicker from '@/shared/components/2.0/DatePicker';
import DateTimePicker from '@/shared/components/2.0/DateTimePicker';
import FormField from '@/shared/components/2.0/input/FormField';
import FormikCalendarSelector from '@/shared/components/2.0/formik/FormikCalendarSelector';
import FormikCaseEntityOptions from '@/shared/components/2.0/formik/FormikCaseEntityOptions';
import FormikCheckbox from '@/shared/components/2.0/formik/FormikCheckbox';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';
import RichTextEditor from '@/shared/components/richTextEditor/RichTextEditor';

type DateKey = 'end' | 'start';

type Dates = Pick<AddEditAppointmentFormikValues, DateKey>;

const ALL_DAY_FORMAT = 'YYYY-MM-DD';
const DEFAULT_APPOINTMENT_DURATION_IN_MIN = 30;
const DESCRIPTION_EDITOR_HEIGHT = 200;

const useDateUtils = () => {
  const [formikProps, _formikUtils, formikCtx] = useFormikValues<AddEditAppointmentFormikValues>();

  const prevDateTimesRef = useRef({
    end: formikProps.end.value,
    start: formikProps.start.value,
  });

  const { dayjs } = useDayjs();

  const areEqual = useCallback(
    (a: string | null, b: string | null, precision: OpUnitType = 'day') =>
      dayjs(a ?? undefined).isSame(b ?? undefined, precision),
    [dayjs],
  );

  const formatAsAllDay = useCallback(
    (date: string | null) => (date ? dayjs.utc(date).format(ALL_DAY_FORMAT) : null),
    [dayjs],
  );

  const formatAsDateTime = useCallback(
    (date: string | null) => (date ? dayjs.utc(date).toISOString() : null),
    [dayjs],
  );

  const getDefaultEndDate = useCallback(
    (startDate: string) =>
      dayjs.utc(startDate).add(DEFAULT_APPOINTMENT_DURATION_IN_MIN, 'minutes').toISOString(),
    [dayjs],
  );

  const restoreHours = useCallback(
    (dates: Dates) => {
      const getPrevDateTime = (key: DateKey) =>
        prevDateTimesRef.current[key] ?? formikCtx.initialValues[key];

      const restore = (key: DateKey) => {
        const source = getPrevDateTime(key);
        const target = dates[key];

        const sourceDate = source ? dayjs.utc(source) : null;

        if (!sourceDate) return formatAsDateTime(target);

        const hours = sourceDate.hour();
        const minutes = sourceDate.minute();

        return dayjs.utc(target).hour(hours).minute(minutes).toISOString();
      };

      return {
        end: restore('end'),
        start: restore('start'),
      };
    },
    [dayjs, formatAsDateTime, formikCtx.initialValues],
  );

  const saveHours = useCallback((dates: Dates) => {
    prevDateTimesRef.current = dates;
  }, []);

  return {
    areEqual,
    formatters: {
      allDay: formatAsAllDay,
      dateTime: formatAsDateTime,
    },
    getDefaultEndDate,
    restoreHours,
    saveHours,
  };
};

type DatePickerProps = {
  field: DateKey;
  label: string;
};

const AppointmentDatePicker: React.FC<DatePickerProps> = ({ field, label }) => {
  const prevEndDatePrefillRef = useRef<string | null>(null);

  const [formikProps, formikUtils, formikCtx] = useFormikValues<AddEditAppointmentFormikValues>();

  const { getDefaultEndDate } = useDateUtils();

  const handleChange = (dateString: string | null) => {
    formikProps[field].onChange(dateString);

    if (field !== 'start') return;

    if (!dateString) prevEndDatePrefillRef.current = null;

    if (
      !dateString ||
      dateString === formikCtx.initialValues.start ||
      formikProps.end.value ||
      formikProps.allDay.value
    )
      return;

    const newEnd = getDefaultEndDate(dateString);

    if (newEnd === prevEndDatePrefillRef.current) return;

    formikUtils.end.set(newEnd);

    prevEndDatePrefillRef.current = newEnd;
  };

  const commonProps = {
    error: formikProps[field].error,
    label,
    required: true,
    value: formikProps[field].value,
  };

  return (
    <div
      className={classNames({
        'w-1/3 flex-initial': formikCtx.initialValues.linkedCalendarId,
        'w-64 flex-initial': !formikCtx.initialValues.linkedCalendarId,
      })}
    >
      {formikProps.allDay.value ? (
        <DatePicker
          {...commonProps}
          clearable
          error={formikProps[field].error}
          options={{
            format: ALL_DAY_FORMAT,
          }}
          inputCallbacks={{
            onBlur: formikUtils[field].setTouched,
            onChange: formikUtils[field].setTouched,
          }}
          onChange={(_, dateString) => handleChange(dateString)}
        />
      ) : (
        <DateTimePicker
          {...commonProps}
          onBlur={formikProps[field].onBlur}
          onChange={handleChange}
        />
      )}
    </div>
  );
};

type FormProps = {
  initialAppointment?: CalendarEvent;
  showLinkedCase?: boolean;
};

const AddEditAppointmentFormBody: React.FC<FormProps> = ({
  initialAppointment,
  showLinkedCase = false,
}) => {
  const descriptionEditorRef = useRef<EditorHandle>(null);

  const [formikProps, formikUtils, formikCtx] = useFormikValues<AddEditAppointmentFormikValues>();

  const { areEqual, formatters, getDefaultEndDate, restoreHours, saveHours } = useDateUtils();
  const { currentCase, currentCaseIsLoading } = useGetCase(formikProps.caseId.value);
  const { t } = useTranslation();

  const caseOption = currentCase
    ? {
        data: {
          description: currentCase.description,
        },
        label: currentCase.currentTeamReference,
        value: currentCase.id,
      }
    : undefined;

  // time zone not saved in BE for all day
  const handleAllDayChange = (allDay: boolean) => {
    const currentEnd = formikProps.end.value;
    const currentStart = formikProps.start.value;

    let newEnd = !currentEnd && currentStart ? getDefaultEndDate(currentStart) : currentEnd;
    let newStart = currentStart;

    if (allDay) {
      saveHours({ end: currentEnd, start: currentStart });

      newEnd = areEqual(newEnd, newStart) ? null : formatters.allDay(newEnd);
      newStart = formatters.allDay(newStart);
    } else {
      ({ end: newEnd, start: newStart } = restoreHours({ end: newEnd, start: newStart }));
    }

    formikUtils.end.set(newEnd);
    formikUtils.start.set(newStart);
  };

  return (
    <>
      <div className="mb-3 flex space-x-4">
        {!formikCtx.initialValues.linkedCalendarId && (
          <div className="w-1/2 flex-initial">
            <FormikCalendarSelector name="linkedCalendarId" label={t('calendar')} />
          </div>
        )}
        <AppointmentDatePicker field="start" label={t('startDate')} />
        <AppointmentDatePicker field="end" label={t('endDate')} />
        <div
          className={classNames('m-auto', {
            'w-64 flex-initial': !formikCtx.initialValues.linkedCalendarId,
          })}
        >
          <FormikCheckbox
            callback={handleAllDayChange}
            size="md"
            name="allDay"
            label={t('allDay')}
          />
        </div>
      </div>
      <FormikTextField className="mb-3" name="title" required label={t('title')} />
      <FormField className="mb-3" label={t('description')}>
        <RichTextEditor
          ref={descriptionEditorRef}
          height={DESCRIPTION_EDITOR_HEIGHT}
          initialValue={formikProps.description?.value ?? ''}
          toolbarSettings={TOOLBAR_SETTINGS.MINIMAL}
          onBlur={() => {
            if (!descriptionEditorRef.current) return;

            const content = descriptionEditorRef.current.save();

            formikProps.description?.onChange(content);
          }}
        />
      </FormField>
      {(!currentCase || showLinkedCase) && (
        <AsyncFormikSelector
          defaultValue={!currentCaseIsLoading ? (caseOption as AutocompleteOption) : undefined}
          name="caseId"
          label={t('case_one')}
          queryFn={caseApi.getCasesAutocomplete}
          queryId={queryIds.cases.GET_CASES_AUTOCOMPLETE}
        />
      )}
      {!!currentCase && (
        <FormikCaseEntityOptions
          // @ts-ignore
          selectedCase={currentCase}
          // @ts-ignore
          entity={initialAppointment}
          shouldNotBeShownOnPublicPage
        />
      )}
    </>
  );
};

export default AddEditAppointmentFormBody;
