import { IconButton, Tooltip } from '@mui/joy';
import { TFunction } from 'i18next';
import { useState } from 'react';

import { InvoiceCreationStatus } from '~/common/enums';
import { trpc } from '@/config/trpc';
import useToggle from '@/shared/hooks/UseToggle';

import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import { PdfIcon } from '@/shared/icons/Icons';
import PdfViewer from '@/shared/components/2.0/PdfViewer';

type Props = {
  rowData: { invoiceId: string; invoiceCreationStatus: InvoiceCreationStatus };
  t: TFunction;
};

const InvoicePreviewCustomCell: React.FC<Props> = ({
  rowData: { invoiceId, invoiceCreationStatus },
  t,
}) => {
  const [currentPdfUri, setCurrentPdfUri] = useState<string | null>(null);

  const pdfToggle = useToggle(false);
  const { mutate: getPreviewUri, isLoading } =
    trpc.finance.invoicingInvoices.getPreviewUri.useMutation({
      onSuccess: (data) => {
        setCurrentPdfUri(data);
        pdfToggle.show();
      },
    });

  return (
    <>
      <Tooltip title={invoiceId ? '' : t('errors:invoicePreviewUnavailable')}>
        <span>
          <IconButton
            disabled={invoiceCreationStatus !== InvoiceCreationStatus.Done}
            loading={isLoading}
            variant="plain"
            onClick={() => getPreviewUri(invoiceId)}
          >
            <PdfIcon size="xl" />
          </IconButton>
        </span>
      </Tooltip>
      {pdfToggle.value && currentPdfUri && (
        <KpcModal title="" toggle={pdfToggle} className="h-full w-2/4">
          <PdfViewer document={{ fileUri: currentPdfUri }} className="h-full w-full" />
        </KpcModal>
      )}
    </>
  );
};

export default InvoicePreviewCustomCell;
