import { getIn, useFormikContext } from 'formik';

import DateTimePicker, { DateTimePickerProps } from '../DateTimePicker';

type Props = Omit<DateTimePickerProps, 'error' | 'name' | 'value' | 'onBlur' | 'onChange'> & {
  name: string;
};

const FormikDateTimePicker: React.FC<Props> = ({ name, required, label, ...props }) => {
  const { values, setFieldValue, errors, touched, setFieldTouched } = useFormikContext();

  const error = getIn(touched, name) ? getIn(errors, name) : '';

  return (
    <DateTimePicker
      error={error}
      value={getIn(values, name)}
      onBlur={() => setFieldTouched(name)}
      onChange={(newValue) => {
        setFieldTouched(name);
        setFieldValue(name, newValue);
      }}
      {...props}
    />
  );
};

export default FormikDateTimePicker;
