export const CLASSNAMES = {
  SPELLING_SPAN: 'e-custom-typo',
  SYNCFUSION_ACTIVE: 'e-active',
};

export const FONT_OPTIONS = {
  default: 'Roboto',
  items: [
    {
      command: 'Font',
      subCommand: 'FontName',
      text: 'Default',
      value: 'Roboto',
    },
    { class: 'e-arial', command: 'Font', subCommand: 'FontName', text: 'Arial', value: 'Arial' },
    {
      class: 'e-georgia',
      command: 'Font',
      subCommand: 'FontName',
      text: 'Georgia',
      value: 'Georgia',
    },
    { class: 'e-impact', command: 'Font', subCommand: 'FontName', text: 'Impact', value: 'Impact' },
    {
      class: 'e-custom-inter',
      command: 'Font',
      subCommand: 'FontName',
      text: 'Inter',
      value: 'Inter',
    },
    {
      class: 'e-custom-roboto',
      command: 'Font',
      subCommand: 'FontName',
      text: 'Roboto',
      value: 'Roboto',
    },
    {
      class: 'e-segoe-ui',
      command: 'Font',
      subCommand: 'FontName',
      text: 'Segoe UI',
      value: 'Segoe UI',
    },
    { class: 'e-tahoma', command: 'Font', subCommand: 'FontName', text: 'Tahoma', value: 'Tahoma' },
    {
      class: 'e-times-new-roman',
      command: 'Font',
      subCommand: 'FontName',
      text: 'Times New Roman',
      value: 'Times New Roman',
    },
    {
      class: 'e-verdana',
      command: 'Font',
      subCommand: 'FontName',
      text: 'Verdana',
      value: 'Verdana',
    },
  ],
};

export const IMAGE_SETTINGS = {
  saveFormat: 'Base64',
};

export const REGEX = {
  NUMBER: /\b-?\d+([.,]\d+)?\b/,
  WORD: /[-\p{L}\p{M}\d'’ĳ+₂²€]+/gu,
};

export const SPELL_CHECKER_TOOLBAR_ID = 'custom-toolbar-item-spellchecker';

export const SPELLCHECK_DELAY_IN_MS = 2000;

export const TOOLBAR_SETTINGS = {
  DEFAULT: {
    items: [
      'Undo',
      'Redo',
      '|',
      'Formats',
      '|',
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Alignments',
      '|',
      'FontName',
      'FontSize',
      'BackgroundColor',
      'FontColor',
      '|',
      'OrderedList',
      'UnorderedList',
      'Indent',
      'Outdent',
      '|',
      'CreateLink',
      'Image',
      '|',
      'Print',
    ],
  },
  MINIMAL: {
    items: ['Undo', 'Redo', '|', 'Bold', 'Italic', 'Underline'],
  },
};
