import * as yup from 'yup';
import { Form, Formik } from 'formik';
import { useCallback, useContext, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import CaseRelationPropTypes from '@/shared/prop-types/CaseRelationPropTypes';

import AddCaseRelationFormBody from './AddCaseRelationFormBody';
import { AppContext } from '@/shared/context/context';
import ModalFooter from '@/shared/components/2.0/forms/ModalFooter';

const propTypes = {
  buildingId: PropTypes.string,
  caseRelationToUpdate: CaseRelationPropTypes,
  currentCaseRelations: PropTypes.arrayOf(CaseRelationPropTypes),
  editMode: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const AddUpdateCaseRelationForm = ({
  buildingId = null,
  onSubmit,
  onCancel,
  currentCaseRelations = [],
  caseRelationToUpdate = null,
  editMode = false,
}) => {
  const {
    currentTeam: { createCaseAskForClient },
  } = useContext(AppContext);

  const { t } = useTranslation(['common', 'errors']);
  const popperContainer = useRef();

  const defaultValues = useMemo(
    () => ({
      addressLine: editMode ? caseRelationToUpdate?.relationAddressAddressLine ?? '' : '',
      city: editMode ? caseRelationToUpdate?.relationAddressCity ?? '' : '',
      companyId: editMode ? caseRelationToUpdate?.companyId ?? null : null,
      companyName: editMode ? caseRelationToUpdate?.displayName ?? '' : '',
      contactId: editMode ? caseRelationToUpdate?.contactId ?? null : null,
      country: editMode ? caseRelationToUpdate?.relationAddressCountry ?? null : null,
      emailAddresses: editMode ? caseRelationToUpdate?.emailAddresses ?? [] : [],
      firstName: editMode ? caseRelationToUpdate?.firstName ?? '' : '',
      gender: editMode ? caseRelationToUpdate?.gender ?? null : null,
      ibans: editMode ? caseRelationToUpdate?.ibans ?? [] : [],
      involvementType: editMode ? caseRelationToUpdate?.involvementType ?? null : null,
      isClient: editMode ? caseRelationToUpdate?.isClient ?? false : false,
      isRequestor: editMode ? caseRelationToUpdate?.isRequestor ?? false : false,
      language: editMode ? caseRelationToUpdate?.language ?? null : null,
      lastName: editMode ? caseRelationToUpdate?.lastName ?? '' : '',
      mobilePhoneNumber: {
        countryCode: editMode ? caseRelationToUpdate?.mobilePhoneNumber?.countryCode ?? '' : '',
        number: editMode ? caseRelationToUpdate?.mobilePhoneNumber?.number ?? '' : '',
      },
      phoneNumber: {
        countryCode: editMode ? caseRelationToUpdate?.phoneNumber?.countryCode ?? '' : '',
        number: editMode ? caseRelationToUpdate?.phoneNumber?.number ?? '' : '',
      },
      postalCode: editMode ? caseRelationToUpdate?.relationAddressPostalCode ?? '' : '',
      pushCrmChanges: false,
      role: editMode ? caseRelationToUpdate?.role ?? null : null,
      subordinate: editMode ? caseRelationToUpdate?.parentRelationId ?? null : null,
      teamId: editMode ? caseRelationToUpdate?.teamId ?? null : null,
      vatnumber: editMode ? caseRelationToUpdate?.vat ?? '' : '',
    }),
    [editMode, caseRelationToUpdate],
  );

  const onSubmitHandler = useCallback(
    (formData) => {
      if (createCaseAskForClient) {
        onSubmit(formData);
        return;
      }
      // if createCaseAskForClient is false: isClient = isRequestor
      const { isRequestor } = formData;
      onSubmit({ ...formData, isClient: isRequestor });
    },
    [onSubmit, createCaseAskForClient],
  );

  return (
    <>
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={{
          addressLine: defaultValues.addressLine,
          city: defaultValues.city,
          companyId: defaultValues.companyId,
          companyName: defaultValues.companyName,
          contactId: defaultValues.contactId,
          country: defaultValues.country,
          crmSelector: null,
          emailAddresses: defaultValues.emailAddresses,
          firstName: defaultValues.firstName,
          gender: defaultValues.gender,
          ibans: sortBy(defaultValues.ibans?.map((i) => ({ ...i, tempKey: uuid() })), (iban) => !iban.isDefault),
          involvementType: defaultValues.involvementType,
          isClient: defaultValues.isClient,
          isRequestor: defaultValues.isRequestor,
          language: defaultValues.language,
          lastName: defaultValues.lastName,
          mobilePhoneNumber: {
            countryCode: defaultValues.mobilePhoneNumber.countryCode,
            number: defaultValues.mobilePhoneNumber.number,
          },
          phoneNumber: {
            countryCode: defaultValues.phoneNumber.countryCode,
            number: defaultValues.phoneNumber.number,
          },
          postalCode: defaultValues.postalCode,
          pushCrmChanges: defaultValues.pushCrmChanges,
          role: defaultValues.role,
          subordinate: defaultValues.subordinate,
          teamId: defaultValues.teamId,
          vatnumber: defaultValues.vatnumber,
        }}
        validationSchema={yup.object().shape(
          {
            addressLine: yup.string().nullable(),
            city: yup.string().nullable(),
            companyId: yup
              .string()
              .nullable()
              .test('crmRequired', t('errors:fieldIsRequired'), (value, schema) => {
                if (editMode) {
                  // Not required when editing an existing relation
                  return true;
                }
                const { parent } = schema;
                const { teamId, contactId } = parent;
                return teamId != null || contactId != null || value != null;
              }),
            companyName: yup
              .string()
              .nullable()
              .when('companyId', {
                is: (companyId) => companyId != null,
                then: yup.string().nullable().required(t('errors:fieldIsRequired')),
              }),
            contactId: yup
              .string()
              .nullable()
              .test('crmRequired', t('errors:fieldIsRequired'), (value, schema) => {
                if (editMode) {
                  // Not required when editing an existing relation
                  return true;
                }
                const { parent } = schema;
                const { teamId, companyId } = parent;
                return teamId != null || companyId != null || value != null;
              }),
            country: yup.string().nullable(),
            emailAddresses: yup.array().of(yup.string().nullable()).nullable(),
            firstName: yup.string().nullable(),
            gender: yup
              .string()
              .nullable()
              .when('contactId', {
                is: (contactId) => contactId != null,
                then: yup.string().nullable().default('').required(t('errors:fieldIsRequired')),
              }),
            ibans: yup.array(
              yup.object().shape({
                accountNumber: yup
                  .string()
                  .nullable()
                  .required(t('errors:fieldIsRequired'))
                  .accountNumber(),
                bic: yup
                  .string()
                  .nullable()
                  .required(t('errors:fieldIsRequired'))
                  .bic(),
                isDefault: yup.boolean().required(t('errors:fieldIsRequired')),
              })
            )
              .test('ibans', t('errors:defaultValueIsRequired'), (value) => {
                if (!value?.length) {
                  return true;
                }
                return value.filter(i => i.isDefault).length === 1;
              }),
            involvementType: yup.string().nullable(),
            isClient: yup.bool().nullable(),
            isRequestor: yup.bool().nullable(),
            language: yup.string().nullable().required(t('errors:fieldIsRequired')),
            lastName: yup
              .string()
              .nullable()
              .when('contactId', {
                is: (contactId) => contactId != null,
                then: yup.string().nullable().required(t('errors:fieldIsRequired')),
              }),
            mobilePhoneNumber: yup
              .object()
              .nullable()
              .when('contactId', {
                is: (contactId) => contactId != null,
                then: yup.object().nullable(),
              }),
            phoneNumber: yup.object().nullable(),
            postalCode: yup.string().nullable(),
            role: yup.string().nullable(),
            subordinate: yup.string().nullable(),
            teamId: yup
              .string()
              .nullable()
              .test('crmRequired', t('errors:fieldIsRequired'), (value, schema) => {
                if (editMode) {
                  // Not required when editing an existing relation
                  return true;
                }
                const { parent } = schema;
                const { contactId, companyId } = parent;
                return companyId != null || contactId != null || value != null;
              }),
            vatnumber: yup.string().nullable(),
          },
          ['contactId', 'companyId'],
          ['companyId', 'teamId'],
          ['contactId', 'teamId'],
        )}
      >
        <Form>
          <AddCaseRelationFormBody
            buildingId={buildingId}
            currentCaseRelations={currentCaseRelations}
            editMode={editMode}
            caseRelationToUpdate={caseRelationToUpdate}
            createCaseAskForClient={createCaseAskForClient}
            popperContainer={popperContainer}
          />

          <ModalFooter onCancel={onCancel} />
        </Form>
      </Formik>
      <div ref={popperContainer} />
    </>
  );
};

AddUpdateCaseRelationForm.propTypes = propTypes;

export default AddUpdateCaseRelationForm;
