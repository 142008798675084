import {
  AutocompleteListbox,
  AutocompleteOption,
  ListItemContent,
  ListItemDecorator,
  ListSubheader,
} from '@mui/joy';
import { createContext, forwardRef, useContext } from 'react';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Popper, PopperProps } from '@mui/base';
import classNames from 'classnames';

import { EntityType } from '~/common/enums';

import { BuildingIcon, CompanyIcon, ContactIcon, TeamIcon } from '@/shared/icons/Icons';

const ITEM_SIZE = 36;
const LIST_BOX_PADDING = 8;
const OVERSCAN_COUNT = 10;

const OuterElementContext = createContext({});

const renderRow = (props: ListChildComponentProps) => {
  const { data, index, style } = props;

  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    gap: 0,
    top: (style.top as number) + LIST_BOX_PADDING,
  };

  if (Object.hasOwn(dataSet, 'group')) {
    return (
      <ListSubheader key={dataSet.key} component="li" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    );
  }

  const { type, label } = dataSet[1];

  const icon = (() => {
    switch (type) {
      case EntityType.CentralCompany:
      case EntityType.Company:
      case EntityType.Team:
        return <CompanyIcon />;
      case EntityType.Contact:
        return <ContactIcon />;
      case EntityType.Building:
        return <BuildingIcon />;
      case 'invitation':
        return <TeamIcon />;
      default:
        return null;
    }
  })();

  const isInvitation = type === 'invitation';

  return (
    <AutocompleteOption
      {...dataSet[0]}
      style={inlineStyle}
      className={classNames('text-sm', {
        'text-gray-800': !isInvitation,
        'text-red-500': isInvitation,
      })}
    >
      {!!icon && <ListItemDecorator>{icon}</ListItemDecorator>}
      <ListItemContent>{label}</ListItemContent>
    </AutocompleteOption>
  );
};

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = useContext(OuterElementContext);

  return (
    <AutocompleteListbox
      {...props}
      {...outerProps}
      component="div"
      ref={ref}
      sx={{
        '& ul': {
          flexShrink: 0,
          margin: 0,
          padding: 0,
        },
      }}
    />
  );
});

type Props = {
  anchorEl: PopperProps['anchorEl'];
  open: boolean;
  modifiers: PopperProps['modifiers'];
} & React.HTMLAttributes<HTMLElement>;

const VirtualListBoxAdapter = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { children, anchorEl, open, modifiers, ...rest } = props;

  const items = children as [{ children: React.ReactElement[] | undefined }[]];
  const itemData: unknown[] = [];

  items[0].forEach((item) => {
    if (item) {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  });

  const itemCount = itemData.length;

  return (
    <Popper
      className="z-popperModal"
      ref={ref}
      anchorEl={anchorEl}
      open={open}
      modifiers={modifiers}
    >
      <OuterElementContext.Provider value={rest}>
        <FixedSizeList
          itemData={itemData}
          height={ITEM_SIZE * 16}
          width="100%"
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={ITEM_SIZE}
          overscanCount={OVERSCAN_COUNT}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </Popper>
  );
});

export default VirtualListBoxAdapter;
