import { ChangeEvent, ReactNode } from 'react';
import { Checkbox, FormControl, FormHelperText, FormLabel } from '@mui/joy';
import { FormikValues, getIn, useFormikContext } from 'formik';

type Props = {
  name: string;
  label: string;
  required?: boolean;
  callback?: (value: boolean) => void;
  disabled?: boolean;
  endAdornment?: ReactNode;
  size?: 'sm' | 'md' | 'lg';
  validateOnChange?: boolean;
  noMargin?: boolean;
};

const FormikCheckbox: React.FC<Props> = ({
  name,
  label,
  callback,
  required = false,
  disabled,
  endAdornment,
  size = 'sm',
  validateOnChange = false,
  noMargin = false,
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext<FormikValues>();

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFieldValue(name, e.target.checked, validateOnChange);

    if (callback) {
      callback(e.target.checked);
    }
  };

  return (
    <FormControl
      disabled={disabled}
      className="flex-1"
      error={!!getIn(errors, name) && !!getIn(touched, name)}
      size={size}
    >
      <div className="flex space-x-2">
        <Checkbox name={name} onChange={handleOnChange} checked={getIn(values, name) ?? false} />
        <FormLabel className={noMargin ? 'p-0 m-0 h-[0px]' : ''} required={required}>{label}</FormLabel>
        {!!endAdornment && <div className={noMargin ? 'flex p-0 m-0 h-[0px]' : 'flex'}>{endAdornment}</div>}
      </div>
      {!!getIn(errors, name) && getIn(touched, name) && (
        /* @ts-ignore */
        <FormHelperText className="text-xs">{getIn(errors, name)}</FormHelperText>
      )}
    </FormControl>
  );
};

export default FormikCheckbox;
