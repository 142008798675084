import { CircularProgress, Divider, FormControl, FormLabel, IconButton } from '@mui/joy';
import classNames from 'classnames';
import { Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Attachment as AttachmentType } from '../types';

import { mailbox } from '@/shared/utils/constants';
import useToggle from '@/shared/hooks/UseToggle';

import { AttachmentsIcon, ChevronsDownIcon } from '@/shared/icons/Icons';
import { FileManagerFile, isFileManagerFile } from '@/types/documents';
import Attachment from './Attachment';
import Tooltip from '../../Tooltip';

type Props = {
  allowRemoveOnExisting?: boolean;
  attachments: (AttachmentType | File | FileManagerFile)[];
  commonAttachments?: (File | FileManagerFile)[];
  isLoadingAttachments?: boolean;
  label?: string;
  onAdd?: (files: File[]) => void;
  onDownload?: (file: AttachmentType | FileManagerFile) => void;
  onRemove?: (fileId: string) => void;
  readOnly?: boolean;
  setCommonAttachments?: (files: (File | FileManagerFile)[]) => void;
  onRemoveFromCommonAttachments?: (key: string) => void;
  showCommonAttachmentUpload?: boolean;
};

const ATTACHMENTS_TO_DISPLAY = 10;

const getKey = (file: AttachmentType | File | FileManagerFile) =>
  file instanceof File ? file.name : isFileManagerFile(file) ? file.fileId : file.id;

const Spinner = () => (
  <div className="ml-4 mr-2 flex h-12 items-center">
    <CircularProgress size="sm" />
  </div>
);

const Attachments: React.FC<Props> = ({
  allowRemoveOnExisting,
  attachments,
  commonAttachments = [],
  isLoadingAttachments = false,
  label,
  onAdd,
  onDownload,
  onRemove,
  readOnly = false,
  setCommonAttachments = () => {},
  onRemoveFromCommonAttachments = () => {},
  showCommonAttachmentUpload,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'errors']);

  const { getRootProps: getIndividualFilesRootProps, getInputProps: getIndividualFilesInputProps } =
    useDropzone({
      maxSize: mailbox.ATTACHMENT_MAX_SIZE,
      onDropAccepted: onAdd,
      onDropRejected: () => {
        enqueueSnackbar(t('errors:failedToAddAttachments'), { variant: 'error' });
      },
    });

  const { getRootProps: getCommonFilesRootProps, getInputProps: getCommonFilesInputProps } =
    useDropzone({
      maxSize: mailbox.ATTACHMENT_MAX_SIZE,
      onDropAccepted: (files: File[]) => setCommonAttachments(files),
      onDropRejected: () => {
        enqueueSnackbar(t('errors:failedToAddAttachments'), { variant: 'error' });
      },
    });

  const isCollapsible = attachments.length > ATTACHMENTS_TO_DISPLAY;
  const shouldCollapse = readOnly && attachments.length === 0;

  const toggle = useToggle(!isCollapsible);

  return (
    <FormControl className="flex-1">
      {label && <FormLabel>{label}</FormLabel>}
      {isCollapsible && (
        <div className="my-2 flex">
          <button
            className="flex items-center space-x-2 text-xs text-primary"
            type="button"
            onClick={toggle.inverse}
          >
            <ChevronsDownIcon
              className={classNames('transition-all duration-150', {
                'rotate-180': toggle.value,
              })}
            />
            <span>
              {toggle.value
                ? t('hideType', { type: t('attachments').toLowerCase() })
                : t('showType', { type: t('attachments').toLowerCase() })}
              ({attachments.length})
            </span>
          </button>
        </div>
      )}

      {showCommonAttachmentUpload ? (
        <div className="flex">
          <div className="flex gap-2">
            <div className="flex">
              {!readOnly && (
                <div {...getIndividualFilesRootProps()}>
                  <input {...getIndividualFilesInputProps()} />

                  <div className="flex flex-col space-y-2">
                    <FontAwesomeIcon icon={['fas', 'person']} />

                    <Tooltip label={t('addTypes', { type: t('individualAttachments') })}>
                      <IconButton size="sm" className="h-12">
                        <AttachmentsIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
              <Collapse className="flex items-end" in={toggle.value && !shouldCollapse}>
                {isLoadingAttachments ? (
                  <Spinner />
                ) : (
                  <div
                    className={classNames('flex max-h-[6.5rem] flex-wrap gap-2 overflow-y-auto', {
                      'ml-2': !!attachments.length,
                    })}
                  >
                    {attachments.map((file, index) => (
                      <Attachment
                        key={`${getKey(file)}-${index}`}
                        attachment={file}
                        onRemove={onRemove}
                        onDownload={onDownload}
                        alwaysAllowRemove={allowRemoveOnExisting}
                      />
                    ))}
                  </div>
                )}
              </Collapse>
            </div>
            <Divider orientation="vertical" />
            <div className="flex">
              {!readOnly && (
                <div {...getCommonFilesRootProps()}>
                  <input {...getCommonFilesInputProps()} />
                  <Tooltip label={t('addTypes', { type: t('commonAttachments') })}>
                    <div className="flex flex-col space-y-2">
                      <FontAwesomeIcon icon={['fas', 'people-group']} />
                      <IconButton size="sm" className="h-12">
                        <AttachmentsIcon iconStyle="fas" />
                      </IconButton>
                    </div>
                  </Tooltip>
                </div>
              )}

              <Collapse
                in={toggle.value && !shouldCollapse}
                sx={{ alignItems: 'end', display: 'flex' }}
              >
                <div
                  className={classNames('flex max-h-[6.5rem] flex-wrap gap-2 overflow-y-auto', {
                    'ml-2': !!commonAttachments.length,
                  })}
                >
                  {commonAttachments.map((file, index) => (
                    <Attachment
                      key={`${file.name}-${index}`}
                      attachment={file}
                      onRemove={onRemoveFromCommonAttachments}
                      onDownload={onDownload}
                      alwaysAllowRemove={allowRemoveOnExisting}
                    />
                  ))}
                </div>
              </Collapse>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center">
          {!readOnly && (
            <div {...getIndividualFilesRootProps()}>
              <input {...getIndividualFilesInputProps()} />
              <Tooltip label={t('addTypes', { type: t('attachments') })}>
                <IconButton size="sm" className="mr-2 h-12">
                  <AttachmentsIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <Collapse in={toggle.value && !shouldCollapse}>
            {isLoadingAttachments ? (
              <Spinner />
            ) : (
              <div
                className={classNames('mb-1 flex max-h-[6.5rem] flex-wrap gap-2 overflow-y-auto', {
                  'mt-1': !label,
                })}
              >
                {attachments.map((file) => (
                  <Attachment
                    key={getKey(file)}
                    attachment={file}
                    onRemove={onRemove}
                    onDownload={onDownload}
                    alwaysAllowRemove={allowRemoveOnExisting}
                  />
                ))}
              </div>
            )}
          </Collapse>
        </div>
      )}
    </FormControl>
  );
};

export default Attachments;
