import { useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  BalancesInnerWrapper,
  BalancesWrapper,
  InboundSide,
  OpenTitleAmount,
  OutboundSide,
} from '../../../FinancialManagement/BodySections/Transactions/Styles/Styles';
import { distinct, formatAsCurrency } from '../../../../../shared/utils/helpers';
import API from '../../../../../shared/api/ApiService';
import { CaseContext } from '@/containers/Cases/CaseContext';
import useNotification from '../../../../../shared/hooks/UseNotification';
import useToggle from '@/shared/hooks/UseToggle';

import AddEditPaymentModal from './AddEditPayment/AddEditPaymentModal';
import { AddIcon } from '../../../../../shared/icons/Icons';
import IconButton from '../../../../../shared/components/buttons/IconButton';
import PaymentDisplay from './PaymentDisplay';
import RemarkModal from '@/shared/components/2.0/RemarkModal';

const AddPaymentButton = styled(IconButton)`
  position: absolute;
`;

const AddPaymentButtonOutbound = styled(AddPaymentButton)`
  right: 16px;
`;

const PaymentsOverview = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [remarkModalData, setRemarkModalData] = useState({ open: false });

  const [selectedPayment, setSelectedPayment] = useState();
  const [isInboundPayment, setIsInboundPayment] = useState();

  const { currentCase, canEditCase } = useContext(CaseContext);
  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const paymentToggle = useToggle();

  const { payments } = currentCase;

  const linkedPaymentIds = useMemo(
    () =>
      payments
        .filter((payment) => !payment.inbound && payment.linkedPaymentId)
        .map((payment) => payment.linkedPaymentId)
        .filter(distinct),
    [payments],
  );

  const formattedPayments = useMemo(
    () =>
      payments.map((payment) => {
        const id = payment.inbound ? payment.id : payment.linkedPaymentId;
        const index = linkedPaymentIds.indexOf(id);
        const linkNumber = index !== -1 ? index + 1 : null;

        return {
          ...payment,
          linkNumber,
        };
      }),
    [payments, linkedPaymentIds],
  );

  const totals = useMemo(() => {
    const calculateTotalAmount = (paymentList) => _.sumBy(paymentList, (payment) => payment.amount);

    const inboundPayments = payments.filter((payment) => payment.inbound);
    const openInboundPayments = inboundPayments.filter((payment) => !payment.completed);

    const outboundPayments = payments.filter((payment) => !payment.inbound);
    const openOutboundPayments = outboundPayments.filter((payment) => !payment.completed);

    return {
      inbound: calculateTotalAmount(inboundPayments),
      inboundOpen: calculateTotalAmount(openInboundPayments),
      outbound: calculateTotalAmount(outboundPayments),
      outboundOpen: calculateTotalAmount(openOutboundPayments),
    };
  }, [payments]);

  const updateRemark = async (id, remark, setSubmitting) => {
    const data = { caseId: currentCase.id, id, remark };

    const response = await API.putUpdateCasePaymentRemark(data);
    setSubmitting(false);

    if (response.serviceError || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    setRemarkModalData({ open: false });
    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyUpdated', { type: t('payment') }),
      variant: 'success',
    });
  };

  const removePayment = async (id) => {
    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    const response = await API.deleteRemovePaymentFromCase({ caseId: currentCase.id, id });
    setIsSubmitting(false);

    if (response.serviceError || response.status !== 204) {
      sendDefaultError(response);
      return;
    }

    sendNotification({
      header: t('success'),
      message: t('typeSuccessfullyRemoved', { type: t('payment') }),
      variant: 'success',
    });
  };

  const onAddEditSubmit = async (formData) => {
    if (isSubmitting) {
      return;
    }

    const promise = selectedPayment ? API.putUpdateCasePayment : API.postAddCasePayment;

    setIsSubmitting(true);
    const response = await promise({
      ...formData,
      caseId: currentCase.id,
      id: selectedPayment?.id,
      inbound: isInboundPayment,
    });
    setIsSubmitting(false);

    if (response.serviceError || response.status !== 200) {
      sendDefaultError(response);
      setSelectedPayment(null);

      return;
    }

    paymentToggle.hide();
    sendNotification({
      header: t('success'),
      message: t(`typeSuccessfully${selectedPayment ? 'Updated' : 'Added'}`, {
        type: t('payment'),
      }),
      variant: 'success',
    });

    setSelectedPayment(null);
  };

  return (
    <>
      <div className="flex">
        <div className="w-1/2">
          <h4 className="underline">{t('inboundPayments')}</h4>
          <OpenTitleAmount>
            {t('open')}: {formatAsCurrency(totals.inboundOpen, true)}
          </OpenTitleAmount>
        </div>
        <div className="w-1/2 text-right">
          <h4 className="underline">{t('outboundPayments')}</h4>
          <OpenTitleAmount>
            {t('open')}: {formatAsCurrency(totals.outboundOpen, true)}
          </OpenTitleAmount>
        </div>
      </div>

      <div className="flex">
        <InboundSide>
          {canEditCase && (
            <AddPaymentButton
              iconComponent={<AddIcon />}
              size="2x"
              color="keypoint"
              onClick={() => {
                setIsInboundPayment(true);
                paymentToggle.show();
              }}
            />
          )}
          {formattedPayments
            .filter((payment) => payment.inbound)
            .map((payment) => (
              <PaymentDisplay
                canEdit={canEditCase}
                key={payment.id}
                payment={payment}
                openRemarkModal={() => setRemarkModalData({ ...payment, open: true })}
                onRemove={() => removePayment(payment.id)}
                onEdit={() => {
                  setSelectedPayment(payment);
                  paymentToggle.show();
                }}
              />
            ))}
        </InboundSide>
        <OutboundSide>
          {canEditCase && (
            <AddPaymentButtonOutbound
              iconComponent={<AddIcon />}
              size="2x"
              color="keypoint"
              onClick={() => {
                setIsInboundPayment(false);
                paymentToggle.show();
              }}
            />
          )}
          {formattedPayments
            .filter((payment) => !payment.inbound)
            .map((payment) => (
              <PaymentDisplay
                canEdit={canEditCase}
                key={payment.id}
                payment={payment}
                openRemarkModal={() => setRemarkModalData({ ...payment, open: true })}
                onEdit={() => {
                  setSelectedPayment(payment);
                  paymentToggle.show();
                }}
                onRemove={() => removePayment(payment.id)}
              />
            ))}
        </OutboundSide>
      </div>
      <BalancesWrapper>
        <BalancesInnerWrapper>
          <div className="flex">
            <div className="w-50 text-nowrap">{t('inbound')}:</div>
            <div className="w-50 text-end">{formatAsCurrency(totals.inbound, true)}</div>
          </div>
          <div className="flex">
            <div className="w-50 text-nowrap">{t('outbound')}:</div>
            <div className="w-50 text-end">{formatAsCurrency(totals.outbound, true)}</div>
          </div>
          <div className="flex">
            <div className="w-50 text-nowrap">{t('finance:balance')}:</div>
            <div className="w-50 text-end">
              {formatAsCurrency(totals.inbound - totals.outbound, true)}
            </div>
          </div>
        </BalancesInnerWrapper>
      </BalancesWrapper>

      <AddEditPaymentModal
        toggle={paymentToggle}
        onSubmit={onAddEditSubmit}
        initialPayment={selectedPayment}
        inbound={isInboundPayment}
        caseId={currentCase.id}
        inboundPayments={payments.filter((p) => p.inbound)}
        onClose={() => setSelectedPayment(null)}
      />

      <RemarkModal
        id={remarkModalData.id}
        remark={remarkModalData.remark}
        isOpen={remarkModalData.open}
        setIsOpen={() => setRemarkModalData({ open: false })}
        submit={updateRemark}
      />
    </>
  );
};

export default PaymentsOverview;
