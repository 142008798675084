import { Box, Stack, type Theme } from '@mui/joy';

type Props = {
  width?: string;
  height?: string;
};

const SkeletonRow: React.FC<Props> = ({ width = '100%', height = '2.5vh' }) => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.palette.background.level2,
      borderRadius: theme.vars.radius.lg,
      height,
      minHeight: '1.5rem',
      width,
    })}
  />
);

const getStackGapSx = (theme: Theme) => ({
  gap: '0.5rem',
  [theme.breakpoints.up('sm')]: {
    gap: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    gap: '2rem',
  },
});

const Skeleton: React.FC = () => (
  <Box
    alignContent="center"
    left={0}
    minHeight="100%"
    paddingX="25%"
    paddingY="5%"
    position="absolute"
    sx={(theme) => ({
      backgroundColor: theme.palette.background.surface,
      zIndex: theme.vars.zIndex.tooltip,
    })}
    top={0}
    width="100%"
  >
    <Stack
      className="animate-pulse"
      justifyContent="center"
      sx={(theme) => ({
        gap: '1rem',
        [theme.breakpoints.up('md')]: {
          gap: '1.5rem',
        },
        [theme.breakpoints.up('lg')]: {
          gap: '2rem',
        },
        [theme.breakpoints.up('xl')]: {
          gap: '4rem',
        },
      })}
    >
      <Stack direction="row" sx={getStackGapSx}>
        <Box
          sx={(theme) => ({
            alignSelf: 'flex-start',
            aspectRatio: 1,
            backgroundColor: theme.palette.background.level2,
            borderRadius: '100%',
            width: '25%',
          })}
        />
        <Stack flexGrow={1} sx={getStackGapSx}>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow width="75%" />
        </Stack>
      </Stack>
      <Stack sx={getStackGapSx}>
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
        <SkeletonRow />
      </Stack>
      <Stack direction="row" sx={getStackGapSx}>
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.background.level2,
            borderRadius: theme.vars.radius.lg,
            width: '50%',
          })}
        />
        <Stack flexGrow={1} sx={getStackGapSx}>
          <SkeletonRow />
          <SkeletonRow />
          <SkeletonRow width="75%" />
        </Stack>
      </Stack>
    </Stack>
  </Box>
);

export default Skeleton;
