import { Button, ColorPaletteProp, DialogActions, Modal, ModalClose, ModalDialog } from '@mui/joy';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '../hooks/UseToggle';

import { CircleExclamation, InlineSpinner } from '../icons/Icons';
import ConfirmationModalBody from './ConfirmationModalBody';

type ButtonProps = {
  primaryFilled?: boolean;
  confirmAsPrimary?: boolean;
  isProcessing?: boolean;
};

type Props<T> = {
  className?: string;
  color?: ColorPaletteProp;
  icon?: React.ReactNode;
  keepOpenOnConfirm?: boolean;
  onClose?: () => void;
  onDecline?: () => void;
  onConfirm: () => void;
  title?: string;
  toggle: UseToggle<T>;
  buttonProps?: ButtonProps;
  confirmText?: string;
  declineText?: string;
  maxWidth?: string;
  additionalActions?: React.ReactNode;
};

const ConfirmationModal = <T,>({
  children,
  className = 'w-1/2',
  maxWidth = 'xl',
  color = 'primary',
  icon = <CircleExclamation />,
  keepOpenOnConfirm = false,
  onClose,
  onDecline,
  onConfirm,
  title,
  toggle,
  buttonProps = { confirmAsPrimary: true, isProcessing: false, primaryFilled: true },
  confirmText,
  declineText,
  additionalActions,
}: React.PropsWithChildren<Props<T>>) => {
  const { t } = useTranslation();
  const { primaryFilled = true, confirmAsPrimary = true, isProcessing = false } = buttonProps;
  const handleClose = () => {
    onClose?.();
    toggle.hide();
  };

  const handleDecline = () => {
    if (onDecline) onDecline();
    else onClose?.();

    toggle.hide();
  };

  const handleModalConfirm = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();

    if (!keepOpenOnConfirm) toggle.hide();

    onConfirm();
  };

  return (
    <Modal open={toggle.value} onClose={handleClose}>
      <ModalDialog className={classNames(`max-w-${maxWidth}`, className)}>
        <ModalClose />
        <ConfirmationModalBody title={title} color={color} icon={icon}>
          {children}
        </ConfirmationModalBody>
        <DialogActions className={classNames({ 'flex-row self-end': !confirmAsPrimary })}>
          <Button
            color={confirmAsPrimary ? color : 'neutral'}
            variant={primaryFilled && confirmAsPrimary ? 'solid' : 'outlined'}
            disabled={isProcessing}
            startDecorator={isProcessing ? <InlineSpinner /> : null}
            onClick={handleModalConfirm}
            className="flex-shrink-0"
          >
            {confirmText ?? t('confirm')}
          </Button>
          <Button
            color={!confirmAsPrimary ? color : 'neutral'}
            variant={!confirmAsPrimary && primaryFilled ? 'solid' : 'outlined'}
            onClick={handleDecline}
          >
            {declineText ?? t('cancel')}
          </Button>

          {additionalActions}
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationModal;
