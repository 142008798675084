import * as yup from 'yup';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import ContactPropTypes from '@/shared/prop-types/ContactPropTypes';
import EmailContext from '~/common/enums/emailContext';
import Language from '@/shared/enums/Language';

import AddEditContactFormBody from '@/containers/Contacts/AddEditContact/AddEditContactFormBody';

const propTypes = {
  contact: ContactPropTypes,
  disabled: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  minified: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const AddEditContactForm = ({
  contact = {
    address: {},
    firstName: '',
    lastName: '',
  },
  disabled = false,
  onSubmit,
  onCancel,
  minified = false,
  isSubmitting = false,
}) => {
  const { t } = useTranslation(['common', 'errors']);

  return (
    <Formik
      onSubmit={onSubmit}
      validateOnMount
      initialValues={{
        addressLine: contact?.address?.addressLine ?? '',
        city: contact?.address?.city ?? '',
        contactTags: contact?.contactTags?.map((tag) => ({
          label: tag.tagDescription,
          value: tag.tagId,
        })),
        country: contact?.address?.country ?? 'BE',
        defaultCaseRole: contact?.defaultCaseRole,
        emailAddresses: contact?.emailAddresses || [
          {
            email: '',
            emailContext: [EmailContext.All],
          },
        ],
        firstName: contact?.firstName ?? '',
        gender: contact?.gender ?? null,
        ibans: sortBy(contact.ibans?.map((i) => ({ ...i, tempKey: uuid() })), (iban) => !iban.isDefault),
        language: contact?.language ?? Language.Dutch,
        lastName: contact?.lastName ?? '',
        mobilePhoneNumber: {
          countryCode: contact?.mobilePhoneNumber?.countryCode ?? '',
          number: contact?.mobilePhoneNumber?.number ?? '',
        },
        phoneNumber: {
          countryCode: contact?.phoneNumber?.countryCode ?? '',
          number: contact?.phoneNumber?.number ?? '',
        },
        postalCode: contact?.address?.postalCode ?? '',
      }}
      validationSchema={yup.object().shape({
        addressLine: yup.string().nullable(),
        city: yup.string().nullable(),
        defaultCaseRole: yup.string().nullable(),
        emailAddresses: yup.array(
          yup.object().shape({
            email: yup.string().email(t('errors:invalidEmail')),
            emailContext: yup.array(yup.number()),
          }),
        ),
        firstName: yup.string().nullable(),
        gender: yup.string().nullable(),
        ibans: yup.array(
          yup.object().shape({
            accountNumber: yup
              .string()
              .nullable()
              .required(t('errors:fieldIsRequired'))
              .accountNumber(),
            bic: yup
              .string()
              .nullable()
              .required(t('errors:fieldIsRequired'))
              .bic(),
            isDefault: yup.boolean().required(t('errors:fieldIsRequired')),
          })
        )
          .test('ibans', t('errors:defaultValueIsRequired'), (value) => {
            if (!value?.length) {
              return true;
            }
            return value.filter(i => i.isDefault).length === 1;
          }),
        language: yup.string().nullable(),
        lastName: yup.string().nullable().required(t('errors:fieldIsRequired')),
        mobilePhoneNumber: yup.object().nullable(),
        phoneNumber: yup.object().nullable(),
        postalCode: yup.number().nullable().typeError(t('errors:invalidNumber')),
      })}
    >
      <Form>
        <AddEditContactFormBody
          disabled={disabled}
          isSubmitting={isSubmitting}
          minified={minified}
          onCancel={onCancel}
        />
      </Form>
    </Formik>
  );
};

AddEditContactForm.propTypes = propTypes;

export default AddEditContactForm;
