import { lowerFirst, sortBy } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { isBoardMemberRole, isSyndicRole, SplitRoles } from './buildingRoleSelector.types';
import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';

import { AppContext } from '@/shared/context/context';
import { mergeRoles } from './buildingRoleSelector.utils';

const buildingRoles = Object.values(BuildingRoleSelectorOption).filter(
  (role) => role !== BuildingRoleSelectorOption.None,
);

const useBuildingRoleSelectorOptions = (value: BuildingRoleSelectorOption[] | SplitRoles) => {
  const { currentTeam } = useContext(AppContext);
  const { t } = useTranslation();

  const selectedValues = useMemo(() => {
    if (Array.isArray(value)) return value;

    return mergeRoles(value);
  }, [value]);

  const roleOptions = useMemo(() => {
    const options = buildingRoles
      .filter((role) => !!currentTeam?.allowSyndicManagement || !isSyndicRole(role))
      .map((role) => {
        const key = lowerFirst(role);
        const parent =
          !!currentTeam?.allowSyndicManagement && isBoardMemberRole(role)
            ? BuildingRoleSelectorOption.BoardOfCoOwnership
            : null;

        return {
          label: isBoardMemberRole(role) ? t(`boardMemberRole.${key}`) : t(key),
          parent,
          parentLabel: parent ? t(lowerFirst(parent)) : null,
          value: role,
        };
      });

    const sortedOptions = sortBy(options, (option) => {
      const { label, parentLabel } = option;

      return [parentLabel, label].filter(Boolean).join('_');
    });

    return {
      list: sortedOptions,
      selected: sortedOptions.filter((option) => selectedValues?.includes(option.value)),
    };
  }, [currentTeam?.allowSyndicManagement, selectedValues, t]);

  type BuildingRoleOption = (typeof roleOptions)['list'][number];

  const isOptionEqualToValue = useCallback(
    (option: BuildingRoleOption, selectedOption: BuildingRoleOption) => {
      if (selectedOption.value === BuildingRoleSelectorOption.AllContactsAndCompanies) {
        return true;
      }
      if (
        selectedOption.value === BuildingRoleSelectorOption.BoardOfCoOwnership &&
        isBoardMemberRole(option.value)
      ) {
        return true;
      }

      return option.value === selectedOption.value;
    },
    [],
  );

  return {
    isOptionEqualToValue,
    options: roleOptions.list,
    selectedOptions: roleOptions.selected,
  };
};

export type RoleOption = ReturnType<typeof useBuildingRoleSelectorOptions>['options'][number];

export default useBuildingRoleSelectorOptions;
