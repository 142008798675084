import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Typo from 'typo-js';

import { SpellCheckRes } from '../types';

import { applySpellCheckStyling, parseHtmlString, removeSpellCheckStyling } from '../utils';
import { localStorageKeys } from '@/shared/utils/constants';
import { REGEX } from '../constants';
import usePersistentState from '@/shared/hooks/usePersistentState';

export enum Dictionary {
  EN = 'en-gb',
  FR = 'fr',
  NL = 'nl',
}

const enum Extension {
  AFF = 'aff',
  DIC = 'dic',
}

const BASE_ROUTE = '/assets/dictionaries';

const useSpellCheck = () => {
  const prevDicRef = useRef<Dictionary | null>(null);

  const [dic, setDic] = usePersistentState<Dictionary>({
    localStorageKey: localStorageKeys.spellChecker,
  });
  const [touched, setTouched] = useState(false);
  const [typo, setTypo] = useState<Typo | null>(null);

  const checkWord = useCallback(
    (word: string) => {
      if (!typo) throw new Error('Tried to use spellChecker before initialization');

      return typo.check(word);
    },
    [typo],
  );

  const checkHtml = useCallback(
    (html: string) => {
      if (!html) return '';

      const htmlDoc = parseHtmlString(html);
      const cleanedHtmlDoc = removeSpellCheckStyling(htmlDoc);
      const treeWalker = document.createTreeWalker(cleanedHtmlDoc, NodeFilter.SHOW_TEXT);

      let currentNode = treeWalker.nextNode();

      const spellcheckWord = (word: string, node: Node) => {
        if (!node?.textContent || REGEX.NUMBER.test(word))
          return {
            isValid: true,
            node,
            word,
          };

        return {
          isValid: checkWord(word),
          node,
          word,
        };
      };

      let wordValidationResults: SpellCheckRes[] = [];

      while (currentNode) {
        if (currentNode.textContent) {
          const node = currentNode;
          const nodeWords = currentNode.textContent.match(REGEX.WORD) ?? [];
          const nodeResults = nodeWords.map((word) => spellcheckWord(word, node));

          wordValidationResults = [...wordValidationResults, ...nodeResults];
        }

        currentNode = treeWalker.nextNode();
      }

      wordValidationResults.forEach(applySpellCheckStyling);

      return htmlDoc.body.innerHTML;
    },
    [checkWord],
  );

  const setDictionary = useCallback(
    (dictionary: Dictionary | null) => {
      if (dictionary === prevDicRef.current) return;

      setDic(dictionary);
      setTouched(true);
    },
    [setDic],
  );

  useEffect(() => {
    const loadDic = async () => {
      if (dic === prevDicRef.current) return;

      let typoDic;

      if (dic) {
        const filePath = `${BASE_ROUTE}/${dic}/${dic}`;

        const affRes = await fetch(`${filePath}.${Extension.AFF}`);
        const dicRes = await fetch(`${filePath}.${Extension.DIC}`);

        const affData = await affRes.text();
        const wordsData = await dicRes.text();

        typoDic = new Typo(dic, affData, wordsData);
      }

      setTypo(typoDic ?? null);

      prevDicRef.current = dic;
    };

    loadDic();
  }, [dic]);

  const spellChecker = useMemo(
    () => ({
      checkHtml,
      checkWord,
      currentDictionary: dic,
      hasTouchedDictionary: touched,
      isLoading: !!dic && !typo,
      setDictionary,
      typo,
    }),
    [checkHtml, checkWord, dic, setDictionary, touched, typo],
  );

  return spellChecker;
};

export default useSpellCheck;
