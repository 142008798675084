import { AutocompleteRenderGetTagProps, Chip, ChipDelete } from '@mui/joy';
import { useDrag, useDrop } from 'react-dnd';
import Close from '@mui/icons-material/Close';
import { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { EmailSelectorProps, Option, SelectableOption } from './types';

import { isDndItem, isSelectable, optionToContact } from './utils';

import SelectEmailContact from './SelectEmailContact';

const TYPE = 'CONTACT';

type ChipProps = {
  getTagProps: AutocompleteRenderGetTagProps;
  index: number;
  item: Option;
  name: string;
  onChange: React.Dispatch<SelectableOption[]>;
  useEmailAsLabel?: boolean;
  value: SelectableOption[];
};

const DraggableChip: React.FC<ChipProps> = ({
  getTagProps,
  index,
  item,
  name,
  onChange,
  useEmailAsLabel = true,
  value,
}) => {
  const getDragItem = () => {
    if (!isSelectable(item)) return null;

    const contact = optionToContact(item);

    return { contact, source: name };
  };

  const [dragState, dragRef] = useDrag(() => {
    const toDrag = getDragItem();

    if (!toDrag)
      return {
        type: TYPE,
      };

    return {
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      item: toDrag,
      type: TYPE,
    };
  });

  const label = useMemo(() => {
    if (!useEmailAsLabel || !isSelectable(item)) return item.label;

    return item.value;
  }, [item, useEmailAsLabel]);

  const handleDelete: React.MouseEventHandler = () => {
    if (!isSelectable(item)) return;

    const valueWithoutSelf = value.filter((option) => option.value !== item.value);

    onChange(valueWithoutSelf);
  };

  const { onClick, ...tagProps } = getTagProps({ index });

  return (
    <Chip
      {...tagProps}
      ref={dragRef}
      color="neutral"
      endDecorator={
        <ChipDelete onMouseDown={handleDelete}>
          <Close className="h-4 opacity-80 hover:opacity-100" />
        </ChipDelete>
      }
      onMouseDown={(e) => e.stopPropagation()}
      sx={{
        opacity: dragState.isDragging ? 0.5 : 1,
      }}
      variant="soft"
    >
      {label}
    </Chip>
  );
};

type Props = EmailSelectorProps & {
  name: string;
  onDrop: (id: string, from: string, to: string) => void;
};

const SelectEmailContactDraggable: React.FC<Props> = ({
  name,
  onDrop,
  useEmailAsLabel,
  ...selectProps
}) => {
  const handleDrop = (item: unknown) => {
    if (!isDndItem(item) || item.source === name) return;

    const itemId = (() => {
      const { companyId, contactId, email } = item.contact;

      if (contactId) return contactId;
      if (companyId) return companyId;

      return email;
    })();

    onDrop(itemId, item.source, name);
  };

  const [_, dropRef] = useDrop({
    accept: TYPE,
    drop: handleDrop,
  });

  const renderTags = (tags: Option[], getTagProps: AutocompleteRenderGetTagProps) =>
    tags.map(
      (tag, index) =>
        selectProps.multiple && (
          <DraggableChip
            key={uuidv4()}
            getTagProps={getTagProps}
            index={index}
            item={tag}
            name={name}
            useEmailAsLabel={useEmailAsLabel}
            value={selectProps.value}
            onChange={selectProps.onChange}
          />
        ),
    );

  return (
    <SelectEmailContact
      ref={dropRef}
      {...selectProps}
      renderTags={selectProps.multiple ? renderTags : undefined}
      useEmailAsLabel={useEmailAsLabel}
    />
  );
};

export default SelectEmailContactDraggable;
