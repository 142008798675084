import { CLASSNAMES } from './constants';
import { SpellCheckRes } from './types';

export const parseHtmlString = (html: string) => {
  const parser = new DOMParser();

  return parser.parseFromString(html, 'text/html');
};

export const applySpellCheckStyling = ({ isValid, node, word }: SpellCheckRes) => {
  if (isValid || !node?.textContent) return;

  const index = node.textContent.indexOf(word);

  if (index === -1) return;

  const range = document.createRange();

  range.setStart(node, index);
  range.setEnd(node, index + word.length);

  const wordWrapper = document.createElement('span');

  wordWrapper.className = CLASSNAMES.SPELLING_SPAN;

  range.surroundContents(wordWrapper);
};

export const removeSpellCheckStyling = <IHtml extends Document | HTMLElement>(el: IHtml): IHtml => {
  const typoWrappers = el.querySelectorAll(`span.${CLASSNAMES.SPELLING_SPAN}`);

  typoWrappers.forEach((wrapper) => {
    if (!wrapper.textContent) {
      wrapper.parentNode?.removeChild(wrapper);

      return;
    }

    const textNode = document.createTextNode(wrapper.textContent);

    wrapper.parentNode?.replaceChild(textNode, wrapper);
  });

  return el;
};

export const removeSpellCheckStylingFromHtml = (html: string) => {
  const htmlDoc = parseHtmlString(html);

  return removeSpellCheckStyling(htmlDoc).body.innerHTML;
};
