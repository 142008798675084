import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';

import {
  BOARD_MEMBER_ROLES,
  BUILDING_UNIT_ROLES,
  SYNDIC_ROLES,
} from './buildingRoleSelector.constants';

export type BoardMemberRole = (typeof BOARD_MEMBER_ROLES)[number];

export type BuildingUnitRole = (typeof BUILDING_UNIT_ROLES)[number];

export type SyndicRole = (typeof SYNDIC_ROLES)[number];

export const isBoardMemberRole = (role: BuildingRoleSelectorOption): role is BoardMemberRole =>
  BOARD_MEMBER_ROLES.includes(role as BoardMemberRole);

export const isBuildingUnitRole = (role: BuildingRoleSelectorOption): role is BuildingUnitRole =>
  BUILDING_UNIT_ROLES.includes(role as BuildingUnitRole);

export const isSyndicRole = (role: BuildingRoleSelectorOption): role is SyndicRole =>
  SYNDIC_ROLES.includes(role as SyndicRole);

export type SplitRoles = {
  boardMemberRoles: BoardMemberRole[];
  buildingUnitRoles: BuildingUnitRole[];
};
