import { useCallback, useMemo, useRef } from 'react';

type Position = {
  node: Node;
  offset: number;
};

type Selection = {
  collapsed: boolean;
  endOffset: number;
  startOffset: number;
};

const useSelection = () => {
  const savedSelectionRef = useRef<Selection | null>(null);

  const findPosition = useCallback((root: HTMLElement, savedOffset: number) => {
    const treeWalker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);

    let charCount = 0;
    let position: Position | null = null;

    let currentNode = treeWalker.nextNode();

    while (currentNode) {
      const nodeLength = currentNode.textContent?.length ?? 0;

      if (charCount <= savedOffset && charCount + nodeLength >= savedOffset) {
        position = {
          node: currentNode,
          offset: savedOffset - charCount,
        };

        break;
      }

      charCount += nodeLength;
      currentNode = treeWalker.nextNode();
    }

    return position;
  }, []);

  const restore = useCallback(
    (root: HTMLElement) => {
      const selection = window.getSelection();
      const savedSelection = savedSelectionRef.current;

      if (!selection || !savedSelection) return;

      const startPosition = findPosition(root, savedSelection.startOffset);

      if (!startPosition) return;

      const range = document.createRange();

      range.setStart(startPosition.node, startPosition.offset);

      if (savedSelection.collapsed) {
        range.collapse(true);
      } else {
        const endPosition = findPosition(root, savedSelection.endOffset);

        if (!endPosition) {
          range.collapse(true);
        } else {
          range.setEnd(endPosition.node, endPosition.offset);
        }
      }

      selection.removeAllRanges();
      selection.addRange(range);
    },
    [findPosition],
  );

  const save = useCallback((root: HTMLElement) => {
    const selection = window.getSelection();

    if (!selection?.rangeCount) {
      savedSelectionRef.current = null;

      return;
    }

    const range = selection.getRangeAt(0);

    const startOffset = (() => {
      const startRange = document.createRange();

      startRange.setStart(root, 0);
      startRange.setEnd(range.startContainer, range.startOffset);

      return startRange.toString().length;
    })();

    const endOffset = (() => {
      if (range.collapsed) return startOffset;

      const endRange = document.createRange();

      endRange.setStart(root, 0);
      endRange.setEnd(range.endContainer, range.endOffset);

      return endRange.toString().length;
    })();

    savedSelectionRef.current = {
      collapsed: range.collapsed,
      endOffset,
      startOffset,
    };
  }, []);

  const utils = useMemo(
    () => ({
      restore,
      save,
    }),
    [restore, save],
  );

  return utils;
};

export default useSelection;
