import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AddEditAppointmentFormikValues } from '../FormikValues';
import { CalendarEvent } from '~/common/types/calendars/events';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { CaseContext } from '@/containers/Cases/CaseContext';
import { sentencize } from '@/shared/utils/helpers';
import useAddEditAppointmentValidation from './useAddEditAppointmentValidation';

import AddEditAppointmentFormBody from './AddEditAppointmentFormBody';
import FormModal from '@/shared/components/2.0/layout/FormModal';

type Props<TToggleState> = {
  calendarId?: string;
  initialAppointment?: CalendarEvent;
  onSubmit: (value: AddEditAppointmentFormikValues) => void;
  showLinkedCase?: boolean;
  toggle: UseToggle<TToggleState>;
};

const AddEditAppointmentModal = <TToggleState,>({
  calendarId,
  initialAppointment,
  onSubmit,
  showLinkedCase,
  toggle,
}: Props<TToggleState>) => {
  const { currentCase } = useContext(CaseContext);
  const { t } = useTranslation();
  const { validationSchema } = useAddEditAppointmentValidation();

  return (
    <FormModal
      handleSubmit={onSubmit}
      initialValues={{
        allDay: initialAppointment?.allDay ?? false,
        caseId: initialAppointment?.caseId ?? currentCase.id,
        description: initialAppointment?.description,
        end: initialAppointment?.endTime ?? null,
        linkedCalendarId: calendarId ?? initialAppointment?.linkedCalendarId,
        start: initialAppointment?.startTime ?? null,
        startTime: '',
        title: initialAppointment?.title,
      }}
      size="lg"
      title={
        initialAppointment?.id
          ? sentencize(t('editType', { type: t('appointment') }))
          : sentencize(t('addType', { type: t('appointment') }))
      }
      toggle={toggle}
      validationSchema={validationSchema}
    >
      <AddEditAppointmentFormBody showLinkedCase={showLinkedCase} />
    </FormModal>
  );
};

export default AddEditAppointmentModal;
