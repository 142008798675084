import { useMutation } from '@tanstack/react-query';

import { EntityType, Language } from '~/common/enums';
import { Document } from '~/common/types/document/document';

import createRestClient from '@/shared/api/restClient';

type DocumentType = Omit<Document, 'id'> & {
  id: string;
};

export type DocumentsResponse = DocumentType[];

type UploadBatchDocumentRequest = {
  entityId?: string;
  entityType?: EntityType;
  files: File[];
  fileIds?: string[];
  parentEntityId: string;
  parentEntityType: EntityType;
  storeUntil?: string;
  languages?: Language[];
};

const client = createRestClient();

const useUploadBatchDocuments = () => {
  const { mutate, mutateAsync, isLoading } = useMutation(
    async ({
      entityId,
      entityType,
      files,
      fileIds,
      parentEntityId,
      parentEntityType,
      storeUntil,
      languages = [Language.Dutch, Language.English, Language.French],
    }: UploadBatchDocumentRequest): Promise<DocumentsResponse> => {
      const data = new FormData();

      if (entityId && entityType) {
        data.set('entityId', entityId);
        data.set('entityType', entityType);
      }

      fileIds?.forEach((id) => {
        data.append('fileIds', id);
      });

      data.set('parentEntityId', parentEntityId);
      data.set('parentEntityType', parentEntityType);

      if (storeUntil) {
        data.set('storeUntil', storeUntil);
      }

      files.forEach((file) => {
        data.append('files', file);
      });

      languages.forEach((language) => {
        data.append('languages', language);
      });

      return client.postForm('/document', data);
    },
  );

  return {
    isUploadingDocuments: isLoading,
    uploadBatchDocuments: mutate,
    uploadBatchDocumentsAsync: mutateAsync,
  };
};

export default useUploadBatchDocuments;
