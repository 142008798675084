const CasePaymentType = {
  DamageToThirdParties: 'DamageToThirdParties',
  OwnDamage: 'OwnDamage',
  DamageCaseInvoicePayment: 'DamageCaseInvoicePayment',
  DamageCaseIndirectLoss: 'DamageCaseIndirectLoss',
  DamageCaseRecoveryExemption: 'DamageCaseRecoveryExemption',
  DamageCaseReimbursementVat: 'DamageCaseReimbursementVat',
  DamageCaseReimbursementOwner: 'DamageCaseReimbursementOwner',
  DamageCaseReimbursementSupplier: 'DamageCaseReimbursementSupplier',
  DamageCaseReimbursementCompany: 'DamageCaseReimbursementCompany',
  DamageCaseExemption: 'DamageCaseExemption',
  InsuranceCompanyIntervention: 'InsuranceCompanyIntervention',
  Varia: 'Varia',
};

export default CasePaymentType;
