import { useCallback, useContext, useMemo } from 'react';

import {
  CaseStatusType,
  CaseType,
  EntityType,
  MessageTemplateContext,
  MessageTemplateIdentifier,
} from '~/common/enums';
import {
  EmailFormValues,
  InitialEmailValues,
} from '@/containers/Mailbox/Components/ComposeEmail/types';
import { CaseContext } from '../CaseContext';
import { File as FileType } from '@/types/documents';
import { MessageTemplate } from '@/types/trpc/team';
import { Task } from '~/common/types/email/emailMessage';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { FormattedEmailData, mapRelation } from '@/shared/utils/Email/emailHelpers';
import { trpc } from '@/config/trpc';
import useComposeEmailForm from '@/containers/Mailbox/Components/ComposeEmail/hooks/useComposeEmailForm';
import useSendEmailFromCase from '@/mutations/email/useSendEmailFromCase';

import FormikCaseEntityOptions from '@/shared/components/2.0/formik/FormikCaseEntityOptions';
import SendEmailModal from '@/shared/components/2.0/modals/SendEmailModal/SendEmailModal';

type Predicate = MessageTemplate['predicates'][number];

type AdditionalFormValues = {
  caseStatus: {
    caseStatusId: string | null;
    status: CaseStatusType | null;
  } | null;
  remark: string | null;
  showOnClientPage: boolean;
  tasks: Task[];
  teamsWithAccess: string[] | null;
};

type CaseEmailFormValues = EmailFormValues & AdditionalFormValues;
type FormattedCaseEmailFormValues = FormattedEmailData & AdditionalFormValues;

const BASE_FILTER_MAP = {
  [CaseType.Case]: (template: MessageTemplate) => template.context === MessageTemplateContext.Case,
  [CaseType.Complaint]: (template: MessageTemplate) =>
    template.context === MessageTemplateContext.CaseComplaint,
  [CaseType.InsuranceClaim]: (template: MessageTemplate) =>
    template.context === MessageTemplateContext.CaseInsurance,
};

const useCaseTemplateFilters = () => {
  const { currentCase } = useContext(CaseContext);

  const filterForRepairCase = useCallback(
    (template: MessageTemplate) => {
      if (template.context !== MessageTemplateContext.CaseRepair) return false;
      if (!template.predicates.length) return true;

      const checkForCaseContract = (predicate: Predicate) => {
        if (
          predicate.predicateIdentifier !== MessageTemplateIdentifier.Contract ||
          !currentCase.contractDescription
        )
          return false;

        return predicate.predicateValues.includes(currentCase.contractDescription);
      };

      return template.predicates.some(checkForCaseContract);
    },
    [currentCase.contractDescription],
  );

  const filters = {
    ...BASE_FILTER_MAP,
    [CaseType.Repair]: filterForRepairCase,
  };

  return filters[currentCase.caseType];
};

type UseCaseEmailFormArgs = {
  initialEmail: InitialEmailValues;
  toggle: UseToggle;
};

const useCaseEmailForm = ({ initialEmail, toggle }: UseCaseEmailFormArgs) => {
  const { currentCase } = useContext(CaseContext);
  const { sendMessage } = useSendEmailFromCase();

  const onSubmit = useCallback(
    (values: FormattedCaseEmailFormValues) => {
      sendMessage(
        {
          ...values,
          caseId: currentCase.id,
        },
        { onSuccess: toggle.hide },
      );
    },
    [currentCase.id, sendMessage, toggle.hide],
  );

  const baseForm = useComposeEmailForm({ initialEmail, onSubmit });

  const initialValues: CaseEmailFormValues = useMemo(
    () => ({
      ...baseForm.initialValues,
      caseStatus: null,
      remark: null,
      showOnClientPage: false,
      tasks: [],
      teamsWithAccess: null,
    }),
    [baseForm.initialValues],
  );

  return {
    ...baseForm,
    initialValues,
  };
};

type Props = {
  buildingId?: string;
  initialFiles?: FileType[];
  initialSubject?: string | null;
  toggle: UseToggle;
};

const SendCaseEmailModal: React.FC<Props> = ({
  buildingId,
  initialFiles,
  initialSubject,
  toggle,
}) => {
  const { currentCase } = useContext(CaseContext);
  const { data: caseFiles } = trpc.case.files.useQuery(currentCase.id);

  const caseRelations = useMemo(
    () => currentCase.relations.flatMap(mapRelation),
    [currentCase.relations],
  );

  const filterTemplatesBy = useCaseTemplateFilters();

  const initialEmail = useMemo(
    () => ({
      attachments: initialFiles,
      subject: initialSubject ?? undefined,
    }),
    [initialFiles, initialSubject],
  );

  const formik = useCaseEmailForm({ initialEmail, toggle });

  return (
    toggle.value && (
      <SendEmailModal
        buildingId={buildingId}
        caseFiles={caseFiles}
        emailSuggestions={caseRelations}
        entityId={currentCase.id}
        entityType={EntityType.Case}
        formikConfig={formik}
        filterTemplatesBy={filterTemplatesBy}
        toggle={toggle}
      >
        <FormikCaseEntityOptions allowRemark />
      </SendEmailModal>
    )
  );
};

export default SendCaseEmailModal;
