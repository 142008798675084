import { FormikContextType, FormikValues, getIn, useFormikContext } from 'formik';
import { useCallback, useMemo } from 'react';

type FormikValuesProps<TValues extends FormikValues> = {
  [Key in keyof TValues]: {
    error: string | undefined;
    onBlur: () => void;
    onChange: React.Dispatch<TValues[Key]>;
    value: TValues[Key];
  };
};

type FormikUtils<TValues extends FormikValues> = {
  [Key in keyof TValues]: { set: React.Dispatch<TValues[Key]>; setTouched: () => void };
};

type UseFormikValuesRes<TValues extends FormikValues> = [
  FormikValuesProps<TValues>,
  FormikUtils<TValues>,
  FormikContextType<TValues>,
];

const useFormikValues = <TValues extends FormikValues>(): UseFormikValuesRes<TValues> => {
  const formikCtx = useFormikContext<TValues>();

  const {
    errors,
    initialValues,
    setFieldTouched,
    setFieldValue,
    touched,
    validateOnBlur,
    validateOnChange,
    values,
  } = formikCtx;

  const utils = useMemo(() => {
    const entries = Object.keys(initialValues).map((name) => [
      name,
      {
        set: (value: unknown, validate = true) => {
          if (validate) setFieldTouched(name);
          setFieldValue(name, value);
        },
        setTouched: (validate = true) => {
          if (!validate) return;

          setFieldTouched(name);
        },
      },
    ]);

    return Object.fromEntries(entries);
  }, [initialValues, setFieldTouched, setFieldValue]);

  const getFormikProps = useCallback(
    (name: string) => ({
      error: getIn(touched, name) ? getIn(errors, name) : undefined,
      onBlur: () => utils[name].setTouched(validateOnBlur),
      onChange: (value: unknown) => utils[name].set(value, validateOnChange),
      value: getIn(values, name),
    }),
    [errors, touched, utils, validateOnBlur, validateOnChange, values],
  );

  const formikProps = useMemo(() => {
    const entries = Object.keys(initialValues).map((name) => [
      name,
      getFormikProps(name.toString()),
    ]);

    return Object.fromEntries(entries);
  }, [getFormikProps, initialValues]);

  return [formikProps, utils, formikCtx];
};

export default useFormikValues;
