import { useEffect, useState } from 'react';

const readTxtFileContent = async (uri: string) => {
  const fileReader = new FileReader();

  const fileRes = await fetch(uri);
  const blob = await fileRes.blob();

  return new Promise<string>((resolve, reject) => {
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') resolve(fileReader.result);

      reject(new Error('Text file resulted in unexpected format'));
    };
    fileReader.onerror = () => reject(new Error('Failed to read text file'));
    fileReader.readAsText(blob);
  });
};

type Props = {
  className?: string;
  onError?: () => void;
  onLoad?: () => void;
  uri: string;
};

const TXTRenderer: React.FC<Props> = ({ className, onError, onLoad, uri }) => {
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    const getTxtData = async () => {
      try {
        const txt = await readTxtFileContent(uri);

        setContent(txt);
        onLoad?.();
      } catch (err) {
        onError?.();
      }
    };

    getTxtData();
  }, [onError, onLoad, uri]);

  return <span className={className}>{content}</span>;
};

export default TXTRenderer;
