import { useCallback, useMemo, useRef, useState } from 'react';

type AcceptedPrimitive = string | number | boolean | null | undefined;

type AcceptedObj = {
  [key: string | number]: AcceptedPrimitive | AcceptedObj | (AcceptedPrimitive | AcceptedObj)[];
};

type InitialValue<TState extends AcceptedObj> = TState | (() => TState);

type Resetter<TState extends AcceptedObj> = <TKey extends keyof TState>(key?: TKey) => void;

type Setter<TState extends AcceptedObj> = <TKey extends keyof TState>(
  key: TKey,
  action: React.SetStateAction<TState[TKey]>,
) => void;

const useObjState = <TState extends AcceptedObj>(initialState: InitialValue<TState>) => {
  const initialValues = typeof initialState === 'function' ? initialState() : initialState;

  const initialValuesRef = useRef(initialValues); // passing an object inline will change the reference very render

  const [values, setValues] = useState<TState>(initialValues);

  const set: Setter<TState> = useCallback(
    (key, action) =>
      setValues((prevValues) => ({
        ...prevValues,
        [key]: typeof action === 'function' ? action(prevValues[key]) : action,
      })),
    [],
  );

  const reset: Resetter<TState> = useCallback(
    (key) => {
      if (key) {
        set(key, initialValuesRef.current[key]);
      } else {
        setValues(initialValuesRef.current);
      }
    },
    [set],
  );

  const methods = useMemo(
    () => ({
      reset,
      set,
      setAll: setValues,
    }),
    [reset, set, setValues],
  );

  return [values, methods] as const;
};

export const useObjWithMethods = <TState extends AcceptedObj>(
  initialState: InitialValue<TState>,
) => {
  const [values, methods] = useObjState<TState>(initialState);

  const objWithMethods = useMemo(
    () => ({
      ...values,
      ...methods,
    }),
    [methods, values],
  );

  return objWithMethods;
};

export default useObjState;
