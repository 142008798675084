import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import {
  decapitalize,
  formatDate,
  formatUtcDate,
  getAllPagedData,
  getCreatedBy,
  getMomentObject,
  getMomentUtcObject,
  scrollTo,
} from '@/shared/utils/helpers';
import API from '../../../../shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import { CaseContext } from '../../CaseContext';
import CaseEntityPropTypes from '../../../../shared/prop-types/CaseEntityPropTypes';
import CaseStatusType from '../../../../shared/enums/CaseStatusType';
import { colorPrimary } from '../../../../shared/utils/palette';
import { DropDownIcon, HomeScoreCategoryIcon } from '../../../../shared/icons/Icons';
import signalRMessages from '../../../../redux/actions/signalRMessages';
import SpreadsheetStatus from '../../../../shared/enums/SpreadsheetStatus';
import SpreadsheetType from '../../../../shared/enums/SpreadsheetType';
import SubmitOrEvaluateSpreadsheetTitle from '../../../../shared/utils/cases/repair';
import useDayjs from '@/shared/hooks/useDayjs';

import AssignContractor from './Steps/AssignContractor/AssignContractor';
import IntakeCall from './Steps/IntakeCall/IntakeCall';
import KpcCard from '../../../../shared/components/2.0/layout/KpcCard';
import NpsScore from './Steps/NpsScore/NpsScore';
import OrderMaterialsEstimatedDeliveryDate from './Steps/OrderMaterialsEstimatedDeliveryDate/OrderMaterialsEstimatedDeliveryDate';
import ScheduleRepairStart from './Steps/ScheduleRepairStart/ScheduleRepairStart';
import SpecificationAppointmentOrSecurization from './Steps/SpecificationAppointmentOrSecurization/SpecificationAppointmentOrSecurization';
import SubmitOfferOrInvoice from './Steps/SubmitOfferOrInvoice/SubmitOfferOrInvoice';
import { trpc } from '@/config/trpc';

const StepIndicator = styled.span`
  background-color: ${colorPrimary};
  border-radius: 50%;
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-right: 5px;
`;

const Step = {
  AssignContractor: 'assignContractor',
  DeliverOrValidateInvoice: 'deliverOrValidateInvoice',
  IntakeCall: 'intakeCall',
  NpsScore: 'npsScore',
  OrderMaterialsEstimatedDeliveryTime: 'orderMaterialsEstimatedDeliveryTime',
  ScheduleOrExecuteWork: 'scheduleOrExecuteWork',
  SpecificationAppointmentOrSecurization: 'specificationAppointmentOrSecurization',
  SubmitOfferOrEvaluate: 'submitOfferOrEvaluate',
};

const propTypes = {
  intakeCalls: PropTypes.arrayOf(CaseEntityPropTypes).isRequired,
};

const RepairTimelineCard = ({ intakeCalls }) => {
  const { socket, currentTeam } = useContext(AppContext);
  const { currentCase, ownRelation } = useContext(CaseContext);
  const { getUtcDateObject } = useDayjs();

  const { t } = useTranslation();

  const [openAccordionStep, setOpenAccordionStep] = useState(null);
  const [userClickedAccordionStep, setUseClickedAccordionStep] = useState(null);

  const [spreadsheets, setSpreadsheets] = useState([]);

  const readonly = [CaseStatusType.Pending, CaseStatusType.Cancelled].includes(
    currentCase?.status?.statusType,
  );

  const { data: contract } = trpc.company.contract.byId.useQuery(currentCase.contractId, {
    enabled: !!currentCase.contractId && currentTeam.allowRepairManagement,
  });

  const repairTimeLineStepsDisabled = useMemo(() => {
    if (readonly) return true;

    if (contract?.repairTimelineAvailableForCaseOwner && currentTeam.id === ownRelation.teamId) {
      return false;
    }

    return !currentCase.relations.some((r) => r.isContractor);
  }, [contract, currentCase.relations, currentTeam.id, ownRelation.teamId, readonly]);

  useEffect(() => {
    async function getFinanceData() {
      const spreadsheetsWithAccessResponse = await getAllPagedData(() =>
        API.fetchGetSpreadsheetsByCase(currentCase.id),
      );
      const spreadsheetSummaryResponse = await API.fetchGetRepairCaseFinanceSummary(currentCase.id);

      if (
        spreadsheetSummaryResponse.serviceError == null &&
        spreadsheetSummaryResponse.status === 200 &&
        spreadsheetsWithAccessResponse.serviceError == null &&
        spreadsheetsWithAccessResponse.status === 200
      ) {
        const allSpreadsheets = spreadsheetsWithAccessResponse.data.concat(
          spreadsheetSummaryResponse.data.filter(
            (s) => !spreadsheetsWithAccessResponse.data.some((w) => w.id === s.id),
          ),
        );
        setSpreadsheets(allSpreadsheets);
      }
    }

    getFinanceData();
  }, [currentCase.id]);

  const onSpreadsheetAddOrUpdate = useCallback(
    async (spreadsheetId, eventCaseId) => {
      if (currentCase.id !== eventCaseId) return;

      let response = await API.fetchGetSpreadsheet(spreadsheetId);

      if (response.status === 404) {
        response = await API.fetchGetRepairCaseFinanceSummary(currentCase.id, spreadsheetId);
      }

      if (response.serviceError == null && response.status === 200) {
        setSpreadsheets((prev) => {
          if (prev.some((s) => s.id === spreadsheetId)) {
            return prev.map((s) => (s.id === spreadsheetId ? response.data : s));
          }
          return [...prev, response.data];
        });
      }
    },
    [currentCase.id],
  );

  useEffect(() => {
    socket.on(signalRMessages.SpreadsheetAdded, onSpreadsheetAddOrUpdate);
    socket.on(signalRMessages.SpreadsheetUpdated, onSpreadsheetAddOrUpdate);

    return () => {
      socket.off(signalRMessages.SpreadsheetAdded);
      socket.off(signalRMessages.SpreadsheetUpdated);
    };
  }, [onSpreadsheetAddOrUpdate, socket]);

  const contractor = currentCase?.relations?.find((rel) => rel.isContractor);

  const initialOpenAccordionStep = useMemo(() => {
    if (intakeCalls.length === 0 && contractor == null) {
      return Step.IntakeCall;
    }

    if (!contractor) {
      return Step.AssignContractor;
    }

    if (currentCase.appointmentDate == null) {
      return Step.SpecificationAppointmentOrSecurization;
    }

    if (
      currentCase.appointmentForSpecifications != null &&
      currentCase.appointmentForSpecifications
    ) {
      return Step.SubmitOfferOrEvaluate;
    }

    if (
      currentCase.appointmentForSpecifications != null &&
      !currentCase.appointmentForSpecifications
    ) {
      return Step.ScheduleOrExecuteWork;
    }

    return null;
  }, [contractor, currentCase, intakeCalls.length]);

  useEffect(() => {
    if (openAccordionStep != null) {
      return;
    }

    setOpenAccordionStep(initialOpenAccordionStep);
  }, [initialOpenAccordionStep, openAccordionStep]);

  const getIntakeCallTitle = useCallback(
    ({ createdByUser, createdByTeam, callee, callDate }) => {
      const caller = getCreatedBy(createdByUser, createdByTeam);
      const formattedDate = getMomentUtcObject(callDate).local().format('DD/MM/YYYY HH:mm');
      return t('intakeCallBy', { callee, caller, date: formattedDate });
    },
    [t],
  );

  const intakeCallTitle = useMemo(() => {
    if (intakeCalls.length === 0) {
      return t('intakeCall');
    }
    if (intakeCalls.length === 1) {
      return getIntakeCallTitle(intakeCalls[0]);
    }
    const latest = intakeCalls.sort((a, b) => {
      const { callDate: currentDate } = a;
      const { callDate: nextDate } = b;
      return getMomentObject(nextDate).unix() - getMomentObject(currentDate).unix();
    })[0];
    return getIntakeCallTitle(latest);
  }, [getIntakeCallTitle, intakeCalls, t]);

  const getOrderMaterialsTitle = () => {
    const orderMaterialsStepCompleted =
      currentCase.mustOrderMaterials || currentCase.mustOrderMaterials === false;

    return orderMaterialsStepCompleted && currentCase.mustOrderMaterials
      ? t('orderMaterialsPending', {
          date: currentCase.materialsOrderDeliveryDate
            ? formatUtcDate(currentCase.materialsOrderDeliveryDate, 'DD/MM/YYYY')
            : '-',
        })
      : orderMaterialsStepCompleted
      ? t('orderMaterialsNotDone')
      : t('orderMaterialsEstimatedDeliveryTime');
  };

  const assignContractorTitle = useMemo(() => {
    if (contractor == null) {
      return t('assignContractor');
    }
    return t('contractorAssigned', {
      contractor: contractor.displayName,
      date: formatUtcDate(currentCase.contractorAssignedTimestampUTC, 'DD/MM/YYYY HH:mm'),
    });
  }, [contractor, currentCase.contractorAssignedTimestampUTC, t]);

  const specificationAppointmentOrSecurizationTitle = useMemo(() => {
    const { appointmentDate, appointmentTime, appointmentAmPm } = currentCase;

    if (appointmentDate == null) {
      return t('specificationAppointmentOrSecurization');
    }

    const date = formatDate(appointmentDate, 'DD/MM/YYYY');

    if (appointmentTime) {
      const time = getUtcDateObject(appointmentTime, 'HH:mm:ss').local().format('HH:mm');

      return t('firstAppointmentOnDateTime', { date, time });
    }

    return t('firstAppointmentOnDateInDayPart', {
      date,
      dayPart: decapitalize(appointmentAmPm ? t('afternoon') : t('morning')),
    });
  }, [currentCase, t]);

  const scheduleRepairStartTitle = () => {
    const { repairStartDate, repairStartTime, repairStartAmPm } = currentCase;

    if (repairStartDate == null) {
      return t('scheduleOrExecuteWork');
    }
    const date = formatUtcDate(repairStartDate, 'DD/MM/YYYY');

    if (repairStartTime) {
      const time = getUtcDateObject(repairStartTime, 'HH:mm:ss').local().format('HH:mm');

      return t('startOfWorksOnDateTime', { date, time });
    }
    return t('startOfWorksOnDateInDayPart', {
      date,
      dayPart: decapitalize(repairStartAmPm ? t('afternoon') : t('morning')),
    });
  };

  const submitOfferOrEvaluateTitle = useMemo(
    () => SubmitOrEvaluateSpreadsheetTitle(spreadsheets, t, SpreadsheetType.Estimate),
    [spreadsheets, t],
  );

  const submitInvoiceOrEvaluateTitle = useMemo(
    () => SubmitOrEvaluateSpreadsheetTitle(spreadsheets, t, SpreadsheetType.PurchaseInvoice),
    [spreadsheets, t],
  );

  const isExpanded = (stepToCheck) => {
    if (readonly) {
      return false;
    }
    switch (stepToCheck) {
      case Step.IntakeCall: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      case Step.AssignContractor: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      case Step.SpecificationAppointmentOrSecurization: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      case Step.SubmitOfferOrEvaluate: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      case Step.OrderMaterialsEstimatedDeliveryTime: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      case Step.ScheduleOrExecuteWork: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      case Step.DeliverOrValidateInvoice: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      case Step.NpsScore: {
        return (
          userClickedAccordionStep === stepToCheck ||
          (openAccordionStep === stepToCheck && (ownRelation.isOwner || ownRelation.isContractor))
        );
      }
      default:
        return false;
    }
  };

  const stepToDo = useMemo(() => {
    if (intakeCalls.length === 0 && !currentCase.relations.some((r) => r.isContractor)) {
      return Step.IntakeCall;
    }
    if (!currentCase.relations.some((r) => r.isContractor)) {
      return Step.AssignContractor;
    }
    if (!currentCase.appointmentDate) {
      return Step.SpecificationAppointmentOrSecurization;
    }
    if (
      !currentCase.executeOnTimeAndExpenseBasis &&
      !spreadsheets.some((s) => s.isEstimate && s.status === SpreadsheetStatus.Accepted)
    ) {
      return Step.SubmitOfferOrEvaluate;
    }
    if (!currentCase.executeOnTimeAndExpenseBasis && currentCase.mustOrderMaterials == null) {
      return Step.OrderMaterialsEstimatedDeliveryTime;
    }
    if (!currentCase.repairStartDate) {
      return Step.ScheduleOrExecuteWork;
    }
    return Step.DeliverOrValidateInvoice;
  }, [currentCase, intakeCalls, spreadsheets]);

  const changeOpenAccordion = (stepToOpen) => {
    scrollTo(stepToOpen);
    if (isExpanded(stepToOpen)) {
      setOpenAccordionStep('closed');
      setUseClickedAccordionStep('closed');
      return;
    }
    setOpenAccordionStep(stepToOpen);
    setUseClickedAccordionStep(stepToOpen);
  };

  return (
    <KpcCard id="caseRepairTimeline" title={t('repairTimeline')}>
      <Accordion
        id={Step.IntakeCall}
        expanded={isExpanded(Step.IntakeCall)}
        onChange={() => changeOpenAccordion(Step.IntakeCall)}
        disabled={readonly}
      >
        <AccordionSummary expandIcon={<DropDownIcon />}>
          <h4 className={classNames({ 'font-bold': openAccordionStep === Step.IntakeCall })}>
            {stepToDo === Step.IntakeCall && <StepIndicator />}
            {intakeCallTitle}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <IntakeCall
            intakeCalls={intakeCalls}
            skipStep={() => changeOpenAccordion(Step.AssignContractor)}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        id={Step.AssignContractor}
        expanded={isExpanded(Step.AssignContractor)}
        onChange={() => changeOpenAccordion(Step.AssignContractor)}
        disabled={readonly}
      >
        <AccordionSummary expandIcon={<DropDownIcon />}>
        <div className='flex w-full items-center'>
          <h4 className={classNames({ 'font-bold': openAccordionStep === Step.AssignContractor })}>
            {stepToDo === Step.AssignContractor && <StepIndicator />}
            {assignContractorTitle}
          </h4>
         {currentCase.contractorHomeScoreCategory && (<div><HomeScoreCategoryIcon className='h-7' category={currentCase.contractorHomeScoreCategory} /></div>)}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <AssignContractor
            nextStep={() => changeOpenAccordion(Step.SpecificationAppointmentOrSecurization)}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        id={Step.SpecificationAppointmentOrSecurization}
        disabled={repairTimeLineStepsDisabled}
        expanded={isExpanded(Step.SpecificationAppointmentOrSecurization)}
        onChange={() => changeOpenAccordion(Step.SpecificationAppointmentOrSecurization)}
      >
        <AccordionSummary expandIcon={<DropDownIcon />}>
          <h4
            className={classNames({
              'font-bold': openAccordionStep === Step.SpecificationAppointmentOrSecurization,
            })}
          >
            {stepToDo === Step.SpecificationAppointmentOrSecurization && <StepIndicator />}
            {specificationAppointmentOrSecurizationTitle}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <SpecificationAppointmentOrSecurization
            currentCase={currentCase}
            isCaseOwnerOrContractor={ownRelation.isOwner || ownRelation.isContractor}
            skipStep={() => changeOpenAccordion(Step.SubmitOfferOrEvaluate)}
            nextStep={(forSpecification) =>
              changeOpenAccordion(
                forSpecification ? Step.SubmitOfferOrEvaluate : Step.ScheduleOrExecuteWork,
              )
            }
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        id={Step.SubmitOfferOrEvaluate}
        expanded={isExpanded(Step.SubmitOfferOrEvaluate)}
        onChange={() => changeOpenAccordion(Step.SubmitOfferOrEvaluate)}
        disabled={repairTimeLineStepsDisabled}
      >
        <AccordionSummary expandIcon={<DropDownIcon />}>
          <h4
            className={classNames({
              'font-bold': openAccordionStep === Step.SubmitOfferOrEvaluate,
            })}
          >
            {stepToDo === Step.SubmitOfferOrEvaluate && <StepIndicator />}
            {submitOfferOrEvaluateTitle}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <SubmitOfferOrInvoice
            isContractor={ownRelation.isContractor}
            isCaseOwner={ownRelation.isOwner}
            worksiteRegistrationNbr={currentCase.worksiteRegistrationNbr}
            caseId={currentCase.id}
            spreadsheets={spreadsheets.filter((s) => s.isEstimate)}
            relations={currentCase.relations}
            photosRequired={currentCase.requireEstimatePhotos}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        id={Step.OrderMaterialsEstimatedDeliveryTime}
        expanded={isExpanded(Step.OrderMaterialsEstimatedDeliveryTime)}
        onChange={() => changeOpenAccordion(Step.OrderMaterialsEstimatedDeliveryTime)}
        disabled={repairTimeLineStepsDisabled}
      >
        <AccordionSummary expandIcon={<DropDownIcon />}>
          <h4
            className={classNames({
              'font-bold': openAccordionStep === Step.OrderMaterialsEstimatedDeliveryTime,
            })}
          >
            {stepToDo === Step.OrderMaterialsEstimatedDeliveryTime && <StepIndicator />}
            {getOrderMaterialsTitle()}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <OrderMaterialsEstimatedDeliveryDate
            currentCase={currentCase}
            isContractor={ownRelation.isContractor}
            isCaseOwner={ownRelation.isOwner}
            nextStep={() => changeOpenAccordion(Step.ScheduleOrExecuteWork)}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        id={Step.ScheduleOrExecuteWork}
        expanded={isExpanded(Step.ScheduleOrExecuteWork)}
        onChange={() => changeOpenAccordion(Step.ScheduleOrExecuteWork)}
        disabled={repairTimeLineStepsDisabled}
      >
        <AccordionSummary expandIcon={<DropDownIcon />}>
          <h4
            className={classNames({
              'font-bold': openAccordionStep === Step.ScheduleOrExecuteWork,
            })}
          >
            {stepToDo === Step.ScheduleOrExecuteWork && <StepIndicator />}
            {scheduleRepairStartTitle()}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <ScheduleRepairStart
            isContractor={ownRelation.isContractor}
            isCaseOwner={ownRelation.isOwner}
            worksiteRegistrationNbr={currentCase.worksiteRegistrationNbr}
            currentCase={currentCase}
            contractorCanEdit={
              ownRelation.isContractor && !spreadsheets.some((s) => s.isPurchaseInvoice)
            }
            nextStep={() => changeOpenAccordion(Step.DeliverOrValidateInvoice)}
          />
        </AccordionDetails>
      </Accordion>

      <Accordion
        id={Step.DeliverOrValidateInvoice}
        expanded={isExpanded(Step.DeliverOrValidateInvoice)}
        onChange={() => changeOpenAccordion(Step.DeliverOrValidateInvoice)}
        disabled={repairTimeLineStepsDisabled}
      >
        <AccordionSummary expandIcon={<DropDownIcon />}>
          <h4
            className={classNames({
              'font-bold': openAccordionStep === Step.DeliverOrValidateInvoice,
            })}
          >
            {stepToDo === Step.DeliverOrValidateInvoice && <StepIndicator />}
            {submitInvoiceOrEvaluateTitle}
          </h4>
        </AccordionSummary>
        <AccordionDetails>
          <SubmitOfferOrInvoice
            isContractor={ownRelation.isContractor}
            isCaseOwner={ownRelation.isOwner}
            caseId={currentCase.id}
            spreadsheets={spreadsheets.filter((s) => s.isPurchaseInvoice || s.isCreditNote)}
            relations={currentCase.relations}
            photosRequired={currentCase.requireInvoicePhotos}
            repairEndDate={currentCase.repairEndDate}
            repairStartDate={currentCase.repairStartDate}
            isSubmitInvoice
          />
        </AccordionDetails>
      </Accordion>
      {currentCase?.npsScore && (
        <Accordion
          id={Step.NpsScore}
          expanded={isExpanded(Step.NpsScore)}
          onChange={() => changeOpenAccordion(Step.NpsScore)}
          disabled={readonly}
        >
          <AccordionSummary expandIcon={<DropDownIcon />}>
            <h4 className={classNames({ 'font-bold': openAccordionStep === Step.NpsScore })}>
              {stepToDo === Step.NpsScore && <StepIndicator />}
              {t('npsScore')}
            </h4>
          </AccordionSummary>{' '}
          <AccordionDetails>
            <NpsScore npsScore={currentCase.npsScore} />
          </AccordionDetails>
        </Accordion>
      )}
    </KpcCard>
  );
};

export default RepairTimelineCard;

RepairTimelineCard.propTypes = propTypes;
