import { isBoardMemberRole, isBuildingUnitRole, SplitRoles } from './buildingRoleSelector.types';
import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';

import { BOARD_MEMBER_ROLES, BUILDING_UNIT_ROLES } from './buildingRoleSelector.constants';

export const convertRoleGroups = (roles: BuildingRoleSelectorOption[]) => {
  let newRoles = roles;

  const allBoardMemberRolesSelected =
    newRoles.includes(BuildingRoleSelectorOption.BoardOfCoOwnership) ||
    BOARD_MEMBER_ROLES.every((role) => newRoles.includes(role));

  const allRolesSelected =
    newRoles.includes(BuildingRoleSelectorOption.AllContactsAndCompanies) ||
    (allBoardMemberRolesSelected && BUILDING_UNIT_ROLES.every((role) => newRoles.includes(role)));

  if (allRolesSelected) {
    newRoles = [BuildingRoleSelectorOption.AllContactsAndCompanies];
  } else if (allBoardMemberRolesSelected) {
    const unitRoles = newRoles.filter(isBuildingUnitRole);

    newRoles = [...unitRoles, BuildingRoleSelectorOption.BoardOfCoOwnership];
  }

  return newRoles;
};

export const mergeRoles = (splitRoles: SplitRoles) => {
  const mergedRoles = [...splitRoles.boardMemberRoles, ...splitRoles.buildingUnitRoles];

  return convertRoleGroups(mergedRoles);
};

export const splitRoles = (toSplitRoles: BuildingRoleSelectorOption[]) => {
  if (toSplitRoles.includes(BuildingRoleSelectorOption.AllContactsAndCompanies))
    return {
      boardMemberRoles: [...BOARD_MEMBER_ROLES],
      buildingUnitRoles: [...BUILDING_UNIT_ROLES],
    };

  const roles = toSplitRoles.reduce<SplitRoles>(
    (acc, role) => {
      if (isBuildingUnitRole(role)) {
        acc.buildingUnitRoles.push(role);
      } else if (isBoardMemberRole(role)) {
        acc.boardMemberRoles.push(role);
      }

      return acc;
    },
    {
      boardMemberRoles: [],
      buildingUnitRoles: [],
    },
  );

  if (toSplitRoles.includes(BuildingRoleSelectorOption.BoardOfCoOwnership)) {
    roles.boardMemberRoles = [...BOARD_MEMBER_ROLES];
  }

  return roles;
};
