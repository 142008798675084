import { Divider, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const logo = '/img/server_down.png';
const vera = '/img/vera-name-logo.png';
const textColor = '#3f3d56';

const ServerDown = () => {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen flex-col items-center justify-center text-center">
      <div className="flex w-full justify-center">
        <img
          src={vera}
          className="mt-[-100px] w-1/6 min-w-[200px] max-w-[500px] object-contain"
          alt="vera-logo"
        />
      </div>
      <Typography level="h1" className="mb-4 font-bold text-[textColor]">
        {t('app.downPage.title')}
      </Typography>
      {[1, 2, 3].map((line) => (
        <Typography
          key={line}
          level="h4"
          component="p"
          sx={{ color: textColor, fontWeight: 'normal' }}
        >
          {t(`app.downPage.line${line}`)}
        </Typography>
      ))}
      <div className="flex justify-center">
        <img src={logo} className="w-1/3 object-contain" alt="vera-logo" />
      </div>
      <Divider className="mx-auto w-1/2 bg-black" />
      <Typography className="mt-4" sx={{ color: textColor }}>
        {t('app.downPage.footer')}{' '}
        <a className="text-[#3ea3fc] underline" href="mailto:helpdesk@keypoint.be">
          helpdesk@keypoint.be
        </a>
      </Typography>
    </div>
  );
};

export default ServerDown;
