import { Grid, IconButton } from '@mui/joy';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import API from '../../../../shared/api/ApiService';
import { AppContext } from '@/shared/context/context';
import { CaseContext } from '../../CaseContext';
import CaseType from '../../../../shared/enums/CaseType';
import { getAddressString } from '../../../../shared/utils/helpers';
import useGetCases from '../../../../queries/cases/useGetCases';
import useNotification from '../../../../shared/hooks/UseNotification';
import useToggle from '../../../../shared/hooks/UseToggle';
import useUpdateCaseBuildingRelation from '@/mutations/case/useUpdateCaseBuildingRelation';

import {
  AddressIconPretty,
  BuildingIconPretty,
  EmailIcon,
  InlineSpinner,
} from '../../../../shared/icons/Icons';
import CaseChipDisplay from '../../CaseChipDisplay';
import CaseRelatedCases from './CaseRelatedCases';
import DeleteAction from '../../../../shared/components/DeleteAction';
import EditCaseBuildingForm from './Forms/EditCaseBuildingForm';
import LinkBuildingButton from '../../../../shared/components/buttons/LinkBuildingButton';
import SendCaseEmailModal from '../../Email/SendCaseEmailModal';

const propTypes = {
  isEditing: PropTypes.bool,
  setIsEditing: PropTypes.func.isRequired,
};

const CaseBuildingCard = ({ setIsEditing, isEditing = false }) => {
  const { currentTeamUser, currentTeam } = useContext(AppContext);
  const { currentCase, canEditCase, refetchCase } = useContext(CaseContext);

  const { t } = useTranslation();
  const { sendNotification, sendDefaultError } = useNotification();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const sendEmailToggle = useToggle();

  const hasBuilding = !!currentCase.building?.buildingId;
  const hasAddress = !!currentCase.address?.addressLine;

  const { updateCaseBuildingRelation } = useUpdateCaseBuildingRelation({
    callback: () => refetchCase(),
  });

  const autocomplete = {
    buildings: hasBuilding ? [{ value: [currentCase.building.buildingId] }] : [],
  };

  const input = {
    addressLine: currentCase.address?.addressLine,
    city: currentCase.address?.city,
    country: currentCase.address?.country,
    postalCode: currentCase.address?.postalCode,
  };

  const {
    cases: relatedCases,
    casesIsLoading,
    loadMoreCases,
    hasMoreCases,
  } = useGetCases({
    enabled: hasBuilding || hasAddress,
    formatOptions: { currentTeam, currentTeamUser },
    params: hasBuilding ? { autocomplete } : { input },
  });

  useEffect(() => {
    if (!casesIsLoading && hasMoreCases) {
      loadMoreCases();
    }
  }, [hasMoreCases, loadMoreCases, casesIsLoading]);

  const sortedRelatedCases = useMemo(() => {
    if (casesIsLoading || hasMoreCases || !relatedCases.length || (!hasBuilding && !hasAddress)) {
      return [];
    }
    return relatedCases
      .filter((c) => c.id !== currentCase.id)
      .sort((a, b) => new Date(b.created) - new Date(a.created))
      .map((c) => <CaseChipDisplay caseEntity={c} />);
  }, [casesIsLoading, hasMoreCases, relatedCases, hasBuilding, hasAddress, currentCase.id]);

  const sendSuccessNotification = useCallback(() => {
    sendNotification({
      header: t('common:success'),
      message: t('common:typeSuccessfullyUpdated', { type: t('common:_case.title') }),
      variant: 'success',
    });
  }, [sendNotification, t]);

  const updateRelationBuildingId = useCallback(
    (buildingId) => {
      updateCaseBuildingRelation({
        buildingId,
        caseId: currentCase.id,
      });
    },
    [currentCase.id, updateCaseBuildingRelation],
  );

  const updateCaseAddress = async (data) => {
    if (isSubmitting) {
      return;
    }

    const {
      worksiteRegistrationNbr: updatedWorksiteRegistrationNbr,
      buildingId,
      ...addressData
    } = data;
    const apiData = {
      address: { ...addressData },
      buildingId,
      caseId: currentCase.id,
      worksiteRegistrationNbr: updatedWorksiteRegistrationNbr,
    };

    setIsSubmitting(true);
    const response = await API.putUpdateCaseAddress(apiData);
    setIsSubmitting(false);

    if (response.serviceError != null || response.status !== 200) {
      sendDefaultError(response);
      return;
    }

    sendSuccessNotification();
    refetchCase();
    setIsEditing(false);
  };

  const formattedAddress = useMemo(
    () =>
      getAddressString(
        currentCase.address?.addressLine || currentCase.building?.address?.addressLine,
        currentCase.address?.city || currentCase.building?.address?.city,
        currentCase.address?.postalCode || currentCase.building?.address?.postalCode,
        currentCase.address?.country || currentCase.building?.address?.country,
      ),
    [
      currentCase.address?.addressLine,
      currentCase.address?.city,
      currentCase.address?.postalCode,
      currentCase.address?.country,
      currentCase.building?.address?.addressLine,
      currentCase.building?.address?.city,
      currentCase.building?.address?.postalCode,
      currentCase.building?.address?.country,
    ],
  );

  return (
    <div>
      {isEditing && (
        <EditCaseBuildingForm
          onCancel={() => setIsEditing(false)}
          onSubmit={updateCaseAddress}
          address={currentCase.address}
          buildingId={currentCase.building?.buildingId}
          worksiteRegistrationNbr={currentCase.worksiteRegistrationNbr}
          caseType={currentCase.caseType}
        />
      )}
      {!isEditing && currentCase && (
        <>
          <div className="flex">
            <div className="mr-6 inline-block">
              {currentCase.building?.name ? <BuildingIconPretty /> : <AddressIconPretty />}
            </div>
            <Grid container columnSpacing={2} sx={{ flexGrow: 1 }} className="flex items-center">
              {currentCase.building?.name && (
                <>
                  <Grid xs={4} md={3}>
                    {t('common:name')}
                  </Grid>
                  <Grid xs={6} md={7} className="space-x-2">
                    <Link to={`/building/${currentCase.building.buildingId}`}>
                      {currentCase.building.name}
                    </Link>
                    {canEditCase && (
                      <span className="ml-2">
                        <DeleteAction
                          onDelete={() =>
                            updateCaseBuildingRelation({
                              buildingId: null,
                              caseId: currentCase.id,
                            })
                          }
                          isUnlink
                        />
                      </span>
                    )}
                    {canEditCase && (
                      <IconButton
                        variant="plain"
                        size="sm"
                        onClick={sendEmailToggle.show}
                        className="min-h-0 min-w-0"
                      >
                        <EmailIcon />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid xs={4} md={3}>
                    {t('vatNumber')}
                  </Grid>
                  <Grid xs={6} md={7}>
                    {currentCase.building?.vatNumber ?? '-'}
                  </Grid>
                </>
              )}

              <Grid xs={4} md={3}>
                {t('_address.label')}
              </Grid>
              <Grid xs={6} md={7}>
                <Link
                  to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    formattedAddress,
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formattedAddress}
                </Link>
                &nbsp;
                {!currentCase.building && (
                  <LinkBuildingButton
                    isEditable={canEditCase}
                    onBuildingSelection={(b) => updateRelationBuildingId(b.id)}
                  />
                )}
              </Grid>
              {currentCase.caseType === CaseType.Repair && (
                <>
                  <Grid xs={4} md={3}>
                    {t('common:worksiteRegistrationNbr')}
                  </Grid>
                  <Grid xs={6} md={7}>
                    {currentCase.worksiteRegistrationNbr ?? '-'}
                  </Grid>
                </>
              )}
              <Grid xs={4} md={3}>
                {t('common:otherCases')}
              </Grid>
              <Grid xs={6} md={7}>
                {casesIsLoading || hasMoreCases ? <InlineSpinner /> : sortedRelatedCases.length}
              </Grid>
            </Grid>
          </div>
          <CaseRelatedCases
            casesIsLoading={casesIsLoading}
            hasMoreCases={hasMoreCases}
            sortedRelatedCases={sortedRelatedCases}
          />
          <SendCaseEmailModal
            buildingId={currentCase.building?.buildingId}
            toggle={sendEmailToggle}
          />
        </>
      )}
    </div>
  );
};

CaseBuildingCard.propTypes = propTypes;

export default CaseBuildingCard;
