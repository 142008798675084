import { IconButton, Tooltip } from '@mui/joy';
import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { decapitalize, sentencize } from '../../../../shared/utils/helpers';
import {
  EditIcon,
  EmailIcon,
  HomeOwnerAssociationIcon,
  InsuranceEngineerIcon,
  InsuranceIntermediaryIcon,
  InsurerIcon,
  MobilePhoneIcon,
  PhoneIcon,
  PropertyManagerIcon,
  TeamIcon,
} from '../../../../shared/icons/Icons';
import { AppContext } from '@/shared/context/context';
import CaseRelationRole from '../../../../shared/enums/CaseRelationRole';
import DeleteAction from '../../../../shared/components/DeleteAction';
import EllipsisContainer from '../../../../shared/components/overflow/ellipsisContainer';
import KeypointTableDetailsButton from '../../../../shared/components/table/components/KeypointTableDetailsButton';
import PhoneNumberPropTypes from '../../../../shared/prop-types/PhoneNumberPropTypes';
import RelationType from '../../../../shared/enums/RelationType';
import useCompanyForRelationByCentralCompany from '../../../../shared/hooks/useCompanyForRelationByCentralCompany';

const propTypes = {
  canEdit: PropTypes.bool,
  canManageOwnTeam: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  disableDetailsButton: PropTypes.bool,
  isDeletable: PropTypes.bool,
  onDeleteCallback: PropTypes.func,
  onEditCallback: PropTypes.func,
  rowData: PropTypes.shape({
    address: PropTypes.shape({
      relationAddressAddressLine: PropTypes.string,
      relationAddressCity: PropTypes.string,
      relationAddressCountry: PropTypes.string,
      relationAddressPostalCode: PropTypes.string,
    }),
    companyId: PropTypes.string,
    contactId: PropTypes.string,
    emailAddresses: PropTypes.arrayOf(PropTypes.string),
    firstName: PropTypes.string,
    id: PropTypes.string,
    isClient: PropTypes.bool,
    isContractor: PropTypes.bool,
    isOwner: PropTypes.bool,
    isRequestor: PropTypes.bool,
    lastName: PropTypes.string,
    mobilePhoneNumber: PhoneNumberPropTypes,
    name: PropTypes.string,
    parentRelationId: PropTypes.string,
    phoneNumber: PhoneNumberPropTypes,
    relationReference: PropTypes.string,
    relationType: PropTypes.string,
    subordinate: PropTypes.string,
    teamId: PropTypes.string,
    teamName: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
};

const CaseRelationCustomCells = ({
  column,
  rowData,
  value = '',
  onEditCallback = () => {},
  onDeleteCallback = () => {},
  canEdit = false,
  isDeletable = false,
  canManageOwnTeam = false,
  disableDetailsButton = false,
  ...data
}) => {
  const {
    ui: { currentTeamId },
  } = useContext(AppContext);

  const { t } = useTranslation('common');
  const { existingCrmCompany } = useCompanyForRelationByCentralCompany(
    rowData,
    rowData.relationType === RelationType.Team && !rowData.companyId,
  );

  const getCaseRelationRoleIcon = (role) => {
    switch (role) {
      case CaseRelationRole.Insurer:
        return <InsurerIcon />;
      case CaseRelationRole.InsuranceEngineer:
        return <InsuranceEngineerIcon />;
      case CaseRelationRole.InsuranceIntermediary:
        return <InsuranceIntermediaryIcon />;
      case CaseRelationRole.HomeOwnerAssociation:
        return <HomeOwnerAssociationIcon />;
      case CaseRelationRole.PropertyManager:
        return <PropertyManagerIcon />;
      default:
        return null;
    }
  };
  const { key } = column;
  switch (key) {
    case 'type': {
      const { parentRelationId, subordinate } = rowData;
      if (
        (value == null || value.length === 0) &&
        parentRelationId == null &&
        subordinate == null
      ) {
        return null;
      }
      return (
        <>
          {(parentRelationId != null || subordinate != null) && (
            <FontAwesomeIcon icon={['far', 'arrow-turn-down-right']} />
          )}
          {value != null && value.length > 0 && (
            <Tooltip arrow title={t(`common:${decapitalize(value)}`)}>
              <span>{getCaseRelationRoleIcon(value)}</span>
            </Tooltip>
          )}
        </>
      );
    }
    case 'name': {
      const {
        isOwner,
        isRequestor,
        isClient,
        isContractor,
        relationReference,
        relationType,
        companyId,
        contactId,
        teamName,
        lastName,
        firstName,
      } = rowData;

      const fullName = lastName ? `${lastName} ${firstName}`.trim() : firstName;
      const displayName =
        relationReference != null && relationReference.length > 0
          ? `${fullName} (${relationReference})`
          : fullName;

      return (
        <>
          {isOwner && <Badge pill>{t('common:owner')}</Badge>}
          {isRequestor && <Badge pill>{t('common:requestor')}</Badge>}
          {isClient && <Badge pill>{t('common:client')}</Badge>}
          {isContractor && <Badge pill>{t('common:contractor')}</Badge>}
          {(isOwner || isRequestor || isClient || isContractor) && <br />}
          {teamName != null && (
            <Tooltip title={teamName}>
              <span>
                <TeamIcon /> &nbsp;
              </span>
            </Tooltip>
          )}
          <div className="truncate">
            <Tooltip title={displayName} arrow>
              <span>
                {(() => {
                  if (contactId) {
                    return <Link to={`/contact/${contactId}`}>{displayName}</Link>;
                  }
                  if (companyId) {
                    return <Link to={`/company/${companyId}`}>{displayName}</Link>;
                  }
                  if (relationType === RelationType.Team && existingCrmCompany) {
                    return <Link to={`/company/${existingCrmCompany.id}`}>{displayName}</Link>;
                  }
                  return displayName;
                })()}
              </span>
            </Tooltip>
          </div>
        </>
      );
    }
    case 'involvementType': {
      if (value == null || value.length === 0) {
        return '-';
      }
      return t(`common:${decapitalize(value)}`);
    }
    case 'contact': {
      const { mobilePhoneNumber, phoneNumber, emailAddresses } = rowData;
      if (!mobilePhoneNumber && !phoneNumber && (!emailAddresses || emailAddresses.length === 0)) {
        return '-';
      }

      const formattedPhoneNumber = `${phoneNumber?.countryCode}${phoneNumber?.number}`;
      const formattedMobilePhoneNumber = `${mobilePhoneNumber?.countryCode}${mobilePhoneNumber?.number}`;

      return (
        <>
          {emailAddresses != null &&
            emailAddresses.length > 0 &&
            emailAddresses.map((ea) => (
              <EllipsisContainer key={ea} title={ea} ellipsisColor="#3ea3fc">
                <p>
                  <i>
                    <EmailIcon color="accent" />{' '}
                  </i>
                  <a href={`mailto:${ea}`}>{ea}</a>
                </p>
              </EllipsisContainer>
            ))}
          {phoneNumber?.number && phoneNumber?.countryCode && (
            <EllipsisContainer title={formattedPhoneNumber} ellipsisColor="#3ea3fc">
              <p>
                <i>
                  <PhoneIcon color="accent" />{' '}
                </i>
                <a href={`tel:${formattedPhoneNumber}`}>{formattedPhoneNumber}</a>
              </p>
            </EllipsisContainer>
          )}
          {mobilePhoneNumber?.number && mobilePhoneNumber?.countryCode && (
            <EllipsisContainer title={formattedMobilePhoneNumber} ellipsisColor="#3ea3fc">
              <p>
                <i>
                  <MobilePhoneIcon color="accent" />{' '}
                </i>
                <a href={`tel:${formattedMobilePhoneNumber}`} className="ml-[3px]">
                  {formattedMobilePhoneNumber}
                </a>
              </p>
            </EllipsisContainer>
          )}
        </>
      );
    }
    case 'address': {
      const relationAddressAddressLine = rowData.address?.relationAddressAddressLine ?? '';
      const relationAddressCity = rowData.address?.relationAddressCity ?? '';
      const relationAddressPostalCode = rowData.address?.relationAddressPostalCode ?? '';
      const relationAddressCountry = rowData.address?.relationAddressCountry ?? '';
      if (!relationAddressAddressLine && !relationAddressCity) {
        return '-';
      }
      return (
        <div>
          <span>{relationAddressAddressLine || ''}</span>
          <br />
          <span>
            {relationAddressPostalCode || ''} {relationAddressCity || ''}
          </span>
          <br />
          <span>{relationAddressCountry || ''}</span>
        </div>
      );
    }
    case 'id': {
      const { teamId, isContractor, isOwner } = rowData;
      return (
        <div className="d-flex">
          {!disableDetailsButton && <KeypointTableDetailsButton {...data} />}
          <Tooltip title={t('edit')}>
            <IconButton variant="plain" disabled={!canEdit} onClick={() => onEditCallback(value)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          {(canManageOwnTeam || currentTeamId) !== teamId && !isContractor && !isOwner && (
            <DeleteAction
              onDelete={() => onDeleteCallback(value)}
              disabled={!isDeletable}
              tooltip={
                isDeletable
                  ? t('common:remove')
                  : sentencize(
                      t('common:cannotDeleteBecause', {
                        reason: t('errors:thereShouldBeAtLeastOneClient'),
                        what: t('involvedParty'),
                      }),
                    )
              }
            />
          )}
        </div>
      );
    }
    default:
      return value ?? '-';
  }
};

CaseRelationCustomCells.propTypes = propTypes;

export default CaseRelationCustomCells;
