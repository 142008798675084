import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { DivisionKey } from '~/common/types/homeownerAssociation';
import { homeownerAssociationApi } from '@/shared/api';

import { queryIds } from '@/shared/utils/constants';
import useNotification from '@/shared/hooks/UseNotification';

const useGetHomeownerAssociationDivisionKeys = <TData = DivisionKey>(
  homeownerAssociationId: string,
  options?: UseQueryOptions<DivisionKey[], unknown, TData[]>,
) => {
  const { sendDefaultError } = useNotification();

  const { data, isLoading } = useQuery<DivisionKey[], unknown, TData[]>({
    enabled: !!homeownerAssociationId,
    onError: (error) => {
      sendDefaultError(error);
    },
    queryFn: () =>
      homeownerAssociationApi.getHomeownerAssociationDivisionKeys(homeownerAssociationId),
    queryKey: [
      queryIds.homeownerAssociation.GET_HOMEOWNER_ASSOCIATION_DIVISION_KEYS,
      homeownerAssociationId,
    ],
    ...options,
  });

  return {
    areHomeownerAssociationDivisionKeysLoading: isLoading,
    homeownerAssociationDivisionKeys: data,
  };
};

export default useGetHomeownerAssociationDivisionKeys;
