import Button from '@mui/joy/Button';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DeleteIcon } from '../../icons/Icons';
import KpcModal from './layout/KpcModal';
import Row from './layout/Row';

const propTypes = {
  isDeleting: PropTypes.bool,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  toggle: PropTypes.shape({
    hide: PropTypes.func,
    value: PropTypes.bool,
  }).isRequired,
};

const DeleteModal = ({ toggle, onDelete, onCancel, isDeleting = false }) => {
  const { t } = useTranslation();

  const handleDelete = () => {
    onDelete();
    toggle.hide();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    toggle.hide();
  };

  return (
    <KpcModal
      className="w-1/2 overflow-y-scroll"
      icon={<DeleteIcon className="pr-2" />}
      title={t('modals.delete.title')}
      toggle={toggle}
      onClose={handleCancel}
    >
      <Row>{t('modals.delete.description')}</Row>
      <Row className="justify-end">
        <Button size="sm" variant="soft" color="neutral" onClick={handleCancel}>
          {t('buttons.cancel')}
        </Button>
        <Button
          disabled={isDeleting}
          size="sm"
          variant="solid"
          color="danger"
          onClick={handleDelete}
        >
          {t('buttons.delete')}
        </Button>
      </Row>
    </KpcModal>
  );
};

DeleteModal.propTypes = propTypes;

export default memo(DeleteModal);
