import { FormControl, FormLabel } from '@mui/joy';
import PropTypes from 'prop-types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CaseContext } from '@/containers/Cases/CaseContext';
import { sentencize } from '../../utils/helpers';

import CaseEntityOption from './CaseEntityOption';
import CaseStatusType from '../../enums/CaseStatusType';
import SelectCaseStatus from "./select/SelectCaseStatus";

const propTypes = {
  callback: PropTypes.func.isRequired,
};

const ChangeCaseStatusOption = ({ callback }) => {
  const { t } = useTranslation();
  const { currentCase, ownRelation } = useContext(CaseContext);

  const canEdit =
    ownRelation.isOwner ||
    (currentCase.status?.statusType === CaseStatusType.Pending && ownRelation.isRequestor);

  return (
    ((currentCase?.status?.statusType !== CaseStatusType.Cancelled && ownRelation.isOwner) ||
      (currentCase?.status?.statusType === CaseStatusType.Pending && ownRelation.isRequestor)) && (
      <CaseEntityOption title={sentencize(t('editType', { type: t('caseStatus') }))}>
        <FormControl className="mb-4">
          <FormLabel>{t('caseStatus')}</FormLabel>
          <SelectCaseStatus
            callback={callback}
            caseType={currentCase.caseType}
            selectedType={currentCase.status.statusType}
            selectedId={currentCase.status.id}
            hideUnavailable
            disabled={!canEdit}
            noColor
          />
        </FormControl>
      </CaseEntityOption>
    )
  );
};

ChangeCaseStatusOption.propTypes = propTypes;

export default ChangeCaseStatusOption;
