import { useMailboxContext } from '@/containers/Mailbox/store';

import { FileManagerFile, File as FileType, isFileManagerFile } from '@/types/documents';

const useGetAttachments = (initialAttachments?: (FileType | FileManagerFile)[]) => {
  const { state: mailbox } = useMailboxContext();

  if (mailbox.isForward || mailbox.isRetry) {
    const { replyEmail } = mailbox;

    if (!replyEmail) return [];

    return replyEmail.attachments
      .filter((attachment) => !attachment.isInline)
      .map((attachment) => ({
        file: attachment,
        id: attachment.id,
        isCaseFile: false,
        isEntityFile: false,
        isForwardAttachment: true,
        messageId: replyEmail.id,
      }));
  }
  if (!initialAttachments?.length) return [];

  return initialAttachments.map((attachment) => ({
    file: attachment,
    id: isFileManagerFile(attachment) ? attachment.fileId : attachment.id,
    isCaseFile: false,
    isEntityFile: attachment.isEntityFile ?? false,
    isForwardAttachment: false,
    messageId: null,
  }));
};

export default useGetAttachments;
