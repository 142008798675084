import { Autocomplete, AutocompleteOption, Button, ListItemContent } from '@mui/joy';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultColorPalette } from '@mui/joy/styles/types';
import { useTranslation } from 'react-i18next';

import KpcModal from '@/shared/components/2.0/layout/KpcModal';

import { CaseStatusType, CaseType, Language } from '~/common/enums';
import { CaseStatus } from '@/types/cases';
import { decapitalize } from '@/shared/utils/helpers';
import LanguageCode from '@/shared/enums/LanguageCode';
import { Nullable } from '~/common/types';
import { useGetCaseStatuses } from '@/queries';
import useToggle from '@/shared/hooks/UseToggle';

const STATUS_COLOR: {
  [key in CaseStatusType]: DefaultColorPalette;
} = {
  [CaseStatusType.Open]: 'success',
  [CaseStatusType.Closed]: 'danger',
  [CaseStatusType.Cancelled]: 'danger',
  [CaseStatusType.Pending]: 'warning',
};

type SelectCaseStatusProps = {
  askToFinishTasks?: boolean;
  callback?: (status: CaseStatus, finishTasks: boolean) => void;
  caseType?: Nullable<CaseType>;
  disabled?: boolean;
  hideUnavailable?: boolean;
  noColor?: boolean;
  selectedId?: Nullable<string>;
  selectedType?: Nullable<CaseStatusType>;
  typesToShow?: CaseStatusType[];
};

type CaseStatusOption = {
  active: boolean;
  description: string;
  id: string;
  isSystemDefault: boolean;
  translated: string;
  type: CaseStatusType;
  value: string;
};

const SelectCaseStatus: React.FC<SelectCaseStatusProps> = ({
  askToFinishTasks = false,
  callback = () => {},
  caseType = null,
  disabled = false,
  hideUnavailable = false,
  noColor = false,
  selectedId = null,
  selectedType = null,
  typesToShow = [CaseStatusType.Open, CaseStatusType.Closed, CaseStatusType.Cancelled],
}) => {
  const {
    i18n: { language },
    t,
  } = useTranslation();
  const { caseStatuses, caseStatusesIsLoading } = useGetCaseStatuses({
    caseType,
    typesToShow,
  });
  const [selectedValue, setSelectedValue] = useState<Nullable<CaseStatusOption>>(null);
  const finishTasksToggle = useToggle<CaseStatus>();

  const format = useCallback(
    (status: CaseStatus) => {
      const { id, statusType, translations, value, active, isSystemDefault } = status;

      let description;

      if (translations == null) {
        description = t(`common:${decapitalize(value)}`);
      } else {
        switch (language) {
          case LanguageCode.en:
            description = translations.find((tr) => tr.language === Language.English)?.description;
            break;
          case LanguageCode.fr:
            description = translations.find((tr) => tr.language === Language.French)?.description;
            break;
          case LanguageCode.nl:
            description = translations.find((tr) => tr.language === Language.Dutch)?.description;
            break;
          default:
            description = translations.find((tr) => tr.language === Language.English)?.description;
            break;
        }
      }
      const translatedType = t(`common:${decapitalize(statusType ?? '-')}`);
      return {
        active,
        description: description || translatedType,
        id: id ?? value,
        isSystemDefault,
        translated: translatedType,
        type: statusType,
        value,
      } as CaseStatusOption;
    },
    [language, t],
  );

  useEffect(() => {
    if (caseStatusesIsLoading) {
      return;
    }
    const valueToSelect = caseStatuses.find(
      (status) =>
        (status.id && status.id === selectedId) ||
        (status.statusType === selectedType && status.isSystemDefault),
    );
    if (valueToSelect) {
      setSelectedValue(format(valueToSelect));
    }
  }, [format, selectedId, selectedType, caseStatusesIsLoading, caseStatuses]);

  const options = useMemo(() => {
    if (caseStatusesIsLoading) {
      return [];
    }

    return caseStatuses
      .map(format)
      .filter((option) => !hideUnavailable || option.active || option.isSystemDefault)
      .sort((a, b) => a.type.localeCompare(b.type));
  }, [format, caseStatusesIsLoading, caseStatuses, hideUnavailable]);

  const handleCallback = useCallback(
    (selectedCaseStatus: CaseStatus, finishTasks: boolean) => {
      callback(selectedCaseStatus, finishTasks);
    },
    [callback],
  );

  const handleChange = useCallback(
    (_: React.SyntheticEvent, newValue: CaseStatusOption) => {
      setSelectedValue(newValue);
      const selectedCaseStatus = caseStatuses.find(
        (opt) =>
          opt.id === newValue?.id ||
          (opt.value === newValue?.value && opt.isSystemDefault && newValue.isSystemDefault),
      );
      if (!selectedCaseStatus) {
        // Should always have a value
        return;
      }
      if (
        askToFinishTasks &&
        [CaseStatusType.Closed, CaseStatusType.Cancelled].includes(selectedCaseStatus.statusType)
      ) {
        finishTasksToggle.show(selectedCaseStatus);
        return;
      }
      handleCallback(selectedCaseStatus, false);
    },
    [askToFinishTasks, handleCallback, caseStatuses, finishTasksToggle],
  );

  return (
    <>
      <Autocomplete
        loading={caseStatusesIsLoading}
        size="sm"
        color={
          !noColor && selectedValue?.type
            ? (STATUS_COLOR[selectedValue.type] as DefaultColorPalette)
            : 'neutral'
        }
        slotProps={{
          listbox: {
            color: 'neutral',
          },
        }}
        noOptionsText={t('common:noOptions')}
        disabled={disabled}
        /* @ts-ignore */
        value={selectedValue}
        onChange={handleChange}
        options={options}
        groupBy={(option) => option.translated}
        getOptionDisabled={(option) => !option.active && !option.isSystemDefault}
        getOptionLabel={(option) => option.description}
        isOptionEqualToValue={(option, value) =>
          (option.value === value.value && option.isSystemDefault && value.isSystemDefault) ||
          (option.id === value.id && !option.isSystemDefault && !value.isSystemDefault)
        }
        variant={noColor ? 'outlined' : 'soft'}
        disableClearable
        renderOption={(props, option) => (
          <AutocompleteOption {...props} className="bg-white" color="neutral" key={option.id}>
            <ListItemContent>{option.description}</ListItemContent>
          </AutocompleteOption>
        )}
      />
      {askToFinishTasks && (
        /* @ts-ignore */
        <KpcModal
          disablePortal
          title={t('doYouWishToAlsoFinishAllTheOngoingTasks')}
          toggle={finishTasksToggle}
          onClose={() => {
            finishTasksToggle.hide();
            handleCallback(finishTasksToggle.state, false);
          }}
        >
          <div className="modal__footer space-x-4 pt-4">
            <Button
              variant="soft"
              color="neutral"
              onClick={() => {
                finishTasksToggle.hide();
                handleCallback(finishTasksToggle.state, false);
              }}
            >
              {t('common:no')}
            </Button>
            <Button
              variant="solid"
              onClick={() => {
                if (finishTasksToggle.state) {
                  handleCallback(finishTasksToggle.state, true);
                }
                finishTasksToggle.hide();
              }}
            >
              {t('common:yes')}
            </Button>
          </div>
        </KpcModal>
      )}
    </>
  );
};

export default SelectCaseStatus;
