import { useEffect, useState } from 'react';

type UsePersistentStateParams<TState> = {
  initialValue?: TState;
  localStorageKey: string;
};

type Setter<TState> = React.Dispatch<React.SetStateAction<TState>>;

function usePersistentState<TState>(params: {
  initialValue: TState;
  localStorageKey: string;
}): [TState, Setter<TState>];

function usePersistentState<TState>(params: {
  initialValue?: undefined;
  localStorageKey: string;
}): [TState | null, Setter<TState | null>];

function usePersistentState<TState = string>({
  initialValue,
  localStorageKey,
}: UsePersistentStateParams<TState>) {
  const [value, setValue] = useState(() => {
    const storageString = window.localStorage.getItem(localStorageKey);

    if (!storageString) return initialValue ?? null;

    return JSON.parse(storageString) as TState;
  });

  useEffect(() => {
    window.localStorage.setItem(localStorageKey, JSON.stringify(value));
  }, [localStorageKey, value]);

  return [value, setValue] as const;
}

export default usePersistentState;
