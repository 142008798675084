import { InputLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import RichTextEditor from '../components/TinyMceRichTextEditor/RichTextEditor';

const FormikRichTextEditor = ({ name, height, label = '', required = false, ...rest }) => {
  const { values, touched, errors, setFieldTouched, setFieldValue } = useFormikContext();

  return (
    <>
      {!!label && (
        <InputLabel style={{ textAlign: 'left' }} error={touched[name] && errors[name]}>
          {label}
          {required && ' *'}
        </InputLabel>
      )}
      <RichTextEditor
        value={values[name]}
        callback={(text) => setFieldValue(name, text)}
        onBlur={() => setFieldTouched(name, true)}
        height={height ?? 200}
        {...rest}
      />
      {touched[name] && !!errors[name] && (
        <span className="validation-message">{errors[name]}</span>
      )}
    </>
  );
};

FormikRichTextEditor.propTypes = {
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

FormikRichTextEditor.defaultProps = {
  height: null,
  label: null,
  required: false,
};

export default FormikRichTextEditor;
