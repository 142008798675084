import { Box, ColorPaletteProp, DialogContent, DialogTitle } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { CircleExclamation } from '../icons/Icons';

type Props = {
  icon?: React.ReactNode;
  title?: string;
  color?: ColorPaletteProp;
};

const ConfirmationModalBody = ({
  children,
  title,
  color = 'primary',
  icon = <CircleExclamation />,
}: React.PropsWithChildren<Props>) => {
  const { t } = useTranslation();

  return (
    <div className="m-2">
      <div className="mb-4 flex items-center gap-4">
        <Box
          className="flex aspect-square w-10 items-center justify-center rounded-full"
          sx={(theme) => ({
            background: `rgba(${theme.palette[color].mainChannel} / 0.1)`,
            color: theme.palette[color][500],
          })}
        >
          <div className="*:aspect-square *:h-4">{icon}</div>
        </Box>
        <DialogTitle className="text-lg">{title || t('areYouSure')}</DialogTitle>
      </div>
      <DialogContent className="overflow-hidden text-base">
        {children || t('thisActionIsIrreversible')}
      </DialogContent>
    </div>
  );
};

export default ConfirmationModalBody;
