import {
  ActionOption,
  CustomOption,
  DefaultOption,
  DisplayType,
  EmailDndItem,
  Option,
  SelectableOption,
  SuggestionOption,
  TipOption,
} from './types';
import { RelationType } from '~/common/enums';
import { SenderOrReceiver } from '@/types/email';

export const isDndItem = (item: unknown): item is EmailDndItem => {
  const dndItem = item as EmailDndItem;

  return !!dndItem.contact && !!dndItem.source;
};

export const isOption = (item: unknown): item is Option => {
  const itemAsOption = item as Option;

  return !!itemAsOption.label && !!itemAsOption.displayType;
};

export const isAction = (item: unknown): item is ActionOption =>
  isOption(item) && item.displayType === DisplayType.Action;

export const isCustom = (item: unknown): item is CustomOption =>
  isOption(item) && item.displayType === DisplayType.Custom;

export const isDefault = (item: unknown): item is DefaultOption =>
  isOption(item) &&
  (item.displayType === DisplayType.Company || item.displayType === DisplayType.Contact);

export const isTip = (item: unknown): item is TipOption =>
  isOption(item) && item.displayType === DisplayType.Tip;

export const isSelectable = (item: unknown): item is SelectableOption =>
  isOption(item) && !isAction(item) && !isTip(item);

export const areSelectable = (items: unknown): items is SelectableOption[] =>
  Array.isArray(items) && (!items.length || isSelectable(items[0]));

export const isSuggestion = (item: unknown): item is SuggestionOption =>
  isOption(item) && item.displayType === DisplayType.Suggestion;

export const hasGroup = (option: Option): option is Exclude<SelectableOption, CustomOption> =>
  isSelectable(option) && option.displayType !== DisplayType.Custom;

export const hasRole = (option: SelectableOption, roles: string[]) =>
  !isSuggestion(option) ||
  !option.data.roles ||
  roles.some((role) => option.data.roles?.includes(role));

export const checkForOption = (options: Option[], value: string) =>
  options.filter(isSelectable).some((option) => option.value === value);

const OPTION_TO_CONTACT = {
  custom: (option: CustomOption) => ({
    email: option.value,
    name: option.label === option.value ? null : option.label,
  }),
  default: ({ data, value }: DefaultOption) => {
    const base = {
      companyId: null,
      contactId: null,
      email: value,
      name: data.name,
    };

    if (data.type === RelationType.Company)
      return {
        ...base,
        companyId: data.id,
        type: RelationType.Company as const,
      };

    return {
      ...base,
      contactId: data.id,
      type: RelationType.Contact as const,
    };
  },
  suggestion: (option: SuggestionOption) => option.data,
};

export const optionToContact = (option: SelectableOption) => {
  switch (option.displayType) {
    case DisplayType.Custom:
      return OPTION_TO_CONTACT.custom(option);
    case DisplayType.Suggestion:
      return OPTION_TO_CONTACT.suggestion(option);
    default:
      return OPTION_TO_CONTACT.default(option);
  }
};

export const optionsToContacts = (options: SelectableOption[]) => options.map(optionToContact);

type Contact = Pick<SenderOrReceiver, 'email' | 'name'>;

export const contactToOption = (contact: Contact) => ({
  displayType: DisplayType.Custom as const,
  label: contact.name ?? contact.email,
  value: contact.email,
});

export const emailAddressToOption = (email: string) => ({
  displayType: DisplayType.Custom as const,
  label: email.split('@')[0],
  value: email,
});

export const contactsToOptions = (contacts: Contact[]) => contacts.map(contactToOption);

export const suggestionToOption = (suggestion: SenderOrReceiver) => ({
  data: suggestion,
  displayType: DisplayType.Suggestion as const,
  label: suggestion.name ?? suggestion.email,
  value: suggestion.email,
});
