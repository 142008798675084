import {
  Autocomplete,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/joy';
import { getIn } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailAddressValue } from '~/common/types/email/emailValue';

import { decapitalize } from '@/shared/utils/helpers';
import EmailContext from '~/common/enums/emailContext';
import { getEnumWithNumberValues } from '~/common/utils/utils';

import { DeleteIcon } from '@/shared/icons/Icons';
import FieldArrayContainer from '@/shared/components/2.0/formik/FieldArrayContainer';
import FormikTextField from '@/shared/components/2.0/formik/FormikTextField';

type Props = {
  name?: string;
  inputOnBlur?: () => void;
  onRemove?: (remainingEmailAddresses: string[]) => void;
};

const EmailAddressInput: React.FC<Props> = ({ name, inputOnBlur, onRemove }) => {
  const { t } = useTranslation();

  const emailContextOptions = useMemo(() => getEnumWithNumberValues(EmailContext), []);

  return (
    <FieldArrayContainer
      title={t('emailAddresses')}
      name={name || 'emailAddresses'}
      onAdd={({ push }) => push({ email: '', emailContext: [EmailContext.All] })}
      renderInput={({ form, remove }) =>
        form.values?.emailAddresses?.map((emailAddress: EmailAddressValue, index: number) => (
          <>
            <Grid key={`email-${index}`} xs={4}>
              <FormikTextField
                name={`emailAddresses[${index}].email`}
                label={t('email_one')}
                onBlur={() => inputOnBlur?.()}
              />
            </Grid>
            <Grid key={`emailContext-${index}`} xs={6}>
              <FormControl>
                <FormLabel>{t('context')}</FormLabel>
                <Autocomplete
                  multiple
                  renderTags={(val, props) =>
                    val.map((value, autoIndex) => (
                      <Tooltip
                        key={value.value}
                        title={t(`_emailContext.${decapitalize(value.name)}`)}
                      >
                        <Chip
                          {...props}
                          className="max-w-14 truncate 2xl:max-w-32"
                          color="neutral"
                          variant="soft"
                          endDecorator={
                            <ChipDelete
                              onDelete={() => {
                                emailAddress.emailContext.splice(autoIndex, 1);
                                form.setFieldValue(
                                  `emailAddresses[${index}].emailContext`,
                                  emailAddress.emailContext,
                                );
                              }}
                            />
                          }
                        >
                          {t(`_emailContext.${decapitalize(value.name)}`)}
                        </Chip>
                      </Tooltip>
                    ))
                  }
                  getOptionLabel={(option) => t(`_emailContext.${decapitalize(option.name)}`)}
                  value={emailContextOptions.filter((option) =>
                    emailAddress.emailContext.includes(option.value),
                  )}
                  options={emailContextOptions ?? []}
                  onChange={(_, value) => {
                    const currentAllIndex = (
                      getIn(form.values, `emailAddresses[${index}].emailContext`) ?? []
                    ).indexOf(EmailContext.All);
                    const allIndex = value.indexOf(
                      value.find((emailContext) => emailContext.value === EmailContext.All) ?? {
                        name: '',
                        value: -1,
                      },
                    );

                    if (value.length > 1) {
                      if (currentAllIndex !== -1) {
                        value.splice(currentAllIndex, 1);
                      }
                      if (currentAllIndex === -1 && allIndex !== -1) {
                        form.setFieldValue(`emailAddresses[${index}].emailContext`, [
                          EmailContext.All,
                        ]);
                        return;
                      }
                    }

                    form.setFieldValue(
                      `emailAddresses[${index}].emailContext`,
                      value.map((option) => option.value),
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid key={`delete-${index}`} xs={2} marginTop={3}>
              <IconButton
                onClick={() => {
                  remove(index);
                  onRemove?.(
                    form.values.emailAddresses
                      .filter((e: EmailAddressValue) => e.email !== emailAddress.email)
                      .map((e: EmailAddressValue) => e.email) || [],
                  );
                }}
                className="w-full"
                color="danger"
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </>
        ))
      }
    />
  );
};

export default EmailAddressInput;
