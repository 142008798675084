import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';

export const BOARD_MEMBER_ROLES = [
  BuildingRoleSelectorOption.Accountant,
  BuildingRoleSelectorOption.ContactPerson,
  BuildingRoleSelectorOption.MemberOfRME,
  BuildingRoleSelectorOption.President,
  BuildingRoleSelectorOption.ViceChairman,
] as const;

export const BUILDING_UNIT_ROLES = [
  BuildingRoleSelectorOption.Owner,
  BuildingRoleSelectorOption.Tenant,
] as const;

export const SYNDIC_ROLES = [
  BuildingRoleSelectorOption.BoardOfCoOwnership,
  BuildingRoleSelectorOption.ContactPerson,
  BuildingRoleSelectorOption.President,
  BuildingRoleSelectorOption.ViceChairman,
] as const;
