import { TFunction } from 'i18next';

const isPhoneNumberValid = (
  t: TFunction,
  phoneNumber: string,
  countryCode: number,
  isMobile: boolean,
  allowBoth = false,
) => {
  const countryLengths = {
    // Add more country codes and lengths as needed
    298: { landline: [6], mobile: [6] }, // Faroe Islands
    30: { landline: [4, 5, 10], mobile: [4, 5, 10] }, // Greece
    31: { landline: [9], mobile: [9] }, // Netherlands
    32: { landline: [8], mobile: [9] }, // Belgium
    33: { landline: [10], mobile: [9] }, // France
    34: { landline: [9], mobile: [9] }, // Spain
    350: { landline: [8], mobile: [8] }, // Gibraltar
    351: { landline: [9], mobile: [9] }, // Portugal
    352: { landline: [4, 5, 6, 7, 8], mobile: [8, 9] }, // Luxembourg
    353: { landline: [7, 8, 9], mobile: [7, 8, 9] }, // Ireland
    354: { landline: [7], mobile: [7] }, // Iceland
    355: { landline: [8], mobile: [9] }, // Albania
    356: { landline: [8], mobile: [8] }, // Malta
    357: { landline: [8], mobile: [8] }, // Cyprus
    358: { landline: [9], mobile: [10] }, // Finland
    359: { landline: [8], mobile: [9] }, // Bulgaria
    36: { landline: [8], mobile: [9] }, // Hungary
    370: { landline: [8], mobile: [8] }, // Lithuania
    371: { landline: [8], mobile: [8] }, // Latvia
    372: { landline: [7], mobile: [7, 8] }, // Estonia
    373: { landline: [8], mobile: [8] }, // Moldova
    374: { landline: [8], mobile: [8] }, // Armenia
    375: { landline: [9], mobile: [9] }, // Belarus
    376: { landline: [6, 9], mobile: [6, 9] }, // Andorra
    377: { landline: [8, 9], mobile: [8, 9] }, // Monaco
    378: {
      hasMultiple: true,
      landline: [6, 7, 8, 9, 10, 11, 12],
      mobile: [6, 7, 8, 9, 10, 11, 12],
    }, // San Marino
    380: { landline: [9], mobile: [9] }, // Ukraine
    381: { landline: [8], mobile: [8] }, // Serbia
    382: { landline: [8], mobile: [8] }, // Montenegro
    383: { landline: [8], mobile: [8] }, // Kosovo
    385: { landline: [8], mobile: [8, 9] }, // Croatia
    386: { landline: [8], mobile: [8] }, // Slovenia
    387: { landline: [8, 9], mobile: [8, 9] }, // Bosnia
    389: { landline: [8], mobile: [8] }, // Macedonia
    39: { landline: [9], mobile: [10] }, // Italy
    40: { landline: [9], mobile: [9] }, // Romania
    41: { landline: [8], mobile: [9] }, // Switzerland
    420: { landline: [9], mobile: [9] }, // Czech Republic
    421: { landline: [8], mobile: [9] }, // Slovakia
    423: { landline: [7], mobile: [7] }, // Liechtenstein
    43: { landline: [12], mobile: [13] }, // Austria
    44: { landline: [9, 10], mobile: [7, 9, 10] }, // UK
    45: { landline: [8], mobile: [8] }, // Denmark
    46: { landline: [6, 7, 8, 9], mobile: [6, 7, 8, 9] }, // Sweden
    47: { landline: [8], mobile: [8] }, // Norway
    48: { landline: [9], mobile: [9] }, // Poland
    49: { landline: [10], mobile: [11] }, // Germany
    7: { landline: [10], mobile: [10] }, // Russia / Kazakhstan
    90: { landline: [10], mobile: [11] }, // Turkey
    998: { landline: [9], mobile: [9] }, // Georgia
  } as {
    [countryCode: number]: {
      landline: number[];
      mobile: number[];
    };
  };

  const actualLength = phoneNumber.replace(/\D/g, '').length; // Remove all non-digits from phone number
  const expectedLengths = countryLengths[countryCode as keyof typeof countryLengths];
  let lengths = (isMobile ? expectedLengths?.mobile : expectedLengths?.landline) ?? [9];
  if (allowBoth && !!expectedLengths) {
    lengths = [...expectedLengths.mobile, ...expectedLengths.landline];
  }

  const isValid = lengths.includes(actualLength);
  let errorMessage = '';
  if (!isValid) {
    errorMessage =
      new Set(lengths).size > 1
        ? t('errors:invalidPhoneNumberWithMultipleLengths', {
            max: Math.max(...lengths),
            min: Math.min(...lengths),
          })
        : t('errors:invalidPhoneNumberWithLength', {
            max: lengths[0],
          });
  }

  return {
    errorMessage,
    isValid: lengths.includes(actualLength),
  };
};

export default isPhoneNumberValid;
