import { Button, IconButton, Input, Tooltip } from '@mui/joy';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DisplayType,
  SelectableOption,
} from '@/shared/components/2.0/select/SelectEmailContact/types';
import { Attachment } from '@/shared/components/2.0/messageTemplates/types';
import { EditorHandle } from '@/shared/components/richTextEditor/types';
import { EmailFormValues } from './types';
import { SenderOrReceiver } from '@/types/email';
import { UseToggle } from '@/shared/hooks/UseToggle';

import {
  areSelectable,
  isCustom,
  isDefault,
  isSuggestion,
} from '@/shared/components/2.0/select/SelectEmailContact/utils';
import useAvailableHeight from '@/shared/hooks/useAvailableHeight';
import useFormikValues from '@/shared/hooks/useFormikValues';
import { useMailboxContext } from '../../store';

import { DeleteIcon, InlineSpinner, SendArrowIcon, ZipIcon } from '@/shared/icons/Icons';
import AttachFilesAction from '../AttachFilesAction';
import AttachmentsDropzone from '../AttachmentsDropzone';
import AttachmentsUploadView from '../AttachmentsUploadView';
import FormField from '@/shared/components/2.0/input/FormField';
import RichTextEditor from '../../../../shared/components/richTextEditor/RichTextEditor';
import SelectEmailContactDraggable from '@/shared/components/2.0/select/SelectEmailContact/SelectEmailContactDraggable';

const MIN_EDITOR_HEIGHT = 300;

type SubmitButtonSlots = {
  content?: React.ReactNode;
  decorator?: React.ReactNode;
};

export type ComposeEmailProps = React.PropsWithChildren<{
  allowFromSelection?: boolean;
  bccToggle: UseToggle;
  caseFiles?: Attachment[];
  editorHeightOffset?: number;
  emailSuggestions?: SenderOrReceiver[];
  isLoadingContent?: boolean;
  isLoadingEmailContacts?: boolean;
  submitButtonSlots?: SubmitButtonSlots;
}>;

const ComposeEmail: React.FC<ComposeEmailProps> = ({
  allowFromSelection = false,
  bccToggle,
  caseFiles,
  children,
  editorHeightOffset,
  emailSuggestions,
  isLoadingContent,
  isLoadingEmailContacts,
  submitButtonSlots,
}) => {
  const editorRef = useRef<EditorHandle>(null);

  const { clearCompose } = useMailboxContext();
  const { t } = useTranslation();

  const [formikProps, formikUtils, formikCtx] = useFormikValues<EmailFormValues>();

  const availableHeight = useAvailableHeight({
    bottomOffset: editorHeightOffset,
    minHeight: MIN_EDITOR_HEIGHT,
  });

  const handleDragAndDrop = useCallback(
    (id: string, from: string, to: string) => {
      const isField = (key: string): key is keyof typeof formikCtx.values =>
        key in formikCtx.values;

      if (!isField(from) || !isField(to)) return;

      const src = formikCtx.values[from];
      const dst = formikCtx.values[to];

      if (!areSelectable(src) || !src.length || !areSelectable(dst)) return;

      const compareRelationOption = (option: SelectableOption) =>
        isDefault(option) && option.data.id === id;

      const dragItemCompares = {
        [DisplayType.Company]: compareRelationOption,
        [DisplayType.Contact]: compareRelationOption,
        [DisplayType.Custom]: (option: SelectableOption) => isCustom(option) && option.value === id,
        [DisplayType.Suggestion]: (option: SelectableOption) =>
          isSuggestion(option) && option.value === id,
      };

      const compareToDragItem = (option: SelectableOption) =>
        dragItemCompares[option.displayType](option);

      const inDst = dst.some(compareToDragItem);

      if (inDst) return;

      const srcIndex = src.findIndex(compareToDragItem);

      if (srcIndex === -1) return;

      const draggedItem = src[srcIndex];

      const newSrc = src.toSpliced(srcIndex, 1);
      const newDst = [...dst, draggedItem];

      formikCtx.setFieldValue(from, newSrc);
      formikCtx.setFieldValue(to, newDst);
    },
    [formikCtx],
  );

  const beforeSubmit = () => {
    const editorContent = editorRef.current?.save() ?? '';

    formikUtils.body.set(editorContent);
  };

  const baseSelectEmailContactProps = {
    className: 'flex-grow',
    initialSuggestions: emailSuggestions,
    isLoading: isLoadingEmailContacts,
    multiple: true as const,
    onDrop: handleDragAndDrop,
  };

  return (
    <AttachmentsDropzone name="attachments">
      <div ref={availableHeight.topRef} className="flex flex-col">
        <div className="align-items-start mb-4 flex flex-row gap-4">
          <Button
            className="mr-4 h-[4.5rem]"
            disabled={formikCtx.isSubmitting}
            endDecorator={submitButtonSlots?.decorator ?? <SendArrowIcon />}
            startDecorator={formikCtx.isSubmitting && <InlineSpinner />}
            type="submit"
            onClick={beforeSubmit}
          >
            {submitButtonSlots?.content ?? t('send')}
          </Button>
          <div className="flex flex-grow flex-col gap-2">
            {allowFromSelection && (
              <SelectEmailContactDraggable
                {...baseSelectEmailContactProps}
                {...formikProps.selectedFrom}
                label={`${t('from')}:`}
                name="selectedFrom"
              />
            )}
            <SelectEmailContactDraggable
              {...baseSelectEmailContactProps}
              {...formikProps.selectedTo}
              label={`${t('to')}:`}
              name="selectedTo"
            />
            <SelectEmailContactDraggable
              {...baseSelectEmailContactProps}
              {...formikProps.selectedCc}
              endDecorator={
                <Button
                  size="sm"
                  variant="soft"
                  onClick={(e) => {
                    e.stopPropagation();
                    bccToggle.inverse();
                  }}
                >
                  BCC
                </Button>
              }
              label="CC:"
              name="selectedCc"
            />
            {bccToggle.value && (
              <SelectEmailContactDraggable
                {...baseSelectEmailContactProps}
                {...formikProps.selectedBcc}
                label="BCC:"
                name="selectedBcc"
              />
            )}
          </div>
          <div className="flex flex-col gap-2">
            <AttachFilesAction name="attachments" />
            <Tooltip title={t('discard')}>
              <IconButton
                color="danger"
                size="sm"
                onClick={() => {
                  clearCompose();
                  formikCtx.resetForm();
                }}
              >
                <DeleteIcon iconStyle="fas" />
              </IconButton>
            </Tooltip>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <FormField error={formikProps.subject.error}>
            <Input
              placeholder={t('addSubject')}
              size="sm"
              value={formikProps.subject.value ?? ''}
              onBlur={formikProps.subject.onBlur}
              onChange={(e) => formikProps.subject.onChange(e.target.value)}
            />
          </FormField>
          <Tooltip title={t('compressAttachments')}>
            <IconButton
              color={formikProps.compressFiles.value ? 'success' : 'danger'}
              size="sm"
              onClick={() => formikProps.compressFiles.onChange(!formikProps.compressFiles.value)}
            >
              <ZipIcon />
            </IconButton>
          </Tooltip>
        </div>
        <AttachmentsUploadView name="attachments" caseFiles={caseFiles ?? []} />
      </div>
      <RichTextEditor
        ref={editorRef}
        height={availableHeight.value}
        isLoading={isLoadingContent}
        value={formikProps.body.value ?? ''}
      />
      <div ref={availableHeight.bottomRef}>{children}</div>
    </AttachmentsDropzone>
  );
};

export default ComposeEmail;
