import {
  Autocomplete,
  AutocompleteOption,
  formLabelClasses,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import { autocompleteClasses } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { DisplayType, EmailSelectorProps, Option } from './types';

import {
  checkForOption,
  emailAddressToOption,
  hasGroup,
  isAction,
  isSelectable,
  suggestionToOption,
} from './utils';
import { isValidEmailAddress } from '@/shared/utils/helpers';
import { search as SEARCH } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import useDebounce from '@/shared/hooks/UseDebounce';

import { AddIcon, EditIcon, InlineSpinner } from '@/shared/icons/Icons';
import AvatarWithColor from '../../AvatarWithColor';
import FormField from '../../input/FormField';

const SEARCH_DELAY = 300;

const SelectEmailContact = forwardRef<HTMLInputElement, EmailSelectorProps>(
  (
    {
      className,
      error,
      initialSuggestions,
      isLoading = false,
      label,
      multiple,
      onChange,
      useEmailAsLabel = true,
      value,
      ...props
    },
    ref,
  ) => {
    const [autocompleteInputValue, setAutocompleteInputValue] = useState('');

    const { t } = useTranslation();

    const query = useDebounce(autocompleteInputValue, SEARCH_DELAY);

    const hasQuery = query.length >= SEARCH.START_SEARCH_QUERY_LENGTH;

    const { data: addressOptions, isLoading: isLoadingAddressOptions } =
      trpc.mailbox.searchAutoComplete.useQuery(query, {
        enabled: hasQuery,
      });

    const appendCustomEmail = useCallback(
      (email: string) => {
        if (!isValidEmailAddress(email)) return;

        const newOption = emailAddressToOption(email);

        if (multiple) {
          onChange([...value, newOption]);
        } else {
          onChange(newOption);
        }
      },
      [multiple, onChange, value],
    );

    const combinedOptions = useMemo(() => {
      let options: Option[] = [];

      const additionalOptions = (() => {
        if (!initialSuggestions) return [];

        if (multiple) {
          return initialSuggestions.map(suggestionToOption);
        }

        return [suggestionToOption(initialSuggestions)];
      })();

      const baseOptions =
        addressOptions?.map((option) => ({
          ...option,
          displayType: DisplayType[option.data.type] as const,
        })) ?? [];

      options = [...additionalOptions, ...baseOptions];

      if (query.length < SEARCH.START_SEARCH_QUERY_LENGTH) {
        options.push({
          displayType: DisplayType.Tip,
          label: t('startTypingToSearchNetwork'),
          startDecorator: <EditIcon />,
        });
      }

      if (!query) return options;

      if (!checkForOption(options, query) && isValidEmailAddress(query)) {
        options.push({
          action: () => appendCustomEmail(query),
          displayType: DisplayType.Action,
          label: t('useThisAddress', { address: query }),
          startDecorator: <AddIcon />,
        });
      }

      return options;
    }, [addressOptions, appendCustomEmail, initialSuggestions, multiple, query, t]);

    const handleChange = (newValue: Option | Option[] | null) => {
      if (!newValue) return;

      if (multiple && Array.isArray(newValue)) {
        const actions = newValue.filter(isAction);

        if (actions.length) {
          actions.forEach(({ action }) => action());

          return;
        }

        const selectableValues = newValue.filter(isSelectable);

        onChange(selectableValues);
      } else if (!multiple && !Array.isArray(newValue)) {
        if (isAction(newValue)) {
          newValue.action();

          return;
        }
        if (!isSelectable(newValue)) return;

        onChange(newValue);
      }
    };

    const handleKeyDown: React.KeyboardEventHandler = (e) => {
      if (e.code !== 'Enter') return;

      e.preventDefault();
      e.stopPropagation();

      if (checkForOption(combinedOptions, autocompleteInputValue)) return;

      appendCustomEmail(autocompleteInputValue);
    };

    const groupLabels = {
      [DisplayType.Company]: t('companies'),
      [DisplayType.Contact]: t('contacts'),
      [DisplayType.Suggestion]: t('suggestions'),
    };

    const isLoadingOptions = isLoading || (hasQuery && isLoadingAddressOptions);

    return (
      <FormField
        className={className}
        error={error}
        horizontal
        label={label}
        sx={{
          [`& .${autocompleteClasses.root}`]: { flex: 1 },
          [`& .${formLabelClasses.root}`]: { flexShrink: 0, width: '2rem' },
        }}
      >
        <Autocomplete
          clearOnBlur
          disableClearable={!!value}
          disabled={isLoading}
          forcePopupIcon={false}
          inputValue={autocompleteInputValue}
          loading={isLoadingOptions}
          multiple={multiple}
          noOptionsText={t('noOptions')}
          options={combinedOptions}
          size="sm"
          slotProps={{
            input: {
              ref,
            },
          }}
          startDecorator={isLoadingOptions && <InlineSpinner />}
          value={multiple ? value : value ?? null}
          filterOptions={(options, state) => {
            const matchOption = (option: Option) => {
              if (!isSelectable(option)) return true;

              const inputParts = state.inputValue.toLowerCase().trim().split(/\s+/);
              const optionString = `${option.label} ${option.value}`.toLowerCase();

              return inputParts.every((part) => optionString.includes(part));
            };

            return options.filter(matchOption);
          }}
          getOptionDisabled={(option) => option.displayType === DisplayType.Tip}
          getOptionLabel={
            useEmailAsLabel
              ? (option) => {
                  if (!isSelectable(option)) return option.label;

                  return option.value;
                }
              : undefined
          }
          groupBy={(option) => {
            if (!hasGroup(option)) return '';

            return groupLabels[option.displayType];
          }}
          isOptionEqualToValue={(a, b) => {
            if (!isSelectable(a) || !isSelectable(b)) return false;

            return a.value === b.value;
          }}
          onChange={(_, newValue) => handleChange(newValue)}
          onInputChange={(_, newValue) => setAutocompleteInputValue(newValue)}
          onKeyDown={handleKeyDown}
          renderOption={(optionProps, option) => (
            <AutocompleteOption {...optionProps} key={uuidv4()}>
              <ListItemDecorator>
                {'startDecorator' in option ? (
                  option.startDecorator
                ) : (
                  <AvatarWithColor name={option.label} size="sm" />
                )}
              </ListItemDecorator>
              <ListItemContent>
                {!isSelectable(option) ? (
                  option.label
                ) : (
                  <>
                    <Typography>{option.label}</Typography>
                    <Typography>{option.value}</Typography>
                  </>
                )}
              </ListItemContent>
            </AutocompleteOption>
          )}
          {...props}
        />
      </FormField>
    );
  },
);

export default SelectEmailContact;
