import { Grid, IconButton } from '@mui/joy';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { DeleteIcon } from '@/shared/icons/Icons';
import FieldArrayContainer from '@/shared/components/2.0/formik/FieldArrayContainer';
import FormHelperText from '@/shared/components/2.0/forms/FormHelperText';
import FormikCheckbox from '@/shared/components/2.0/formik/FormikCheckbox';
import FormikIbanInput from '@/shared/formik/FormikIbanInput';
import SelectBic from '@/shared/components/2.0/select/SelectBic';

import { BicValueKey } from '~/common/enums/bicValues';
import { IbanFormikValue } from '~/common/types/finance/iban';

const getEmptyIban = (asDefault: boolean) => ({
  accountNumber: null,
  bic: null,
  isDefault: asDefault,
  tempKey: uuid(),
});

const FormikIbansInput: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FieldArrayContainer
      validateOnChange
      name="ibans"
      title={t('ibans')}
      onAdd={({ form, push }) => {
        push(getEmptyIban(!(form.values?.ibans ?? []).find((i: IbanFormikValue) => i.isDefault)));
        form.validateForm();
      }}
      renderInput={({ form, remove }) => form.values?.ibans?.map((iban: IbanFormikValue, index: number) => (
        <Fragment key={iban.tempKey}>
          <Grid xs={4}>
            <FormikIbanInput
              name={`ibans[${index}].accountNumber`}
              label={t('accountNumber')}
              required
            />
          </Grid>
          <Grid xs={3}>
            <SelectBic
              label={t('bic')}
              value={iban.bic as BicValueKey | null}
              iban={iban.accountNumber}
              onChange={(value) => form.setFieldValue(`ibans[${index}].bic`, value)}
              required
            />
          </Grid>
          <Grid className="mt-8" xs={3}>
            <FormikCheckbox
              validateOnChange
              name={`ibans[${index}].isDefault`}
              label={t('isDefault')}
              callback={(value) => {
                if (!value) {
                  return;
                }
                // Set all other ibans to false if this one is true
                form.setFieldValue('ibans', form.values.ibans.map((i: IbanFormikValue, x: number) => ({
                  ...i,
                  isDefault: x === index ? value : false,
                })), true);
              }}
            />
          </Grid>
          <Grid xs={2} marginTop={3}>
            <IconButton onClick={() => remove(index)} className="w-full" color="danger">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Fragment>
      ))}
      renderFooter={({ form }) => form.errors.ibans && !Array.isArray(form.errors.ibans) && form.values?.ibans?.length > 0 && (
        <div className="mt-4 ml-1">
          <FormHelperText
            error={form.errors.ibans as string}
            touched
          />
        </div>
      )}
    />
  );
};

export default FormikIbansInput;
