import { useMemo } from 'react';

import { SenderOrReceiver } from '@/types/email';

import { trpc } from '@/config/trpc';
import { useMailboxContext } from '@/containers/Mailbox/store';

type ToCompareReceiver = Pick<SenderOrReceiver, 'email' | 'name'>;

const addressesMatch = (a: ToCompareReceiver, b: ToCompareReceiver) =>
  a.email === b.email && (!b.name || a.name === b.name);

const useGetReceivers = (initialTo?: SenderOrReceiver[]) => {
  const { state: mailbox } = useMailboxContext();

  const replyFrom = useMemo(
    () => mailbox.replyEmail?.from?.[0] ?? null,
    [mailbox.replyEmail?.from],
  );
  const replyTo = useMemo(() => {
    if (!mailbox.replyEmail) return [];

    return [...mailbox.replyEmail.to];
  }, [mailbox.replyEmail]);

  const shouldFetchAddresses = !mailbox.isForward && !mailbox.isRetry && !initialTo?.length;

  const { data: replyFromAddresses } = trpc.mail.searchAddresses.useQuery(
    { term: replyFrom?.email ?? '' },
    { enabled: shouldFetchAddresses && !!replyFrom },
  );
  const { data: replyToAddresses } = trpc.mail.searchAddressesByMultiple.useQuery(
    {
      terms: replyTo.map(({ email }) => email),
    },
    { enabled: shouldFetchAddresses && !!replyTo.length },
  );

  if (mailbox.isForward) return [];
  if (initialTo?.length) return initialTo;
  if (mailbox.isRetry) return replyTo;

  const fromAddress = (() => {
    if (!replyFrom || !replyFromAddresses?.length) return replyFrom;

    const matchingAddress = replyFromAddresses.find((address) =>
      addressesMatch(address, replyFrom),
    );

    return matchingAddress ?? replyFrom;
  })();

  let receivers = fromAddress ? [fromAddress] : [];

  if (!mailbox.isReplyAll) {
    return receivers;
  }

  const getToAddress = (to: ToCompareReceiver) =>
    replyToAddresses?.find((address) => addressesMatch(address, to)) ?? to;

  const toAddresses = replyTo.map(getToAddress);

  receivers = [...receivers, ...toAddresses];

  return receivers.filter(
    (receiver) =>
      receiver.email.toLowerCase() !== mailbox.emailAccount?.emailAddress?.toLowerCase(),
  );
};

export default useGetReceivers;
