import { Box, Grid, IconButton, Typography } from '@mui/joy';
import { FieldArrayConfig, FieldArrayRenderProps } from 'formik/dist/FieldArray';
import { FieldArray } from 'formik';
import React from 'react';

import { AddIcon } from '@/shared/icons/Icons';

type Props = Omit<FieldArrayConfig, 'render'> & {
  title: string;
  onAdd: (helpers: FieldArrayRenderProps) => void;
  renderInput: (props: FieldArrayRenderProps) => React.ReactNode;
  renderFooter?: (props: FieldArrayRenderProps) => React.ReactNode;
}

const FieldArrayContainer: React.FC<Props> = ({
onAdd,
renderInput,
renderFooter,
title,
...props
}) => (
  <Box
    sx={(theme) => ({
      borderColor: theme.palette.neutral.outlinedBorder,
      borderRadius: '0.5rem',
      borderWidth: 1,
      boxShadow: theme.shadow.xs,
      marginBottom: '1.75rem',
      marginTop: '1rem',
      paddingBottom: '0.75rem',
      paddingTop: '0.5rem',
      paddingX: '0.25rem',
      position: 'relative',
    })}
  >
    <FieldArray
      {...props}
      render={(helpers) => (
        <>
          <div className="flex items-center justify-between pl-2 pr-1">
            <Typography className="font-semibold" level="title-sm" textColor="text.secondary">
              {title}
            </Typography>
            <IconButton
              onClick={() => onAdd(helpers)}
              size="sm"
            >
              <AddIcon/>
            </IconButton>
          </div>
          <Grid
            mt={1}
            container
            spacing={1}
            className="max-h-[130px] overflow-y-auto px-2"
          >
            {renderInput(helpers)}
          </Grid>
          {renderFooter && renderFooter(helpers)}
        </>
      )}
    />
  </Box>
);

export default FieldArrayContainer;
