import { IconButton, Tooltip } from '@mui/joy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import { mailbox } from '@/shared/utils/constants';
import { sentencize } from '@/shared/utils/helpers';

type Props = {
  name: string;
};

const AttachFilesAction: React.FC<Props> = ({ name }) => {
  const { t } = useTranslation(['common', 'errors']);
  const { setFieldValue, values } = useFormikContext();
  const { enqueueSnackbar } = useSnackbar();

  const { getRootProps, getInputProps } = useDropzone({
    maxSize: mailbox.ATTACHMENT_MAX_SIZE,
    onDropAccepted: (files) =>
      setFieldValue(name, [
        // @ts-ignore
        ...values[name],
        ...files.map((f) => ({
          file: f,
          id: uuidv4(),
          isEntityFile: false,
          isForwardAttachment: false,
          isNylasAttachment: false,
        })),
      ]),
    onDropRejected: () => {
      enqueueSnackbar(t('errors:failedToAddAttachments'), { variant: 'error' });
    },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Tooltip arrow title={sentencize(t('addTypes', { type: t('attachments') }))}>
        <IconButton size="sm">
          <FontAwesomeIcon icon={['fas', 'paperclip']} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default AttachFilesAction;
