import { Dropdown, IconButton, Menu, MenuButton, MenuItem } from '@mui/joy';
import { useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Collapse } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { ChevronsDownIcon, DropDownIcon } from '@/shared/icons/Icons';
import { CosmosEmailAttachment } from '~/common/types/email/emailMessage';
import DocumentPreviewModal from '@/shared/components/documents/Documents/DocumentPreviewModal';
import { downloadUsingUri } from '@/shared/utils/fileHelpers';
import { FileContext } from '@/FileContext';
import { getFileTypeIconForContentType } from '@/shared/utils/helpers';
import { Nullable } from '~/common/types';
import useToggle from '@/shared/hooks/UseToggle';

type Props = {
  areAttachmentsCaseFiles?: boolean;
  attachments: CosmosEmailAttachment[];
};

const Attachments: React.FC<Props> = ({ attachments, areAttachmentsCaseFiles = false }) => {
  const { t } = useTranslation();

  const [selectedAttachment, setSelectedAttachment] =
    useState<Nullable<CosmosEmailAttachment>>(null);
  const { files: caseFiles } = useContext(FileContext);

  const attachmentCaseFileUrl = useMemo(() => {
    if (!areAttachmentsCaseFiles || !selectedAttachment) return null;

    const caseFileUrl = caseFiles.find((file) => file.id === selectedAttachment?.fileId)?.fileUri;

    return caseFileUrl;
  }, [areAttachmentsCaseFiles, selectedAttachment, caseFiles]);

  const fileUri = attachmentCaseFileUrl || selectedAttachment?.url;

  const isCollapsible = attachments.length > 10;
  const collapseToggle = useToggle(!isCollapsible);
  const previewToggle = useToggle();

  const onDownloadFile = () => {
    if (!fileUri || !selectedAttachment) return;

    downloadUsingUri(fileUri ?? '', selectedAttachment.fileName);
  };

  return (
    <>
      {isCollapsible && (
        <div className="flex justify-end">
          <button
            className="flex items-center space-x-2 text-xs text-primary"
            type="button"
            onClick={collapseToggle.inverse}
          >
            <ChevronsDownIcon
              className={classNames(
                'transition-all duration-150',
                collapseToggle.value && 'rotate-180',
              )}
            />
            <span>
              {collapseToggle.value
                ? t('hideType', { type: t('attachments').toLowerCase() })
                : t('showType', { type: t('attachments').toLowerCase() })}{' '}
              ({attachments.length})
            </span>
          </button>
        </div>
      )}
      <Collapse in={collapseToggle.value}>
        <div className="my-4 flex flex-wrap gap-2">
          {attachments.map((attachment) => (
            <div
              key={`file-${attachment.id}-${attachment.fileName}`}
              className="flex w-48 items-center justify-between rounded border border-slate-200 bg-slate-50 p-2 dark:border-zinc-600 dark:bg-zinc-700 "
            >
              <FontAwesomeIcon
                className="mr-2 text-xl"
                // @ts-ignore
                icon={['fad', getFileTypeIconForContentType(attachment.contentType)]}
              />
              <div className="ml-2 mr-2 flex flex-col overflow-hidden">
                <div className="truncate text-xs">{attachment.fileName}</div>
              </div>

              <Dropdown>
                <MenuButton
                  slots={{ root: IconButton }}
                  slotProps={{ root: { size: 'sm', variant: 'plain' } }}
                  onClick={() => {
                    setSelectedAttachment(attachment);
                  }}
                >
                  <DropDownIcon />
                </MenuButton>
                <Menu placement="bottom-end" className="z-popperModal">
                  <MenuItem onClick={previewToggle.show}>
                    <FontAwesomeIcon icon={['fas', 'file-magnifying-glass']} className="mr-3" />
                    {t('preview')}
                  </MenuItem>
                  <MenuItem onClick={onDownloadFile}>
                    <FontAwesomeIcon icon={['fas', 'arrow-down-to-line']} className="mr-3" />
                    {t('download')}
                  </MenuItem>
                </Menu>
              </Dropdown>
            </div>
          ))}
          {!!(fileUri && selectedAttachment) && (
            <DocumentPreviewModal
              toggle={previewToggle}
              file={{
                contentType: selectedAttachment.contentType,
                fileName: selectedAttachment.fileName,
                fileUri,
                id: selectedAttachment.id ?? '',
              }}
            />
          )}
        </div>
      </Collapse>
    </>
  );
};

export default Attachments;
