import { Button, Checkbox, Tooltip } from '@mui/joy';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  DisplayType,
  SelectableOption,
} from '@/shared/components/2.0/select/SelectEmailContact/types';

import { AppContext } from '@/shared/context/context';
import useToggle from '~/frontend/shared/hooks/UseToggle';

import { InfoIcon } from '@/shared/icons/Icons';
import SelectEmailContact from '@/shared/components/2.0/select/SelectEmailContact/SelectEmailContact';

type DataProps = {
  emailAddress: string;
};

type Options = {
  enabled: boolean;
  onSubmit: (data: DataProps) => void;
};

const InvolvedPartiesAddBuildingAsClient = ({ enabled, onSubmit }: Options) => {
  const { currentTeamUser } = useContext(AppContext);
  const { t } = useTranslation();

  const [selectedEmailContact, setSelectedEmailContact] = useState<SelectableOption | null>(() => {
    if (!currentTeamUser || !currentTeamUser.email) return null;

    return {
      displayType: DisplayType.Custom,
      label: currentTeamUser.name ?? currentTeamUser.email,
      value: currentTeamUser.email,
    };
  });

  const toggle = useToggle();

  const handleSubmit = () => {
    if (!selectedEmailContact) return;

    onSubmit({ emailAddress: selectedEmailContact.value });
  };

  return (
    <div className="flex h-12 items-center space-x-2 border-t-2">
      <Checkbox
        disabled={!enabled}
        label={t('addBuildingAsClient')}
        onChange={() => toggle.inverse()}
      />
      {toggle.value && (
        <>
          <SelectEmailContact value={selectedEmailContact} onChange={setSelectedEmailContact} />
          <Tooltip title={t('fillEmailaddressUsedForCommunication')} placement="top" arrow>
            <span className="mr-2 text-gray-500">
              <InfoIcon size="md" />
            </span>
          </Tooltip>
          <Button size="sm" variant="soft" onClick={handleSubmit}>
            {t('add')}
          </Button>
        </>
      )}
    </div>
  );
};

export default InvolvedPartiesAddBuildingAsClient;
