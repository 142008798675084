import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SenderOrReceiver } from '@/types/email';

import { trpc } from '@/config/trpc';
import { useMailboxContext } from '@/containers/Mailbox/store';

type MetadataItem = { label: string; value: string };

type ToFormatSenderOrReceiver = Pick<SenderOrReceiver, 'email' | 'name'>;

const formatSenderOrReceiver = (senderOrReceiver: ToFormatSenderOrReceiver) =>
  senderOrReceiver.name
    ? `${senderOrReceiver.name} &lt;${senderOrReceiver.email}&gt;`
    : senderOrReceiver.email;

const formatSenderOrReceivers = (senderOrReceivers: readonly ToFormatSenderOrReceiver[]) =>
  senderOrReceivers.map(formatSenderOrReceiver).join('; ');

const DATE_FORMAT = 'dddd, MMM Do, YYYY HH:mm';
const DIVIDER = '<hr style="width: 100%" />';

export const EMPTY_BODY = '<p></p><p></p>';

const useGetMetadata = () => {
  const { state: mailbox } = useMailboxContext();
  const { t } = useTranslation();

  if (!mailbox.replyEmail) return '';

  const wrapWithMarkup = (item: MetadataItem) => `<b>${item.label}:</b> ${item.value}<br>`;

  const metadata = (() => {
    const data = [];

    if (mailbox.replyEmail.from.length) {
      data.push({ label: t('from'), value: formatSenderOrReceiver(mailbox.replyEmail.from[0]) });
    }

    const date = dayjs.unix(mailbox.replyEmail.dateEpoch).format(DATE_FORMAT);

    data.push({ label: t('sent'), value: date });

    if (mailbox.replyEmail.to.length) {
      data.push({ label: t('to'), value: formatSenderOrReceivers(mailbox.replyEmail.to) });
    }

    if (mailbox.replyEmail.cc.length) {
      data.push({ label: 'CC', value: formatSenderOrReceivers(mailbox.replyEmail.cc) });
    }

    if (mailbox.replyEmail.subject) {
      data.push({ label: t('subject'), value: mailbox.replyEmail.subject });
    }

    return data;
  })();

  return `
      <font color="#000000" face="Calibri, sans-serif" style="font-size: 11pt;">
        ${metadata.map(wrapWithMarkup).join('')}
      </font>
      `;
};

const useComposeBody = (initialContent?: string | null) => {
  const { data: teamSettings } = trpc.team.user.teamSettings.useQuery();
  const { state: mailbox } = useMailboxContext();

  const metadata = useGetMetadata();

  const hasReplyWithInlineContent = useMemo(() => {
    if (!mailbox.replyEmail) return false;

    return mailbox.replyEmail.attachments.some((attachment) => attachment.isInline);
  }, [mailbox.replyEmail]);

  const shouldFetchInlineReply =
    !mailbox.isRetry && !!mailbox.emailAccount && hasReplyWithInlineContent;

  const { data: inlineReplyBody } = trpc.mailbox.messages.getBodyWithInlineImages.useQuery(
    {
      asBase64: true,
      emailAccountLinkId: mailbox.emailAccount?.linkId ?? '',
      messageId: mailbox.replyEmail?.id ?? '',
    },
    {
      enabled: shouldFetchInlineReply,
    },
  );

  if (mailbox.isRetry) return mailbox.replyEmail?.body ?? initialContent ?? EMPTY_BODY;
  if (!teamSettings || (!inlineReplyBody && shouldFetchInlineReply))
    return initialContent ?? EMPTY_BODY;

  const body = (() => {
    const replyBody = inlineReplyBody ?? mailbox.replyEmail?.body ?? '';
    const signature = teamSettings.emailSignature ?? '';

    const baseBody = (initialContent ?? EMPTY_BODY) + signature;

    if (!replyBody) return baseBody + metadata;

    return baseBody + DIVIDER + metadata + replyBody;
  })();

  return body;
};

export default useComposeBody;
