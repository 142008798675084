import { Box, Sheet, Skeleton } from '@mui/joy';
import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  HtmlEditor,
  Image,
  Inject,
  Link,
  QuickToolbar,
  Resize,
  RichTextEditorComponent,
  RichTextEditorModel,
  RichTextEditorTypecast,
  Toolbar,
} from '@syncfusion/ej2-react-richtexteditor';
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { useTranslation } from 'react-i18next';

import { EditorHandle } from './types';

import {
  CLASSNAMES,
  FONT_OPTIONS,
  IMAGE_SETTINGS,
  SPELL_CHECKER_TOOLBAR_ID,
  SPELLCHECK_DELAY_IN_MS,
  TOOLBAR_SETTINGS,
} from './constants';
import useSpellCheck, { Dictionary } from './hooks/useSpellCheck';
import { removeSpellCheckStylingFromHtml } from './utils';
import useSelection from './hooks/useSelection';

const observeSpellCheckerDropDown = (
  mutations: MutationRecord[],
  callback: (el: Element | null) => void,
) => {
  mutations.forEach((mutation) => {
    if (mutation.type !== 'childList') return;

    const popupEl = document.querySelector(`#${SPELL_CHECKER_TOOLBAR_ID}-popup`);

    if (!popupEl) return;

    callback(popupEl);
  });
};

type Props = RichTextEditorModel &
  Omit<DefaultHtmlAttributes, 'onBlur' | 'onChange'> &
  RichTextEditorTypecast & {
    onBlur?: () => void;
    onChange?: (content: string) => void;
    disableSpellCheck?: boolean;
    initialValue?: string;
    isLoading?: boolean;
  };

const RichTextEditor = forwardRef<EditorHandle, Props>(
  (
    {
      onBlur,
      onChange,
      disableSpellCheck = false,
      height = '100%',
      initialValue,
      isLoading,
      toolbarSettings = TOOLBAR_SETTINGS.DEFAULT,
      value,
      ...props
    },
    forwardedRef,
  ) => {
    const editorRef = useRef<RichTextEditorComponent>(null);
    const flagsRef = useRef({
      dropdownHasMounted: false,
      touched: false,
    });
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    const [initialValueCapture] = useState(initialValue ?? '');

    const selection = useSelection();
    const spellChecker = useSpellCheck();

    const { t } = useTranslation();

    const customToolbarSettings = useMemo(() => {
      const defaultItems = toolbarSettings.items ?? [];

      const customItems = (() => {
        if (disableSpellCheck) return [];

        return [
          {
            id: SPELL_CHECKER_TOOLBAR_ID,
            tooltipText: t('spellChecker.tooltip'),
          },
        ];
      })();

      return {
        ...toolbarSettings,
        items: [...defaultItems, ...customItems],
      };
    }, [disableSpellCheck, toolbarSettings, t]);

    const spellCheckerToolbarOptions = useMemo(() => {
      const dictionaryOptions = Object.entries(Dictionary).map(([code, dic]) => ({
        id: dic,
        text: code,
        value: dic,
      }));

      return [{ text: t('spellChecker.off') }, ...dictionaryOptions];
    }, [t]);

    const handleSpellCheck = useCallback(
      (html: string) => {
        if (!editorRef.current || spellChecker.isLoading) return;

        let newContent;

        if (disableSpellCheck || !spellChecker.currentDictionary) {
          if (!spellChecker.hasTouchedDictionary) return;

          newContent = removeSpellCheckStylingFromHtml(html);
        } else {
          newContent = spellChecker.checkHtml(html);
        }

        selection.save(editorRef.current.element);
        editorRef.current.updateValue(newContent);
        selection.restore(editorRef.current.element);
      },
      [disableSpellCheck, selection, spellChecker],
    );

    useImperativeHandle(forwardedRef, () => ({
      getEditor: () => editorRef.current,
      save: () => {
        const content = editorRef.current?.getHtml();

        if (!content) return '';

        return removeSpellCheckStylingFromHtml(content);
      },
    }));

    useEffect(() => {
      if (!value) return;

      flagsRef.current.touched = false;
    }, [value]);

    useEffect(() => {
      if (!editorRef.current) return;

      const content = flagsRef.current.touched ? editorRef.current.getHtml() : value ?? '';

      handleSpellCheck(content);
    }, [handleSpellCheck, value]);

    // use event listener, because syncfusion's change event only fires on focus loss or after a second or five of no typing
    useEffect(() => {
      const editor = editorRef.current;

      const handleChange = () => {
        if (!editorRef.current) return;

        flagsRef.current.touched = true;

        if (onChange) {
          const currentContent = editorRef.current.getHtml();

          onChange(removeSpellCheckStylingFromHtml(currentContent));
        }

        clearTimeout(timerRef.current);

        timerRef.current = setTimeout(() => {
          if (!editorRef.current) return;

          const currentContent = editorRef.current.getHtml();

          handleSpellCheck(currentContent);
        }, SPELLCHECK_DELAY_IN_MS);
      };

      editor?.element.addEventListener('input', handleChange);

      return () => {
        editor?.element.removeEventListener('input', handleChange);

        if (timerRef.current) {
          clearTimeout(timerRef.current);

          timerRef.current = undefined;
        }
      };
    }, [handleSpellCheck, onChange]);

    // Syncfusion EJ2 is just a monolithic JS library with a tin veneer whatever FE framework they're trying to cash in on
    // so you need bs code like this sometimes
    useEffect(() => {
      const spellCheckerToolbarEl = document.getElementById(SPELL_CHECKER_TOOLBAR_ID);

      let dropdown: DropDownButtonComponent | undefined;
      let observer: MutationObserver | undefined;

      const markActiveListitem = (popupEl: Element | null) => {
        const listEl = popupEl?.querySelector('ul');
        const activeEls = listEl?.querySelectorAll(`.${CLASSNAMES.SYNCFUSION_ACTIVE}`);

        activeEls?.forEach((el) => el.classList.remove(CLASSNAMES.SYNCFUSION_ACTIVE));

        if (spellChecker.currentDictionary) {
          const valueEl = listEl?.querySelector(`#${spellChecker.currentDictionary}`);

          valueEl?.classList.add(CLASSNAMES.SYNCFUSION_ACTIVE);
        } else {
          listEl?.children[0].classList.add(CLASSNAMES.SYNCFUSION_ACTIVE);
        }
      };

      if (!isLoading && !flagsRef.current.dropdownHasMounted && spellCheckerToolbarEl) {
        dropdown = new DropDownButtonComponent({
          iconCss: 'e-icons fal fa-globe',
          items: spellCheckerToolbarOptions,
          select: (args: { item: { properties: { id?: Dictionary } } }) =>
            spellChecker.setDictionary(args.item.properties.id ?? null),
        });
        observer = new MutationObserver((mutations) =>
          observeSpellCheckerDropDown(mutations, markActiveListitem),
        );

        dropdown.appendTo(spellCheckerToolbarEl);
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });

        flagsRef.current.dropdownHasMounted = true;
      }

      return () => {
        dropdown?.destroy();
        observer?.disconnect();

        flagsRef.current = { ...flagsRef.current, dropdownHasMounted: false };
      };
    }, [isLoading, spellChecker, spellCheckerToolbarOptions]);

    const showSkeleton = isLoading || spellChecker.isLoading;

    return (
      <Box height={height === '100%' ? '100%' : undefined} position="relative">
        {showSkeleton && (
          <Sheet
            sx={(theme) => ({
              height,
              left: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              zIndex: theme.vars.zIndex.tooltip,
            })}
          >
            <Skeleton height="100%" variant="rectangular" />
          </Sheet>
        )}
        <RichTextEditorComponent
          ref={editorRef}
          blur={onBlur}
          enableResize
          fontFamily={FONT_OPTIONS}
          height={height}
          htmlAttributes={{ spellcheck: false }} // disable gecko spellcheck etc
          insertImageSettings={IMAGE_SETTINGS}
          toolbarSettings={customToolbarSettings}
          value={value ?? initialValueCapture}
          {...props}
        >
          <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Resize]} />
        </RichTextEditorComponent>
      </Box>
    );
  },
);

export default memo(RichTextEditor);
