import { lowerFirst, sortBy } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Language, MessageTemplateContext, MessageTemplateType } from '~/common/enums';
import { MessageTemplate } from '@/types/trpc/team';

import { trpc } from '@/config/trpc';

type UseGetEmailTemplateOptionsInput = {
  context?: MessageTemplateContext;
  filterBy?: (template: MessageTemplate) => boolean;
};

const useGetEmailTemplateOptions = ({ context, filterBy }: UseGetEmailTemplateOptionsInput) => {
  const { data: messageTemplates, isLoading } = trpc.team.messageTemplate.list.useQuery(
    { context },
    { enabled: !!context || !!filterBy },
  );
  const { t } = useTranslation();

  const getLanguageCode = useCallback(
    (language: Language) => t(`_language.${lowerFirst(language)}.code`).toLocaleUpperCase(),
    [t],
  );

  const getTemplateDescription = useCallback(
    (template: MessageTemplate) => {
      if (!template.isAutomaticTemplate) return template.description ?? '';

      const stringifiedTriggers = template.triggers
        .map((trigger) => t(lowerFirst(trigger)))
        .join(', ');

      if (!template.receiver) return stringifiedTriggers;

      const receiverTranslation = t(lowerFirst(template.receiver));

      if (!stringifiedTriggers) return receiverTranslation;

      return `${receiverTranslation} - ${stringifiedTriggers}`;
    },
    [t],
  );

  const splitTemplateIntoOptionsByLanguage = useCallback(
    (template: MessageTemplate) => {
      const description = getTemplateDescription(template);

      return template.contents
        .filter((content) => content.type === MessageTemplateType.Email)
        .map((content) => ({
          data: {
            ...content,
            parent: template,
          },
          label: `${description} [${getLanguageCode(Language[content.language])}]`,
          value: content.id,
        }));
    },
    [getLanguageCode, getTemplateDescription],
  );

  const messageTemplateOptions = useMemo(() => {
    if (!messageTemplates) return [];

    const filtered = filterBy ? messageTemplates.filter(filterBy) : messageTemplates;
    const options = filtered.flatMap(splitTemplateIntoOptionsByLanguage);

    return sortBy(options, (option) => `${option.data.parent.isAutomaticTemplate}_${option.label}`);
  }, [filterBy, messageTemplates, splitTemplateIntoOptionsByLanguage]);

  return {
    data: messageTemplateOptions,
    isLoading,
  };
};

export default useGetEmailTemplateOptions;
