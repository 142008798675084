import { Alert, AlertTitle, IconButton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { decapitalize } from '../utils/helpers';
import { OpenIcon } from '../icons/Icons';

const useNotification = () => {
  const { t } = useTranslation('common');
  const [conf, sendNotification] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const handleOpen = useCallback(
    (key) => {
      if (conf.onSuccessRoute && conf.onSuccessRoute !== null) {
        navigate(conf.onSuccessRoute);
      }
      closeSnackbar(key);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [closeSnackbar, conf.onSuccessRoute],
  );

  const content = useMemo(
    (key) => (
      <Alert
        onClose={() => closeSnackbar(key)}
        key={key}
        variant="filled"
        severity={conf.variant}
        action={
          <>
            {conf.onSuccessRoute && conf.variant === 'success' && (
              <IconButton
                size="small"
                aria-label="Open"
                title="Open"
                color="inherit"
                onClick={() => handleOpen(key)}
              >
                <OpenIcon />
              </IconButton>
            )}
            <IconButton
              size="small"
              aria-label="Close"
              title="Close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
            >
              <FontAwesomeIcon icon={['fad', 'times-circle']} />
            </IconButton>
          </>
        }
      >
        <AlertTitle>{conf.header}</AlertTitle>
        {conf.message}
      </Alert>
    ),
    [conf, closeSnackbar, handleOpen],
  );

  useEffect(() => {
    if (conf?.message) {
      let variant = 'info';
      if (conf.variant) {
        variant = conf.variant;
      }
      enqueueSnackbar(conf.message, {
        anchorOrigin: {
          horizontal: 'left',
          vertical: 'bottom',
        },
        autoHideDuration: 3000,
        content,
        variant,
      });
    }
  }, [conf, enqueueSnackbar, content]);

  const getErrorMessageFromError = useCallback(
    (err) => {
      const error = err.response || err;
      const errorCode = error.data.httpStatus === 422 ? error.message : error.data?.errorCode;

      if ((error.status !== 422 && error.data.httpStatus !== 422) || !errorCode) {
        return t('errors:somethingWentWrongPleaseTryAgainLater');
      }

      return t(`errors:${decapitalize(errorCode)}`);
    },
    [t],
  );

  const sendDefaultError = useCallback(
    (error) => {
      const message = getErrorMessageFromError(error);
      sendNotification({
        header: t('errors:oops'),
        message,
        variant: 'error',
      });
    },
    [t, getErrorMessageFromError],
  );

  const sendSuccessMessage = useCallback(
    (message) =>
      sendNotification({
        header: t('success'),
        message,
        variant: 'success',
      }),
    [t],
  );

  return { sendDefaultError, sendNotification, sendSuccessMessage };
};

export default useNotification;
