import { TFunction } from 'i18next';

import { CaseInGroup, CaseInGroupRelation, DetailedCaseRelation } from '@/types/cases';
import { ExcessType, VatCode } from '~/common/enums';
import { Contract } from '~/common/types/contract/contract';
import FeeType from '@/shared/enums/FeeType';
import { isNullOrEmpty } from '@/shared/utils/helpers';
import { LineItem } from '@/containers/Cases/FinancialManagement/Components/LineItems';
import { Nullable } from '~/common/types';
import { Spreadsheet } from '@/types/finance';

type CalculateExcessFunction = (
  lineItems: LineItem[],
  recipient: Nullable<DetailedCaseRelation>,
  insurer: Nullable<DetailedCaseRelation>,
  excessAmount: number,
  excessType: Nullable<ExcessType>,
) => {
  skipExcess: boolean;
  excessAmountReached: boolean;
  excessAmountPaidByClient: number;
  excessAmountPaidByInsurer: number;
};

export const calculateExcess: CalculateExcessFunction = (
  lineItems,
  recipient,
  insurer,
  excessAmount,
  excessType,
) => {
  if (
    insurer == null ||
    recipient == null ||
    excessType == null ||
    excessType === ExcessType.None
  ) {
    return {
      excessAmountPaidByClient: 0,
      excessAmountPaidByInsurer: 0,
      excessAmountReached: false,
      skipExcess: true,
    };
  }
  // Excess should be applied when:
  // 1. The invoice recipient is not the insurer
  // 2. The total amount of all the lineItems (incl. VAT) where the insurer is recipient of >= excess
  // 2.1. When Legal Excess: the client pays the excess amount
  // 2.2. When English Excess: the excess amount expires
  let skipExcess = false;
  let excessAmountReached = false;
  let excessAmountPaidByClient = 0;
  let excessAmountPaidByInsurer = 0;
  const excessAmountNumber = +Number(excessAmount || 0);
  if (recipient.id === insurer.id) {
    skipExcess = true;
  }
  const sumOfAllInsurerLineItems = lineItems
    .filter((li) => !!li.receiver?.id && li.receiver.id === insurer.id)
    .map((li) => li.totalAmount || 0)
    .reduce((partialSum, amount) => partialSum + amount, 0);

  if (sumOfAllInsurerLineItems >= excessAmount) {
    excessAmountReached = true;
  }

  // We cannot check on same type here because we need to support the 'old' js enums as well
  // eslint-disable-next-line eqeqeq
  if (excessAmountReached && excessType == ExcessType.LegalExcess) {
    excessAmountPaidByClient = excessAmountNumber;
    excessAmountPaidByInsurer = -excessAmountNumber;
  }

  return {
    excessAmountPaidByClient,
    excessAmountPaidByInsurer,
    excessAmountReached,
    skipExcess,
  };
};

type CalculateVatForInsurerAndClientFunction = (
  lineItems: LineItem[],
  vatPaidByWorkProvider: boolean,
  vatRecoveryPercentage: number,
  insurer: Nullable<DetailedCaseRelation>,
  client: Nullable<DetailedCaseRelation>,
) => {
  vatForClient: number;
  vatForInsurer: number;
};

export const calculateVatForInsurerAndClient: CalculateVatForInsurerAndClientFunction = (
  lineItems,
  vatPaidByWorkProvider,
  vatRecoveryPercentage,
  insurer,
  client,
) => {
  const vatForInsurerDependingOnMandate = lineItems
    .filter((li) => !!li?.receiver?.id && !!insurer?.id && li.receiver.id === insurer.id)
    .map((li) => li.vatAmount || 0)
    .reduce((partialSum, amount) => partialSum + amount, 0);

  const vatToAddToClient = vatPaidByWorkProvider
    ? 0
    : vatForInsurerDependingOnMandate * (vatRecoveryPercentage / 100);
  const vatForInsurer = vatPaidByWorkProvider
    ? vatForInsurerDependingOnMandate
    : vatForInsurerDependingOnMandate - vatToAddToClient;

  const vatForClient = lineItems
    .filter((li) => !!li?.receiver?.id && !!client?.id && li.receiver.id === client.id)
    .map((li) => li.vatAmount || 0)
    .reduce((partialSum, amount) => partialSum + amount, 0);
  const totalVatForClient = vatForClient + vatToAddToClient;

  return {
    vatForClient: totalVatForClient,
    vatForInsurer,
  };
};

type CalculateInitialLineItemsFunction = (
  selectedPurchaseInvoices: Spreadsheet[],
  casesInGroup: CaseInGroup[],
) => LineItem[];

export const calculateInitialLineItems: CalculateInitialLineItemsFunction = (
  selectedPurchaseInvoices,
  casesInGroup,
) =>
  selectedPurchaseInvoices.flatMap((invoice) =>
    invoice.lineItems.map((li) => {
      const groupName = isNullOrEmpty(li.groupName)
        ? casesInGroup.find((c) => c.id === invoice.caseId)!.reference
        : `${li.groupName} - ${casesInGroup.find((c) => c.id === invoice.caseId)!.reference}`;

      return {
        ...li,
        groupName,
        isDelete: false,
        isFee: false,
        receiver: null,
        vat: VatCode.InlandExclusive21,
      };
    }),
  );

type CalculateFeeAmountFunction = (
  lineItems: LineItem[],
  selectedContract: Nullable<Contract>,
  alreadyPaidFee: number,
) => number;

export const calculateFeeAmount: CalculateFeeAmountFunction = (
  lineItems,
  selectedContract,
  alreadyPaidFee,
) => {
  const totalExclVat = lineItems
    .filter((li) => !li.isFee)
    .map(({ totalAmount }) => totalAmount || 0)
    .reduce((partialSum, amount) => partialSum + amount, 0);

  const currentTier = selectedContract?.tiers?.find(
    (tier) =>
      tier.lowerLimit <= totalExclVat &&
      (tier.upperLimit == null || tier.upperLimit > totalExclVat),
  );

  if (currentTier == null) {
    return 0;
  }

  const newFeeAmount =
    currentTier.feeType === FeeType.Fixed
      ? currentTier.fee
      : totalExclVat * (currentTier.fee / 100);

  const roundedFee = Math.round((newFeeAmount + Number.EPSILON) * 100) / 100;
  return roundedFee - alreadyPaidFee > 0 ? roundedFee - alreadyPaidFee : 0;
};

type RecalculateLineItemsWithFeeFunction = (
  lineItems: LineItem[],
  automaticFeeCalculation: boolean,
  alreadyPaidFee: number,
  fee: Nullable<{
    amount: Nullable<number>;
    description: Nullable<string>;
    receiver: Nullable<CaseInGroupRelation>;
    vatCode: Nullable<VatCode>;
  }>,
  repairMandateVatPercentage: Nullable<VatCode>,
  contract: Nullable<Contract>,
  fallbackToRepairMandateVat: boolean,
  t: TFunction,
) => {
  lineItems: LineItem[];
  feeAmount: number;
};

export const recalculateLineItemsWithFee: RecalculateLineItemsWithFeeFunction = (
  lineItems,
  automaticFeeCalculation,
  alreadyPaidFee,
  fee,
  repairMandateVatPercentage,
  contract,
  fallbackToRepairMandateVat,
  t,
) => {
  let currentLineItems = [...lineItems];
  const feeAmount =
    automaticFeeCalculation && !!contract
      ? calculateFeeAmount(currentLineItems, contract, alreadyPaidFee)
      : fee?.amount || 0;

  if (fee == null || feeAmount === 0) {
    currentLineItems = currentLineItems.filter((li) => !li.isFee);
  } else if (fee && !currentLineItems.find((li) => li.isFee)) {
    currentLineItems.push({
      description: fee.description || null,
      groupName: t('finance:forfait'),
      id: 'feeLine',
      isDelete: false,
      isFee: true,
      quantity: 1,
      receiver: fee.receiver || null,
      rowOrder: currentLineItems.length,
      subText: null,
      totalAmount: fee.amount || null,
      unit: t('finance:forfait'),
      unitPrice: fee.amount || null,
      vat: fee.vatCode || null,
      vatAmount: null,
    });
  } else {
    currentLineItems = currentLineItems.map((li) => {
      if (li.isFee) {
        return {
          ...li,
          description: fee?.description || null,
          receiver: fee?.receiver || null,
          totalAmount: fee?.amount || null,
          unitPrice: fee?.amount || null,
          vat: fee?.vatCode || null,
        };
      }
      return {
        ...li,
      };
    });
  }
  if (fallbackToRepairMandateVat) {
    currentLineItems = currentLineItems.map((li) => {
      if (li.isFee) {
        return { ...li };
      }
      return {
        ...li,
        vat: repairMandateVatPercentage || li.vat || null,
      };
    });
  }
  return {
    feeAmount,
    lineItems: [...currentLineItems],
  };
};

export const isStructuredReference = (reference: string | null) => {
  const IDENTIFIER = '+++';

  if (!reference) return false;

  return reference.startsWith(IDENTIFIER);
};

export const isStructuredReferenceValid: (reference: Nullable<string>) => boolean = (reference) => {
  if (!reference) {
    return false;
  }

  // Trim eventual pluses or stars at the beginning and end of the reference
  // Remove slashes from the string
  const trimmed = reference
    .trim()
    .replace(/^\+*|\+*$/g, '')
    .replace(/\//g, '');

  // Check if the reference has the correct length (12 to 20 digits)
  if (trimmed.length < 12 || trimmed.length > 20) {
    return false;
  }

  // Check if the reference only contains numbers
  if (!/^\d+$/.test(trimmed)) {
    return false;
  }

  // Get the control number from the reference
  const controlNumber = parseInt(trimmed.substring(trimmed.length - 2), 10);

  // Calculate the remainder of the preceding ten digits by Euclidean division by 97
  const previousNumbers = trimmed.substring(0, trimmed.length - 2);
  const rest = parseInt(previousNumbers, 10) % 97;

  // Check if the control number matches the calculated remainder
  // If the rest is 0, then the check digits are 97
  if (controlNumber !== (rest === 0 ? 97 : rest)) {
    return false;
  }

  return true;
};
