import { FormControl, FormHelperText, FormLabel } from '@mui/joy';
import classNames from 'classnames';
import { SxProps } from '@mui/joy/styles/types';
import { useTranslation } from 'react-i18next';

export type FieldProps = {
  className?: string;
  error?: string;
  horizontal?: boolean;
  label?: string;
  required?: boolean;
  size?: 'sm' | 'md' | 'lg';
  sx?: SxProps;
  translationKey?: string;
};

const FormField: React.FC<React.PropsWithChildren<FieldProps>> = ({
  children,
  className = '[.flex-row_&]:min-w-0 [.flex-row_&]:flex-1',
  error,
  horizontal,
  label,
  required = false,
  translationKey,
  ...formControlProps
}) => {
  const { t } = useTranslation();

  const fieldLabel = (() => {
    if (label) return label;

    return translationKey ? t(translationKey) : null;
  })();

  return (
    <FormControl
      className={classNames(className, { 'gap-1': horizontal })}
      error={!!error}
      orientation={horizontal ? 'horizontal' : 'vertical'}
      {...formControlProps}
    >
      {!!fieldLabel && <FormLabel required={required}>{fieldLabel}</FormLabel>}
      {children}
      {!!error && (
        <FormHelperText className={classNames('px-1 text-xs', { 'my-0': horizontal })}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormField;
