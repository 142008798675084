import { createContext } from 'react';

import { DivisionKey, FormattedGeneralLedgerAccount } from '~/common/types/homeownerAssociation';
import {
  FormattedBuildingUnit,
  SimpleBuilding,
  SimpleBuildingRelation,
} from '~/common/types/building';
import { components } from '~/common/types/kpc.d';
import { Nullable } from '~/common/types';

export type SimpleHomeownerAssociationResponse =
  components['schemas']['KeypointConnect.Api.Apis.V1.DTOs.HomeownerAssociations.Responses.SimpleHomeownerAssociationResponse'];

type HomeownerAssociationContext = {
  buildings: SimpleBuilding[];
  buildingsAreLoading: boolean;
  divisionKeys: DivisionKey[];
  divisionKeysAreLoading: boolean;
  glas: FormattedGeneralLedgerAccount[];
  glasAreLoading: boolean;
  homeownerAssociation?: SimpleHomeownerAssociationResponse;
  homeownerAssociationId: Nullable<string>;
  homeownerAssociationIsLoading: boolean;
  invalidateGlas: () => void;
  navigatedFromBuilding?: SimpleBuilding;
  navigatedFromBuildingIsLoading: boolean;
  relations: SimpleBuildingRelation[];
  relationsAreLoading: boolean;
  units: FormattedBuildingUnit[];
  unitsAreLoading: boolean;
};

const HomeownerAssociationContext = createContext<HomeownerAssociationContext>({
  buildings: [],
  buildingsAreLoading: true,
  divisionKeys: [],
  divisionKeysAreLoading: true,
  glas: [],
  glasAreLoading: true,
  homeownerAssociationId: null,
  homeownerAssociationIsLoading: true,
  invalidateGlas: () => {},
  navigatedFromBuildingIsLoading: true,
  relations: [],
  relationsAreLoading: true,
  units: [],
  unitsAreLoading: true,
});

export default HomeownerAssociationContext;
