import { useCallback, useMemo, useState } from 'react';
import { Button } from '@mui/joy';
import { Swiper } from 'swiper/types';
import { useTranslation } from 'react-i18next';

import { UseToggle } from '@/shared/hooks/UseToggle';

import { imageFileTypes, msDocFileTypes, pdfFileTypes, txtFileTypes } from '@/shared/utils/helpers';
import downloadFile from '@/shared/utils/fileHelpers';

import { CancelIcon, DownloadIcon } from '../../../icons/Icons';
import DocumentSkeleton from '../../2.0/skeletons/DocumentSkeleton';
import ImageRenderer from './Renderers/ImageRenderer';
import KpcModal from '../../2.0/layout/KpcModal';
import MSDocRenderer from './Renderers/MSDocRenderer';
import PDFRenderer from './Renderers/PDFRenderer';
import TXTRenderer from './Renderers/TXTRenderer';

export type FileProps = {
  contentType: string;
  fileUri: string;
};

const isImg = (file: FileProps) => imageFileTypes.includes(file.contentType);
const isMsDoc = (file: FileProps) => msDocFileTypes.includes(file.contentType);
const isPdf = (file: FileProps) => pdfFileTypes.includes(file.contentType);
const isTxt = (file: FileProps) => txtFileTypes.includes(file.contentType);

const isUnsupported = (file: FileProps) =>
  !isImg(file) && !isMsDoc(file) && !isPdf(file) && !isTxt(file);

type RendererProps<TToggleState> = {
  download: () => Promise<void>;
  file: FileProps;
  isLoading: boolean;
  onLoad: () => void;
  swiper?: Swiper;
  toggle: UseToggle<TToggleState>;
};

const Renderer = <TToggleState,>({
  download,
  file,
  isLoading,
  onLoad,
  swiper,
  toggle,
}: RendererProps<TToggleState>) => {
  const [hasError, setHasError] = useState(false);

  const { t } = useTranslation();

  const onError = useCallback(() => setHasError(true), []);

  const commonProps = useMemo(
    () => ({
      onError,
      onLoad,
      uri: file.fileUri,
    }),
    [file.fileUri, onError, onLoad],
  );

  if (isImg(file) && !hasError) {
    return <ImageRenderer {...commonProps} download={download} swiper={swiper} toggle={toggle} />;
  }

  return (
    <div className="mt-6 h-full">
      {hasError || isUnsupported(file) ? (
        <span>{t('unsupportedFileType')}</span>
      ) : (
        <div className="relative h-full">
          {isLoading && <DocumentSkeleton />}
          {isTxt(file) && <TXTRenderer {...commonProps} />}
          {isMsDoc(file) && <MSDocRenderer {...commonProps} />}
          {isPdf(file) && <PDFRenderer {...commonProps} />}
        </div>
      )}
    </div>
  );
};

type ModalProps<TToggleState> = Pick<RendererProps<TToggleState>, 'file' | 'swiper' | 'toggle'> & {
  onClose?: () => void;
  onDownload?: (file: FileProps) => Promise<void>;
};

const DocumentPreviewModal = <TToggleState,>({
  file,
  onDownload,
  onClose,
  toggle,
  swiper,
}: ModalProps<TToggleState>) => {
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  const download = async () => {
    if (onDownload) {
      await onDownload(file);
      return;
    }

    await downloadFile(file);
  };

  return (
    <KpcModal
      className="h-[90vh] w-1/2 gap-0"
      hideClose={isImg(file)}
      noDivider
      toggle={toggle}
      actions={
        <div className="flex justify-end gap-4">
          <Button
            startDecorator={<CancelIcon />}
            color="neutral"
            variant="outlined"
            onClick={toggle.hide}
          >
            {t('cancel')}
          </Button>
          <Button disabled={isLoading} startDecorator={<DownloadIcon />} onClick={() => download()}>
            {t('download')}
          </Button>
        </div>
      }
      onClose={onClose}
    >
      <Renderer
        download={download}
        file={file}
        isLoading={isLoading}
        onLoad={() => setIsLoading(false)}
        swiper={swiper}
        toggle={toggle}
      />
    </KpcModal>
  );
};

export default DocumentPreviewModal;
