import { Form, Formik, FormikConfig } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Autocomplete } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import {
  EmailFormValues,
  InitialEmailValues,
} from '@/containers/Mailbox/Components/ComposeEmail/types';
import { EntityType, MessageTemplateContext } from '~/common/enums';
import { BuildingOption } from '@/types/trpc/hoa';
import { FormattedEmailData } from '@/shared/utils/Email/emailHelpers';
import { MessageTemplate } from '@/types/trpc/team';

import useEmailContactsForRoles, { RolesData } from './hooks/useEmailContactsForRoles';
import useToggle, { UseToggle } from '@/shared/hooks/UseToggle';
import { DEFAULT_MODAL_OFFSET } from '@/shared/utils/constants';
import { trpc } from '@/config/trpc';
import { useAutocompleteOptions } from '@/shared/hooks/autocomplete';
import useComposeBody from '@/containers/Mailbox/Components/ComposeEmail/hooks/useComposeBody';
import useComposeEmailForm from '@/containers/Mailbox/Components/ComposeEmail/hooks/useComposeEmailForm';
import useFormikValues from '@/shared/hooks/useFormikValues';
import useGetEmailTemplateOptions from './hooks/useGetEmailTemplateOptions';
import useGetSubject from '@/containers/Mailbox/Components/ComposeEmail/hooks/useGetSubject';
import { useMailboxContext } from '@/containers/Mailbox/store';

import ComposeEmail, {
  ComposeEmailProps,
} from '@/containers/Mailbox/Components/ComposeEmail/ComposeEmail';
import BuildingRoleSelector from '@/shared/components/2.0/autocomplete/buildingRoleSelector';
import EmailAccountsAutocomplete from '@/containers/Mailbox/Components/EmailAccountsAutocomplete';
import FormField from '../../input/FormField';
import { InlineSpinner } from '@/shared/icons/Icons';
import KpcModal from '../../layout/KpcModal';

type InnerProps = Pick<ComposeEmailProps, 'caseFiles' | 'emailSuggestions'> &
  React.PropsWithChildren<{
    buildingId?: string | null;
    entityId?: string;
    entityType?: EntityType;
    filterTemplatesBy?: (template: MessageTemplate) => boolean;
    hoaId?: string | null;
    templateCtx?: MessageTemplateContext;
  }>;

const InnerForm: React.FC<InnerProps> = ({
  buildingId,
  entityId,
  entityType,
  filterTemplatesBy,
  hoaId,
  templateCtx,
  ...composeProps
}) => {
  const [roles, setRoles] = useState<RolesData>({
    selected: [],
    split: {
      boardMemberRoles: [],
      buildingUnitRoles: [],
    },
  });
  const [selectedHoaBuildingIds, setSelectedHoaBuildingIds] = useState<string[]>([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);

  const [formikProps, formikUtils] = useFormikValues<EmailFormValues>();

  const resolveTemplateEnabled = !!entityId && !!entityType && !!selectedTemplateId;

  const { data: resolvedTemplate, isLoading: isResolvingTemplate } =
    trpc.messaging.resolveTemplate.useQuery(
      {
        entityId: entityId ?? '',
        entityType: entityType ?? '',
        templateId: selectedTemplateId ?? '',
      },
      { enabled: resolveTemplateEnabled },
    );
  const { t } = useTranslation();

  const bccToggle = useToggle(!!formikProps.selectedBcc.value.length);
  const emailTemplateOptionsRes = useGetEmailTemplateOptions({
    context: templateCtx,
    filterBy: filterTemplatesBy,
  });
  const hoaBuildingOptionsRes = trpc.hoa.building.autocomplete.useQuery(
    { homeownerAssociationId: hoaId ?? '' },
    { enabled: !!hoaId },
  );
  const templateBody = useComposeBody(resolvedTemplate?.resolvedBody);
  const templateSubject = useGetSubject(resolvedTemplate?.resolvedSubject);

  const { isFetching: isFetchingBuildingEmailContacts, reset: resetBuildingEmailContacts } =
    useEmailContactsForRoles({
      bccToggle,
      buildingId,
      hoaBuildingIds: selectedHoaBuildingIds,
      hoaId,
      roles,
    });

  const handleHoaBuildingsChange = useCallback((options: BuildingOption[]) => {
    const ids = options.map((option) => option.value);

    setSelectedHoaBuildingIds(ids);
  }, []);

  const autocompleteProps = {
    building: useAutocompleteOptions({
      ...hoaBuildingOptionsRes,
      callback: handleHoaBuildingsChange,
      disableClearable: true,
      multiple: true,
      selected: selectedHoaBuildingIds,
    }),
    template: useAutocompleteOptions({
      ...emailTemplateOptionsRes,
      callback: (option) => setSelectedTemplateId(option?.value ?? null),
      enforceSingleOptionPrefill: false,
      selected: selectedTemplateId,
    }),
  };

  useEffect(() => formikUtils.body.set(templateBody), [formikUtils.body, templateBody]);

  useEffect(() => formikUtils.subject.set(templateSubject), [formikUtils.subject, templateSubject]);

  useEffect(() => {
    if (!hoaBuildingOptionsRes.data) return;

    handleHoaBuildingsChange(hoaBuildingOptionsRes.data);
  }, [handleHoaBuildingsChange, hoaBuildingOptionsRes.data]);

  return (
    <>
      <div className="mt-4 flex flex-row gap-4 px-4">
        {(!!templateCtx || !!filterTemplatesBy) && (
          <FormField label={t('selectType', { type: t('template') })}>
            <Autocomplete
              {...autocompleteProps.template}
              getOptionKey={() => uuidv4()}
              groupBy={(option) =>
                option.data.parent.isAutomaticTemplate
                  ? t('automaticEmailTemplates')
                  : t('manualEmailTemplates')
              }
              startDecorator={autocompleteProps.template.loading && <InlineSpinner />}
            />
          </FormField>
        )}
        {!!hoaId && (
          <FormField label={t('building_other')}>
            <Autocomplete
              {...autocompleteProps.building}
              startDecorator={autocompleteProps.building.loading && <InlineSpinner />}
            />
          </FormField>
        )}
        {(!!buildingId || !!hoaId) && (
          <BuildingRoleSelector
            value={roles.selected}
            onChange={(selectedRoles, splitRoles) => {
              setRoles({ selected: selectedRoles, split: splitRoles });

              if (!selectedRoles.length) resetBuildingEmailContacts();
            }}
          />
        )}
        <EmailAccountsAutocomplete clearMailboxState={false} showLabel />
      </div>
      <ComposeEmail
        bccToggle={bccToggle}
        editorHeightOffset={DEFAULT_MODAL_OFFSET.bottomOffset}
        isLoadingContent={resolveTemplateEnabled && isResolvingTemplate}
        isLoadingEmailContacts={isFetchingBuildingEmailContacts}
        {...composeProps}
      />
    </>
  );
};

type ModalBaseProps = InnerProps & {
  onClose?: () => void;
  toggle: UseToggle;
};

type ModalProps = Omit<ModalBaseProps, 'initialEmail'> &
  (
    | {
        formikConfig: FormikConfig<EmailFormValues>;
        onSubmit?: never;
        initialEmail?: never;
      }
    | {
        formikConfig?: never;
        onSubmit?: (values: FormattedEmailData) => void;
        initialEmail?: InitialEmailValues;
      }
  );

const SendEmailModal: React.FC<ModalProps> = ({
  formikConfig,
  initialEmail,
  onClose,
  onSubmit,
  toggle,
  ...innerProps
}) => {
  const { clearCompose } = useMailboxContext();
  const { t } = useTranslation();

  const defaultConfig = useComposeEmailForm({
    initialEmail,
    onSubmit,
    toggle,
  });

  const handleClose = () => {
    clearCompose();
    onClose?.();
  };

  const formikProps = formikConfig ?? defaultConfig;

  return (
    <KpcModal
      className="h-full w-2/3"
      noDivider
      title={t('sendEmail')}
      toggle={toggle}
      onClose={handleClose}
    >
      <Formik {...formikProps}>
        <Form className="flex flex-grow flex-col">
          <InnerForm {...innerProps} />
        </Form>
      </Formik>
    </KpcModal>
  );
};

export default SendEmailModal;
