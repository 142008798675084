import { Autocomplete, AutocompleteOption, ListItemContent, ListItemDecorator } from '@mui/joy';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { BuildingRoleSelectorOption } from '@/shared/enums/BuildingRoleSelectorOption';
import { SplitRoles } from './buildingRoleSelector.types';

import { convertRoleGroups, splitRoles } from './buildingRoleSelector.utils';
import useBuildingRoleSelectorOptions, { RoleOption } from './useBuildingRoleSelectorOptions';

import FormField from '@/shared/components/2.0/input/FormField';
import { IndentationArrowIcon } from '@/shared/icons/Icons';

type Props = {
  onChange: (roles: BuildingRoleSelectorOption[], splitRoles: SplitRoles) => void;
  value: BuildingRoleSelectorOption[] | SplitRoles;
};

const BuildingRoleSelector: React.FC<Props> = ({ onChange, value }) => {
  const { isOptionEqualToValue, options, selectedOptions } = useBuildingRoleSelectorOptions(value);
  const { t } = useTranslation();

  const handleChange = (newOptions: RoleOption[]) => {
    const selectedRoles = newOptions.map((option) => option.value);

    const newRolesValue = convertRoleGroups(selectedRoles);
    const splitRolesValue = splitRoles(selectedRoles);

    onChange(newRolesValue, splitRolesValue);
  };

  return (
    <FormField label={t('buildingContactRoles')}>
      <Autocomplete
        disableCloseOnSelect
        multiple
        options={options}
        value={selectedOptions}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={(_, option) => handleChange(option)}
        renderOption={(props, option) => (
          <AutocompleteOption {...props}>
            {!!option.parent && (
              <ListItemDecorator className="ml-1">
                <IndentationArrowIcon />
              </ListItemDecorator>
            )}
            <ListItemContent className={classNames({ 'font-medium': !option.parent })}>
              {option.label}
            </ListItemContent>
          </AutocompleteOption>
        )}
      />
    </FormField>
  );
};

export default BuildingRoleSelector;
