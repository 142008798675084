import {
  BookmarkView,
  Inject,
  Magnification,
  Navigation,
  PdfViewerComponent,
  Print,
  TextSearch,
  TextSelection,
  ThumbnailView,
  Toolbar,
} from '@syncfusion/ej2-react-pdfviewer';
import classNames from 'classnames';
import { memo } from 'react';

const RESOURCE_URL = 'https://cdn.syncfusion.com/ej2/26.2.11/dist/ej2-pdfviewer-lib';

type Props = {
  className?: string;
  uri: string;
  onError?: () => void;
  onLoad?: () => void;
};

const PDFRenderer: React.FC<Props> = ({ className, uri, onLoad, onError }) => (
  <PdfViewerComponent
    className={classNames('h-full', className)}
    documentPath={uri}
    readOnly
    resourceUrl={RESOURCE_URL}
    toolbarSettings={{
      toolbarItems: [
        'DownloadOption',
        'MagnificationTool',
        'PageNavigationTool',
        'PanTool',
        'PrintOption',
        'SearchOption',
        'SelectionTool',
      ],
    }}
    documentLoad={() => onLoad?.()}
    documentLoadFailed={() => onError?.()}
  >
    <Inject
      services={[
        BookmarkView,
        Magnification,
        Navigation,
        Print,
        ThumbnailView,
        TextSearch,
        TextSelection,
        Toolbar,
      ]}
    />
  </PdfViewerComponent>
);

export default memo(PDFRenderer);
