import {
  Alert,
  Autocomplete,
  Badge,
  Checkbox,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Tooltip,
} from '@mui/joy';
import { lowerCase, sortBy, uniq } from 'lodash';
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo } from 'react';
import classNames from 'classnames';
import { TRPCErrorShape } from '@trpc/server/rpc';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { CaseType, EntityType, RelationType } from '~/common/enums';
import { RouteParams, ToggleProps } from '@/types';
import { AddEditBuildingFormikValues } from '@/containers/Buildings/AddEditBuilding/AddEditBuildingFormConfig';
import { AutocompleteOption } from '~/common/types';
import { DetailedCaseTemplate } from '~/common/types/case/caseTemplate';
import { Task } from '~/common/types/task/task';

import { decapitalize, getAddressStringFromAddressObject } from '@/shared/utils/helpers';
import {
  useGetBuildingLinkedCases,
  useGetBuildingSummary,
  useGetEnums,
  useGetSimpleBuildingRelations,
} from '@/queries';
import { AppContext } from '@/shared/context/context';
import { buildingApi } from '@/shared/api';
import { CaseContext } from '../CaseContext';
import { CreateCaseValues } from './AddCase';
import { queryIds } from '@/shared/utils/constants';
import team from '@/shared/api/team';
import { trpc } from '@/config/trpc';
import { useSearchFilter } from '@/shared/hooks/UseFilters';
import useToggle from '@/shared/hooks/UseToggle';

import { AddressIcon, BuildingIcon, InlineSpinner, WarningIcon } from '@/shared/icons/Icons';
import AddEditBuildingForm from '@/containers/Buildings/AddEditBuilding/AddEditBuildingForm';
import AddressForm from '@/shared/components/2.0/forms/AddressForm';
import AsyncAutocomplete from '@/shared/components/2.0/autocomplete/AsyncAutoComplete';
import BuildingContactsSuggestions from '../BuildingContactsSuggestions';
import CaseChipDisplay from '../CaseChipDisplay';
import CaseRelationsAutocomplete from '@/shared/components/2.0/autocomplete/CaseRelationsAutocomplete';
import FormHelperText from '@/shared/components/2.0/forms/FormHelperText';
import FormModal from '@/shared/components/2.0/layout/FormModal';
import KpcModal from '@/shared/components/2.0/layout/KpcModal';
import TaskCreator from '@/shared/components/tasks/TaskCreator';

type Props = {
  caseType: CaseType;
  currentCaseTemplate?: DetailedCaseTemplate;
  taskToggle: ToggleProps;
};

const CommonFormBody: React.FC<Props> = ({ currentCaseTemplate, caseType, taskToggle }) => {
  const { debounceSearch, search } = useSearchFilter({ defaultSearch: '', startQueryLength: 0 });
  const { values, setFieldValue, errors, touched } = useFormikContext<CreateCaseValues>();
  const { caseId } = useParams() as RouteParams;
  const { currentTeam } = useContext(AppContext);
  const { currentCase } = useContext(CaseContext) || {};
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const utils = trpc.useUtils();

  const {
    enums: { caseChannels, priorities },
  } = useGetEnums();

  const sortedCaseChannels: { code: null; name: string; value: number }[] = sortBy(
    caseChannels,
    lowerCase('name'),
  );
  const { building, buildingIsLoading } = useGetBuildingSummary(values.buildingOption?.value);
  const { linkedCases } = useGetBuildingLinkedCases(values.buildingOption?.value);
  const { relations } = useGetSimpleBuildingRelations(values.buildingOption?.value);
  const {
    data: caseClassificationsAutocomplete,
    isLoading: isLoadingCaseClassificationsAutocomplete,
  } = trpc.team.case.classification.autocomplete.useQuery({
    caseType,
  });

  const { data: caseData } = trpc.case.byId.useQuery(caseId, {
    enabled: !!caseId && caseType === CaseType.Complaint,
  });

  const { mutateAsync: createBuildingAsync } = trpc.building.create.useMutation();

  const addressToggle = useToggle();
  const buildingContactsToggle = useToggle();
  const relatedBuildingCasesToggle = useToggle();
  const buildingToggle = useToggle();

  const filteredBuildingRelations = useMemo(() => {
    const query = search.toLowerCase();

    const filteredRelations = relations.filter((relation) => {
      if (relation.units.some((unit) => unit.info?.toLowerCase().includes(query))) {
        return true;
      }
      return relation.displayName.toLowerCase().includes(query);
    });

    return sortBy(filteredRelations, (item) => lowerCase(item.displayName));
  }, [relations, search]);

  const suggestedRelationIds = useMemo(() => {
    if (!caseData) return [];

    const relationIds = caseData.relations
      .map(({ companyId, contactId, relationType, teamId }) => {
        if (relationType === RelationType.Company) return companyId;
        if (relationType === RelationType.Contact) return contactId;

        return teamId;
      })
      .filter(Boolean) as string[];

    return uniq(relationIds);
  }, [caseData]);

  const onError = (error: unknown) => {
    const { message } = error as TRPCErrorShape;

    enqueueSnackbar(message, { variant: 'error' });
  };

  const onSuccess = (typeKey: string, queryKey: string) => {
    queryClient.invalidateQueries([queryKey]);
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] === queryIds.crm.GET_OPTIONS,
    });
    enqueueSnackbar(t('typeSuccessfullyAdded', { type: t(typeKey) }));
  };

  const submitBuilding = async (buildingValues: AddEditBuildingFormikValues) => {
    try {
      const request = {
        ...buildingValues,
        administrators: buildingValues.administrators.map(({ value }) => value),
      };

      const buildingResponse = await createBuildingAsync(request);

      const {
        address: { addressLine, postalCode, city },
        name,
        vatNumber,
      } = buildingResponse;

      const label = vatNumber ? `${name} (${vatNumber})` : name;
      const description = `${addressLine ?? ''} - ${postalCode ?? ''} ${city ?? ''}`.trim() || '';

      setFieldValue('buildingOption', {
        data: {
          description,
        },
        label,
        type: EntityType.Building,
        value: buildingResponse.id,
      });
      buildingToggle.hide();
      utils.building.list.invalidate();
      onSuccess('building', queryIds.buildings.GET_BUILDINGS);
    } catch (error) {
      onError(error);
    }
  };

  const handleTaskChange = useCallback(
    (task: Task) => {
      if (!task.teamTaskTypeId && !task.systemTaskType && !task.description) {
        setFieldValue('task', null);
        return;
      }
      setFieldValue('task', task);
    },
    [setFieldValue],
  );

  useEffect(() => {
    if (currentCaseTemplate) {
      const classification = caseClassificationsAutocomplete?.find(
        (classificationOption) =>
          classificationOption.value === currentCaseTemplate.classificationId,
      );
      setFieldValue('requestor', currentCaseTemplate.requestor);
      setFieldValue('description', currentCaseTemplate.description);
      setFieldValue('priority', currentCaseTemplate.priority);
      setFieldValue('classification', classification);
      setFieldValue('administratorOptions', currentCaseTemplate.administratorOptions);
    }

    if (values.buildingOption?.value) {
      setFieldValue('address', {
        addressLine: building?.address?.addressLine || null,
        city: building?.address?.city || null,
        countryCode: building?.address?.country || null,
        postalCode: building?.address?.postalCode || null,
      });
    } else {
      setFieldValue('address', {
        addressLine: values.address?.addressLine,
        city: values.address?.city,
        countryCode: values.address?.countryCode,
        postalCode: values.address?.postalCode,
      });
    }
  }, [
    currentCaseTemplate,
    setFieldValue,
    currentCase,
    caseType,
    values.buildingOption,
    building,
    values.address?.addressLine,
    values.address?.city,
    values.address?.countryCode,
    values.address?.postalCode,
    caseClassificationsAutocomplete,
  ]);

  useEffect(() => {
    setFieldValue('admins', building?.administrators);
  }, [building?.administrators, setFieldValue]);

  return (
    <div className="flex flex-col space-y-4">
      <FormControl>
        <div
          className={classNames('flex justify-between py-2', {
            'pr-6': !!linkedCases.length,
            'space-x-4': !relations.length && !linkedCases.length,
            'space-x-8': !!relations.length || !!linkedCases.length,
          })}
        >
          <FormLabel>{t('building_one')}</FormLabel>
          <div className="flex space-x-8">
            <Badge badgeContent={relations.length || null}>
              <Checkbox
                checked={buildingContactsToggle.value}
                disabled={!relations.length}
                label={t('buildingContacts')}
                className="items-center text-xs"
                onChange={() => {
                  buildingContactsToggle.inverse();
                  setFieldValue('requestor', null);
                }}
              />
            </Badge>

            <Badge badgeContent={linkedCases.length || null}>
              <Checkbox
                checked={relatedBuildingCasesToggle.value}
                disabled={!values.buildingOption || !linkedCases.length}
                label={t('relatedCases')}
                className="items-center text-xs"
                onChange={relatedBuildingCasesToggle.inverse}
              />
            </Badge>
          </div>
        </div>
        <AsyncAutocomplete
          defaultValue={values.buildingOption}
          placeholder={t('search.placeholder')}
          onClearCallback={() => {
            setFieldValue('buildingOption', null);
          }}
          queryFn={buildingApi.getBuildingsAutocomplete}
          queryId={queryIds.buildings.GET_BUILDINGS_AUTOCOMPLETE}
          onChange={(_, option) => {
            if (option) {
              const buildingOption = option as AutocompleteOption;
              setFieldValue('buildingOption', buildingOption);
            } else {
              setFieldValue('buildingOption', null);
            }
          }}
        >
          <Tooltip title={t('_building.actions.create')}>
            <IconButton onClick={buildingToggle.show}>
              <BuildingIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('createAddress')}>
            <IconButton onClick={() => addressToggle.show()}>
              <AddressIcon />
            </IconButton>
          </Tooltip>
        </AsyncAutocomplete>
        <FormHelperText error={errors.buildingOption} touched={touched.buildingOption} />
      </FormControl>

      {values.address?.addressLine && (
        <FormControl>
          <FormLabel>{t('_address.label')}</FormLabel>
          <div className="flex grow flex-row space-x-3">
            <Input
              disabled
              className="grow"
              value={getAddressStringFromAddressObject(values.address) || undefined}
            />
          </div>
        </FormControl>
      )}

      {buildingContactsToggle.value && !!relations.length && (
        <BuildingContactsSuggestions
          callback={(relation) =>
            setFieldValue('requestor', {
              ...relation.requestorData,
              entity: relation.requestorData.type,
            })
          }
          debounceSearch={debounceSearch}
          isRequired
          filteredBuildingRelations={filteredBuildingRelations}
          title="buildingContacts"
          valueName="requestor"
        />
      )}

      {relatedBuildingCasesToggle.value && !!linkedCases.length && (
        <FormControl>
          <FormLabel> {t('buildingCases')}</FormLabel>
          <div className="grid max-h-32 grid-cols-2 gap-2 overflow-y-auto">
            {linkedCases.map((c) => (
              <CaseChipDisplay key={c.caseId} caseEntity={{ ...c, id: c.caseId }} />
            ))}
          </div>
        </FormControl>
      )}

      {!buildingIsLoading && values.buildingOption && !linkedCases.length && (
        <Alert startDecorator={<WarningIcon />} color="warning" size="sm">
          {t('noRelatedCasesForThisBuilding')}
        </Alert>
      )}

      {!buildingContactsToggle.value && (
        <CaseRelationsAutocomplete
          callbacks={{
            onSuccess: (addedOption) => setFieldValue('requestor', addedOption),
          }}
          label={t('requestor')}
          onChange={(value) => setFieldValue('requestor', value)}
          required
          selected={values.requestor?.value || null}
          suggestedRelationIds={suggestedRelationIds}
        />
      )}

      {currentTeam?.createCaseAskForClient && (
        <CaseRelationsAutocomplete
          callbacks={{
            onSuccess: (addedOption) => setFieldValue('clients', [...values.clients, addedOption]),
          }}
          label={t('clients')}
          onChange={(value) => setFieldValue('clients', value)}
          multiple
          selected={values.clients.map((client) => client.value)}
          suggestedRelationIds={suggestedRelationIds}
        />
      )}

      <FormControl>
        <FormLabel required>{t('description')}</FormLabel>
        <Input
          placeholder={t('description')}
          value={values.description}
          onChange={(e) => setFieldValue('description', e.target.value)}
        />
        <FormHelperText error={errors.description} touched={touched.description} />
      </FormControl>
      <div className="flex flex-row space-x-4">
        <FormControl className="w-full">
          <FormLabel>{t('channel')}</FormLabel>
          <Select
            value={values.channel}
            placeholder={t('select.placeholder')}
            onChange={(_, option) => {
              setFieldValue('channel', option);
            }}
          >
            {sortedCaseChannels.length &&
              sortedCaseChannels.map((channel) => (
                <Option key={channel.name} value={channel.name}>
                  {t(decapitalize(channel.name))}
                </Option>
              ))}
          </Select>
        </FormControl>

        <FormControl className="w-full">
          <FormLabel>{t('priority')}</FormLabel>
          <Select
            value={values.priority || 'Normal'}
            onChange={(_, value) => {
              if (value) {
                setFieldValue('priority', value);
              } else {
                setFieldValue('priority', null);
              }
            }}
          >
            {!!priorities.length &&
              priorities.map((priority) => (
                <Option key={priority.name} value={priority.name}>
                  {t(decapitalize(priority.name))}
                </Option>
              ))}
          </Select>
        </FormControl>
      </div>

      <div className="flex space-x-4">
        <FormControl className="w-1/2">
          <FormLabel>{t('classification')}</FormLabel>
          <Autocomplete
            placeholder={t('search.placeholder')}
            value={values.classification}
            onChange={(_, classification) => setFieldValue('classification', classification)}
            options={caseClassificationsAutocomplete || []}
            startDecorator={isLoadingCaseClassificationsAutocomplete && <InlineSpinner />}
          />
        </FormControl>

        <FormControl error={!!errors.administratorOptions} className="w-1/2">
          <FormLabel required>{t('administrators')}</FormLabel>
          <AsyncAutocomplete
            multiple
            placeholder={t('search.placeholder')}
            defaultValue={values.administratorOptions || []}
            queryFn={team.getTeamUsersAutocomplete}
            queryId={queryIds.team.GET_TEAM_USERS_AUTOCOMPLETE}
            onChange={(_, options) => setFieldValue('administratorOptions', options)}
          />
        </FormControl>
      </div>

      {taskToggle.value && <TaskCreator callback={handleTaskChange} />}

      {addressToggle.value && (
        <FormModal
          size="md"
          title={t('_address.label')}
          description={t('_address.description.building')}
          toggle={addressToggle}
          initialValues={{
            addressLine: values.address?.addressLine || null,
            city: values.address?.city || null,
            country: values.address?.countryCode || 'BE',
            postalCode: values.address?.postalCode || null,
          }}
          handleSubmit={(address) => {
            setFieldValue('address', address);
            addressToggle.hide();
            setFieldValue('buildingOption', null);
          }}
        >
          <AddressForm />
        </FormModal>
      )}

      {buildingToggle.value && (
        <KpcModal title={t('building_one')} toggle={buildingToggle}>
          <AddEditBuildingForm onCancel={buildingToggle.hide} onSubmit={submitBuilding} />
        </KpcModal>
      )}
    </div>
  );
};

export default CommonFormBody;
