import * as yup from 'yup';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { CaseRole, ExactOnlinePaymentCondition, Language } from '~/common/enums';
import { Company, CompanySummary, isFullCompany } from '~/common/types/company/company';
import { DeepNullable, Nullable } from '~/common/types';

import { languageMap } from '~/common/constants';
import { RouterOutputs } from '@/config/trpc';

export type AddEditCompanyFormikValues = Omit<
  DeepNullable<CompanySummary>,
  | 'isContractor'
  | 'displayName'
  | 'id'
  | 'emailAddresses'
  | 'companyTags'
  | 'provider'
  | 'address'
  | 'isActive'
  | 'exactOnlinePaymentCondition'
> & {
  isActive: boolean;
  directDebitHomeownerAssociationIds: string[];
  isMemberOfContractorNetwork: boolean;
  name: Nullable<string>;
  companyId: Nullable<string>;
  showContractorNameOnSalesInvoice: boolean;
  pushCrmChanges: boolean;
  emailAddresses: string[];
  companyTags: string[];
  directDebitEnabled: boolean;
  note: Nullable<string>;
  exactOnlinePaymentCondition: ExactOnlinePaymentCondition;
};

type Options = {
  isContractor?: boolean;
  minified?: boolean;
};

const useAddEditCompanyFormConfig = (
  company?: Company | CompanySummary | RouterOutputs['company']['byId'],
  options: Options = { isContractor: false, minified: false },
) => {
  const { t, i18n } = useTranslation('errors');

  const { isContractor, minified } = options;

  const getPhoneNumberSchema = (mobile = false) =>
    yup
      .object()
      .shape({
        countryCode: yup.string().nullable(),
        number: yup.string().nullable(),
      })
      .phoneNumber(mobile);

  const getInitialValues = () => {
    const defaultEmailsValue = minified ? [] : [{ emailContext: [0] }];

    if (company) {
      return {
        companyId: company.id,
        companyTags: company.companyTags.map((tag) => tag.tagId),
        defaultCaseRole: company.defaultCaseRole,
        directDebitEnabled: isFullCompany(company)
          ? company.directDebitHomeownerAssociationIds.length > 0
          : false,
        directDebitHomeownerAssociationIds: isFullCompany(company)
          ? company.directDebitHomeownerAssociationIds
          : [],
        emailAddresses: company.emailAddresses || defaultEmailsValue,
        exactOnlinePaymentCondition: company.exactOnlinePaymentCondition || null,
        ibans: sortBy(company?.ibans?.map((i) => ({ ...i, tempKey: uuid() })), (iban) => !iban.isDefault),
        isActive: company.isActive,
        isMemberOfContractorNetwork: company.isContractor,
        language: company.language,
        mobilePhoneNumber: {
          countryCode: company.mobilePhoneNumber?.countryCode || null,
          number: company.mobilePhoneNumber?.number || null,
        },
        name: company.displayName || null,
        note: isFullCompany(company) && company.note ? company.note : null,
        paymentPeriod:
          isFullCompany(company) && company.paymentPeriod ? company.paymentPeriod : null,
        phoneNumber: {
          countryCode: company.phoneNumber?.countryCode || null,
          number: company.phoneNumber?.number || null,
        },
        pushCrmChanges: false,
        showContractorNameOnSalesInvoice: false,
        vatNumber: company.vatNumber || null,
      };
    }

    return {
      companyId: null,
      companyTags: [],
      defaultCaseRole: null,
      directDebitEnabled: false,
      directDebitHomeownerAssociationIds: [],
      emailAddresses: defaultEmailsValue,
      exactOnlinePaymentCondition: null,
      ibans: [],
      isActive: true,
      isMemberOfContractorNetwork: isContractor,
      language: languageMap[i18n.language] || Language.English,
      mobilePhoneNumber: {
        countryCode: null,
        number: null,
      },
      name: null,
      note: null,
      paymentPeriod: null,
      phoneNumber: {
        countryCode: null,
        number: null,
      },
      pushCrmChanges: false,
      showContractorNameOnSalesInvoice: false,
      vatNumber: null,
    };
  };

  const baseValidationSchema = yup.object({
    defaultCaseRole: yup.mixed<CaseRole>().nullable(),
    language: yup
      .mixed<Language>()
      .nullable()
      .oneOf(Object.values(Language))
      .required(t('fieldIsRequired')),
    name: yup.string().nullable().required(t('fieldIsRequired')),
  });

  return {
    baseValidationSchema,
    expandedValidationSchema: baseValidationSchema.shape({
      directDebitHomeownerAssociationIds: yup.array().of(yup.string().uuid()),
      emailAddresses: yup.array(
        yup.object().shape({
          email: yup.string().email(t('errors:invalidEmail')),
          emailContext: yup.array(yup.number()),
        }),
      ),
      ibans: yup.array(
        yup.object().shape({
          accountNumber: yup
            .string()
            .nullable()
            .required(t('errors:fieldIsRequired'))
            .accountNumber(),
          bic: yup
            .string()
            .nullable()
            .required(t('errors:fieldIsRequired'))
            .bic(),
          isDefault: yup.boolean().required(t('errors:fieldIsRequired')),
        })
      )
        .test('ibans', t('errors:defaultValueIsRequired'), (value) => {
          if (!value?.length) {
            return true;
          }
          return value.filter(i => i.isDefault).length === 1;
        }),
      isMemberOfContractorNetwork: yup.boolean(),
      mobilePhoneNumber: getPhoneNumberSchema(true),
      name: yup.string().nullable().required(t('fieldIsRequired')),
      note: yup.string().nullable(),
      paymentPeriod: yup.number().nullable().positive(t('valueMustBePositive')),
      phoneNumber: getPhoneNumberSchema(),
      vatNumber: yup.string().nullable(),
    }),
    initialValues: getInitialValues(),
  };
};

export default useAddEditCompanyFormConfig;
