import * as yup from 'yup';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { EmailFormValues, InitialEmailValues, ParsedEmailFormValues } from '../types';
import { SenderOrReceiver } from '@/types/email';
import { UseToggle } from '@/shared/hooks/UseToggle';

import {
  contactsToOptions,
  optionsToContacts,
} from '@/shared/components/2.0/select/SelectEmailContact/utils';
import { formatEmailFormData, FormattedEmailData } from '@/shared/utils/Email/emailHelpers';
import useComposeBody, { EMPTY_BODY } from './useComposeBody';
import useGetAttachments from './useGetAttachments';
import useGetReceivers from './useGetReceivers';
import useGetSubject from './useGetSubject';
import { useMailboxContext } from '../../../store';
import useSendEmailMessage from '@/mutations/email/useSendEmailMessage';

const INITIAL_VALUES = {
  attachmentFileIds: [],
  attachments: [],
  body: EMPTY_BODY,
  compressFiles: true,
  emailAccountLinkId: null,
  recipientCompanyIds: [],
  recipientContactIds: [],
  replyTo: [],
  replyToMessageId: null,
  selectedBcc: [],
  selectedCc: [],
  selectedFrom: [],
  selectedTo: [],
  subject: null,
};

const useInitialValues = (initialEmail?: InitialEmailValues | null): EmailFormValues => {
  const { state: mailbox } = useMailboxContext();

  const attachments = useGetAttachments(initialEmail?.attachments);
  const body = useComposeBody(initialEmail?.body);
  const receivers = useGetReceivers(initialEmail?.to);
  const subject = useGetSubject(initialEmail?.subject);

  const bcc = (() => {
    if (!mailbox.isReplyAll || !mailbox.replyEmail)
      return initialEmail?.bcc ?? INITIAL_VALUES.selectedBcc;

    return [...mailbox.replyEmail.bcc];
  })();

  const cc = (() => {
    if (!mailbox.isReplyAll || !mailbox.replyEmail)
      return initialEmail?.cc ?? INITIAL_VALUES.selectedCc;

    return [...mailbox.replyEmail.cc];
  })();

  const replyToMessageId = (() => {
    if (mailbox.isForward) return INITIAL_VALUES.replyToMessageId;

    return mailbox.replyEmail?.id ?? INITIAL_VALUES.replyToMessageId;
  })();

  return {
    ...INITIAL_VALUES,
    attachments,
    body,
    compressFiles: initialEmail?.compressFiles ?? INITIAL_VALUES.compressFiles,
    replyToMessageId,
    selectedBcc: contactsToOptions(bcc),
    selectedCc: contactsToOptions(cc),
    selectedFrom: initialEmail?.from
      ? contactsToOptions(initialEmail.from)
      : INITIAL_VALUES.selectedFrom,
    selectedTo: contactsToOptions(receivers),
    subject,
  };
};

const MAX_SUBJECT_LENGTH = 255;

export const useEmailOptionsSchema = () => {
  const { t } = useTranslation('errors');

  const emailOptionSchema = yup.object({ label: yup.string(), value: yup.string().email() });

  return yup.array().test('valid-email-options', t('emailsAreInvalid'), (value) => {
    if (!value) return true;

    return value.every((item) => emailOptionSchema.isValidSync(item));
  });
};

const useValidation = () => {
  const { t } = useTranslation('errors');

  const emailOptionsSchema = useEmailOptionsSchema();

  const schema = useMemo(
    () =>
      yup.object({
        attachments: yup.array(),
        selectedBcc: emailOptionsSchema,
        selectedCc: emailOptionsSchema,
        selectedTo: emailOptionsSchema.when('selectedBcc', {
          is: (bcc: SenderOrReceiver[]) => !bcc.length,
          then: emailOptionsSchema.min(1, t('fieldIsRequired')),
        }),
        subject: yup
          .string()
          .nullable()
          .required(t('fieldIsRequired'))
          .max(MAX_SUBJECT_LENGTH, t('fieldMaxLengthIsX', { max: MAX_SUBJECT_LENGTH })),
        tasks: yup
          .array()
          .test('taskDataShouldBeValid', (value) => (value && value[0] ? value[0].isValid : true)),
      }),
    [emailOptionsSchema, t],
  );

  return schema;
};

export type UseComposeEmailFormArgs<
  TFormattedData extends FormattedEmailData,
  TParsedValues extends ParsedEmailFormValues,
> = {
  initialEmail?: InitialEmailValues | null;
  onSubmit?: (formattedValues: TFormattedData, values: TParsedValues) => void;
  toggle?: UseToggle;
};

const useComposeEmailForm = <
  TFormattedData extends FormattedEmailData,
  TParsedValues extends ParsedEmailFormValues,
>({
  initialEmail,
  onSubmit,
  toggle,
}: UseComposeEmailFormArgs<TFormattedData, TParsedValues>) => {
  const { sendMessage } = useSendEmailMessage();
  const { state: mailbox } = useMailboxContext();

  const initialValues = useInitialValues(initialEmail);
  const validationSchema = useValidation();

  const handleSubmit = useCallback(
    <TFormValues extends EmailFormValues>(values: TFormValues) => {
      if (!mailbox.emailAccount) return;

      const parsedValues = {
        ...values,
        bcc: optionsToContacts(values.selectedBcc),
        cc: optionsToContacts(values.selectedCc),
        from: optionsToContacts(values.selectedFrom),
        to: optionsToContacts(values.selectedTo),
      };

      const formattedData = formatEmailFormData(
        parsedValues,
        mailbox.emailAccount,
        mailbox.replyEmail,
      );

      if (onSubmit) {
        onSubmit(formattedData, parsedValues);
      } else {
        sendMessage(formattedData, { onSuccess: toggle?.hide });
      }
    },
    [mailbox.emailAccount, mailbox.replyEmail, onSubmit, sendMessage, toggle?.hide],
  );

  return {
    enableReinitialize: true,
    initialValues,
    onSubmit: handleSubmit,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema,
  };
};

export default useComposeEmailForm;
