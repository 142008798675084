import { useCallback, useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

const { VITE_KEYPOINT_API_URI } = import.meta.env;

enum ServerStatus {
  Online = "Online",
  Offline = "Offline",
}

const healthSessionIdKey = "healthSessionId"
const serverStatusKey = "serverStatus"

const useServerHealth = () => {
    const [serverDown, setServerDown] = useState(localStorage.getItem(serverStatusKey) === ServerStatus.Offline);
    const downTimerRef = useRef<ReturnType<typeof setTimeout>>();
    const retryTimerRef = useRef<ReturnType<typeof setTimeout>>();

    const eventSource = useRef<EventSource | null>(null);
    const sessionId = useRef(uuidv4());

    const startHealthCheck = useCallback(() => {
      const healthSessionId = localStorage.getItem(healthSessionIdKey);
    
      // Ensure only one tab is monitoring the server. 
      // SSE connections are limited per domain.
      if (healthSessionId && sessionId.current !== healthSessionId) {
        if (eventSource?.current) {
          eventSource.current.close();
        }
        return;
      }
    
      console.log("Starting server health check...");
      eventSource.current = new EventSource(`${VITE_KEYPOINT_API_URI}/health`);
      localStorage.setItem(healthSessionIdKey, sessionId.current);
    
      eventSource.current.onopen = () => {
        console.log("Connected to SSE");
        if (retryTimerRef.current){
          clearTimeout(retryTimerRef.current);
          retryTimerRef.current = undefined;
        }


        // Clear timeout if connection is restored.
        if (downTimerRef.current) {
          clearTimeout(downTimerRef.current);
          downTimerRef.current = undefined;
        }
    
        localStorage.setItem(serverStatusKey, ServerStatus.Online);
        setServerDown(false);
      };
    
      eventSource.current.onerror = () => {
        if (!downTimerRef.current){
            console.log("Server error detected, waiting 15 seconds...");

            downTimerRef.current = setTimeout(() => {
              console.log("No connection restored within 15 seconds. Marking server as down.");
              localStorage.setItem(serverStatusKey, ServerStatus.Offline);
              setServerDown(true);
            }, 15000);
        } else {
          // retry every 10 seconds
          retryTimerRef.current = setTimeout(() => {
            eventSource.current?.close();
            eventSource.current = null;
            startHealthCheck();
          }, 10000);
        }
      };
    }, []);

    useEffect(() => {
      startHealthCheck();
  
      const handleStorageChange = (event: StorageEvent) => {
          if (event.key === serverStatusKey) {
              setServerDown(event.newValue === ServerStatus.Offline);
          }
  
          if (event.key === healthSessionIdKey && event.newValue === null) {
              startHealthCheck();
          }
      };

      const resetLocalStorage = () => {
          localStorage.removeItem(healthSessionIdKey);
          localStorage.removeItem(serverStatusKey);
      }
    
      const reset =() => {
          resetLocalStorage();
          eventSource.current?.close();
      }
  
      // Reset local storage every 5 minutes if server is down. 
      // This is a safety measurement for the server being falsely down.
      const resetInterval = setInterval(() => {
        if (!serverDown) return;
        resetLocalStorage();
      }, 5 * 60 * 1000); 
      
      window.addEventListener("storage", handleStorageChange);
      window.addEventListener("beforeunload", reset);
  
      return () => {
          clearInterval(resetInterval);
          window.removeEventListener("storage", handleStorageChange);
          window.removeEventListener("beforeunload", reset);
      };
    }, [serverDown, startHealthCheck]);
  
    return { serverDown };
  };
  
  export default useServerHealth;