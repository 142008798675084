import { useEffect, useMemo, useState } from 'react';
import { lowerFirst } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ContactDuplicateReasonType, LogicalOperator } from '~/common/enums';
import ContactFormikValues from '~/common/types/contact/ContactFormikValues';
import { trpc } from '@/config/trpc';

import { InvalidIcon, WarningIcon } from '@/shared/icons/Icons';

type DuplicateAlertInfo = {
  startDecorator: JSX.Element;
  label: string;
  color: 'danger' | 'warning';
  contactId: string;
};

const useDuplicateCheck = ({
  contactId,
  values,
}: {
  contactId?: string;
  values: ContactFormikValues;
}) => {
  const { t } = useTranslation();
  const [duplicateReason, setDuplicateReason] = useState<{
    contactId: string;
    reason: ContactDuplicateReasonType;
  } | null>();

  const queryData = useMemo(() => {
    const emailAddresses =
      values.emailAddresses
        .map((emailAddress) => emailAddress.email)
        .filter((emailString) => emailString) ?? [];

    if (
      emailAddresses.length === 0 &&
      (!values.firstName || !values.lastName) &&
      (!values.mobilePhoneNumber?.countryCode || !values.mobilePhoneNumber.number) &&
      (!values.phoneNumber?.countryCode || !values.phoneNumber?.number)
    ) {
      return null;
    }
    const request = {
      emailAddresses: values.emailAddresses
        .map((emailAddress) => emailAddress.email)
        .filter((emailString) => emailString),
      firstName: values.firstName,
      lastName: values.lastName,
      logicalOperator: LogicalOperator.Or,
      mobilePhoneNumber: values.mobilePhoneNumber,
      phoneNumber: values.phoneNumber,
    };

    return request;
  }, [
    values.emailAddresses,
    values.firstName,
    values.lastName,
    values.mobilePhoneNumber,
    values.phoneNumber,
  ]);

  const {
    data: duplicateContacts,
    isFetching: isCheckingForDuplicate,
    refetch: refetchDuplicates,
  } = trpc.contact.list.useQuery(
    queryData || {
      emailAddresses: [],
      firstName: null,
      lastName: null,
      logicalOperator: LogicalOperator.And,
      mobilePhoneNumber: null,
      phoneNumber: null,
    },
    { enabled: false },
  );

  useEffect(() => {
    const duplicateContact = duplicateContacts?.find((contact) => contact.id !== contactId);

    if (!duplicateContact || !queryData) {
      setDuplicateReason(null);
      return;
    }

    let reason = null;
    if (
      duplicateContact.firstName?.localeCompare(queryData.firstName as string, undefined, {
        sensitivity: 'accent',
      }) === 0 &&
      duplicateContact.lastName?.localeCompare(queryData.lastName as string, undefined, {
        sensitivity: 'accent',
      }) === 0
    ) {
      reason = ContactDuplicateReasonType.Name;
    } else if (
      duplicateContact.emailAddresses.some((email) =>
        queryData.emailAddresses.some((e) => e === email),
      )
    ) {
      reason = ContactDuplicateReasonType.Email;
    } else if (
      !!queryData.phoneNumber?.number &&
      duplicateContact.phoneNumber?.countryCode === queryData.phoneNumber?.countryCode &&
      duplicateContact.phoneNumber?.number === queryData.phoneNumber?.number
    ) {
      reason = ContactDuplicateReasonType.PhoneNumber;
    } else if (
      !!queryData.mobilePhoneNumber?.number &&
      duplicateContact.mobilePhoneNumber?.countryCode ===
        queryData.mobilePhoneNumber?.countryCode &&
      duplicateContact.mobilePhoneNumber?.number === queryData.mobilePhoneNumber?.number
    ) {
      reason = ContactDuplicateReasonType.MobilePhoneNumber;
    }
    setDuplicateReason(reason ? { contactId: duplicateContact.id, reason } : null);
  }, [contactId, duplicateContacts, queryData]);

  const checkForDuplicates = () => {
    if (!queryData) return;

    refetchDuplicates();
  };

  const alertInfo = useMemo(() => {
    if (!duplicateReason) {
      return null;
    }

    const alertLabel = t(`contactDuplicateReasonType.${lowerFirst(duplicateReason.reason)}`);

    switch (duplicateReason?.reason) {
      case ContactDuplicateReasonType.Name:
        return {
          color: 'warning',
          contactId: duplicateReason.contactId,
          label: alertLabel,
          startDecorator: <WarningIcon />,
        } as DuplicateAlertInfo;

      case ContactDuplicateReasonType.Email:
        return {
          color: 'danger',
          contactId: duplicateReason.contactId,
          label: alertLabel,
          startDecorator: <InvalidIcon />,
        } as DuplicateAlertInfo;
      case ContactDuplicateReasonType.MobilePhoneNumber:
      case ContactDuplicateReasonType.PhoneNumber:
        return {
          color: 'danger',
          contactId: duplicateReason.contactId,
          label: alertLabel,
          startDecorator: <InvalidIcon />,
        } as DuplicateAlertInfo;
      default:
        return null;
    }
  }, [duplicateReason, t]);

  return { alertInfo, checkForDuplicates, duplicateReason, isCheckingForDuplicate };
};

export default useDuplicateCheck;
