import Button from '@mui/joy/Button';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';

import KpcModal from './layout/KpcModal';
import Row from './layout/Row';
import { SaveIcon } from '../../icons/Icons';
import { UseToggle } from '@/shared/hooks/UseToggle';

type Props<T> = {
  onCancel?: () => void;
  onConfirm: () => void;
  toggle: UseToggle<T>;
  bodyText?: string;
};

const ConfirmModal = <T,>({ toggle, onConfirm, bodyText, onCancel = () => {} }: Props<T>) => {
  const { t } = useTranslation();

  const handleConfirm = () => {
    onConfirm();
    toggle.hide();
  };

  const handleCancel = () => {
    onCancel();
    toggle.hide();
  };

  return (
    <KpcModal
      className="w-1/2 overflow-y-scroll"
      title={t('modals.confirm.title')}
      toggle={toggle}
      icon={<SaveIcon />}
    >
      <Row>{bodyText ?? t('modals.confirm.description')}</Row>
      <Row className="justify-end">
        <Button size="sm" variant="soft" color="neutral" onClick={handleCancel}>
          {t('buttons.cancel')}
        </Button>
        <Button size="sm" variant="solid" color="success" onClick={handleConfirm}>
          {t('buttons.confirm')}
        </Button>
      </Row>
    </KpcModal>
  );
};

export default memo(ConfirmModal);
