import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Modal,
  ModalClose,
  ModalDialog,
} from '@mui/joy';
import { Form, Formik, useFormikContext } from 'formik';
import { AnyObjectSchema } from 'yup';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Obj } from '~/common/types';
import { UseToggle } from '@/shared/hooks/UseToggle';

import { CancelIcon, InlineSpinner, ValidIcon } from '../../../icons/Icons';

const SIZES_MAP = {
  lg: 'w-3/5',
  md: 'w-2/5',
  sm: 'w-1/5',
  xl: 'w-4/5',
};

const DEFAULT_CLASSNAMES =
  'max-h-screen max-w-screen-2xl overflow-y-auto rounded-xl border border-gray-200 bg-white';

type ActionProps = {
  isDelete?: boolean;
  onClose?: () => void;
};

const FormModalActions: React.FC<ActionProps> = ({ isDelete, onClose }) => {
  const { isSubmitting } = useFormikContext();
  const { t } = useTranslation();

  return (
    <>
      <Button
        type="submit"
        size="sm"
        startDecorator={isSubmitting ? <InlineSpinner /> : <ValidIcon />}
        disabled={isSubmitting}
        color={isDelete ? 'danger' : 'primary'}
      >
        {isDelete ? t('buttons.delete') : t('buttons.save')}
      </Button>
      <Button size="sm" color="neutral" variant="outlined" onClick={onClose}>
        <CancelIcon className="pr-2" /> {t('buttons.cancel')}
      </Button>
    </>
  );
};

type ModalProps<TFormValues extends Obj, TToggleState> = ActionProps & {
  backdropBehavior?: 'closeOnSingleClick' | 'closeOnDoubleClick' | 'noCloseOnClick';
  children?: React.ReactNode;
  className?: string;
  description?: string;
  handleSubmit: (values: TFormValues) => void;
  headerComponent?: React.ReactNode;
  icon?: React.ReactNode;
  initialValues: TFormValues;
  size?: 'lg' | 'md' | 'sm' | 'xl';
  title?: string;
  toggle: UseToggle<TToggleState>;
  validationSchema?: AnyObjectSchema;
};

const FormModal = <TFormValues extends Obj, TToggleState>({
  backdropBehavior = 'closeOnDoubleClick',
  children,
  className,
  description,
  handleSubmit,
  headerComponent,
  initialValues,
  icon,
  isDelete = false,
  onClose,
  size = 'xl',
  title,
  toggle,
  validationSchema,
}: ModalProps<TFormValues, TToggleState>) => {
  const handleClose = () => {
    onClose?.();
    toggle.hide();
  };

  const onClickBackdrop = () => {
    if (backdropBehavior === 'closeOnSingleClick') {
      handleClose();
    }
  };

  const onDoubleClickBackdrop = () => {
    if (backdropBehavior === 'closeOnDoubleClick') {
      handleClose();
    }
  };

  return (
    toggle.value && (
      <Modal
        onClose={(_, reason) => {
          if (reason === 'backdropClick') return;
          handleClose();
          toggle.hide();
        }}
        open={toggle.value}
        slotProps={{
          backdrop: {
            onClick: onClickBackdrop,
            onDoubleClick: onDoubleClickBackdrop,
          },
        }}
      >
        <ModalDialog className={classNames(DEFAULT_CLASSNAMES, SIZES_MAP[size], className)}>
          <ModalClose />
          {(!!icon || !!title) && (
            <DialogTitle>
              {icon} {title}
            </DialogTitle>
          )}
          {!!description && (
            <p className="font-sans text-xs italic leading-5">
              {description && <p>{description}</p>}
            </p>
          )}
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            <Form>
              {headerComponent}
              <DialogContent>{children}</DialogContent>
              <DialogActions>
                <FormModalActions isDelete={isDelete} onClose={handleClose} />
              </DialogActions>
            </Form>
          </Formik>
        </ModalDialog>
      </Modal>
    )
  );
};

export default FormModal;
