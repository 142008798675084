import { IconButton, Input, InputProps } from '@mui/joy';
import { MobileDateTimePicker, MobileDateTimePickerProps } from '@mui/x-date-pickers';
import { useEffect, useRef, useState } from 'react';
import { Dayjs } from 'dayjs';

import useDayjs from '@/shared/hooks/useDayjs';

import { ClearIcon } from '@/shared/icons/Icons';
import FormField from './input/FormField';

type BaseProps = MobileDateTimePickerProps<Dayjs, Dayjs> & {
  error?: string;
  label?: string;
  onBlur?: () => void;
  required?: boolean;
};

export type DateTimePickerProps = Omit<
  BaseProps,
  'onAccept' | 'onChange' | 'onClose' | 'onOpen' | 'value' | 'renderInput'
> & {
  value?: string | null;
  onChange: React.Dispatch<string | null>;
};

const DateTimePicker: React.FC<DateTimePickerProps> = ({
  error,
  label,
  onBlur,
  onChange,
  required,
  value,
  ...props
}) => {
  const isSettingDateFlagRef = useRef(false);

  const { dayjs } = useDayjs();

  const [inputValue, setInputValue] = useState<Dayjs | null>(
    value ? dayjs.utc(value).local() : null,
  );

  const reset: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation();
    onChange(null);
    setInputValue(null);
  };

  useEffect(() => {
    if (!value || isSettingDateFlagRef.current) return;

    const newInputValue = dayjs.utc(value).local();

    if (!inputValue || !newInputValue.isSame(inputValue)) {
      setInputValue(newInputValue);
    }
  }, [dayjs, inputValue, value]);

  return (
    <MobileDateTimePicker
      ampm={false}
      value={inputValue}
      onAccept={(newValue) => onChange(newValue?.utc().toISOString() ?? null)}
      onChange={(newValue) => setInputValue(newValue)}
      onClose={() => {
        isSettingDateFlagRef.current = false;
      }}
      onOpen={() => {
        isSettingDateFlagRef.current = true;
      }}
      renderInput={(textFieldProps) => (
        <FormField label={label} error={error} required={required}>
          <Input
            {...(textFieldProps.inputProps as InputProps)}
            ref={textFieldProps.inputRef}
            endDecorator={
              !!inputValue && (
                <IconButton
                  className="rounded-full"
                  color="neutral"
                  variant="plain"
                  onClick={reset}
                >
                  <ClearIcon />
                </IconButton>
              )
            }
            fullWidth
            onBlur={onBlur}
          />
        </FormField>
      )}
      {...props}
    />
  );
};

export default DateTimePicker;
